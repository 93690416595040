const Elm = require('./Main.elm').Elm;

const apiBaseUrl = __ENVIRONMENT__.API_BASE_URL;
const version = process.env.ELM_APP_VERSION;

require('../public/styles/main.css');

const app = Elm.Main.init({
  node: document.getElementById("promotions_container"),
  flags: { initialUrl: window.location.href, apiBaseUrl, version }
});

app.ports.setUrlHash.subscribe(hash => {
  history.pushState(null, null, '#' + hash);
});

app.ports.performAuthRedirect.subscribe(hash => {
  window.location = apiBaseUrl + "/page/promotion/create-external?page=" + encodeURIComponent("#" + hash);
});

window.onpopstate = function(event) {
  app.ports.urlDidChange.send(window.location.href);
};
