(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === elm$core$Basics$EQ ? 0 : ord === elm$core$Basics$LT ? -1 : 1;
	}));
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File === 'function' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[94m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.cA.aw === region.cW.aw)
	{
		return 'on line ' + region.cA.aw;
	}
	return 'on lines ' + region.cA.aw + ' through ' + region.cW.aw;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = elm$core$Set$toList(x);
		y = elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = elm$core$Dict$toList(x);
		y = elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = elm$core$Dict$toList(x);
		y = elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? elm$core$Basics$LT : n ? elm$core$Basics$GT : elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return word
		? elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? elm$core$Maybe$Nothing
		: elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? elm$core$Maybe$Just(n) : elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}



function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return elm$core$Maybe$Nothing;
	}
}


function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? elm$core$Result$Ok(value)
		: (value instanceof String)
			? elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return elm$core$Result$Err(A2(elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return (elm$core$Result$isOk(result)) ? result : elm$core$Result$Err(A2(elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return (elm$core$Result$isOk(result)) ? result : elm$core$Result$Err(A2(elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!elm$core$Result$isOk(result))
					{
						return elm$core$Result$Err(A2(elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return elm$core$Result$Ok(elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if (elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return elm$core$Result$Err(elm$json$Json$Decode$OneOf(elm$core$List$reverse(errors)));

		case 1:
			return elm$core$Result$Err(A2(elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!elm$core$Result$isOk(result))
		{
			return elm$core$Result$Err(A2(elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList === 'function' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2(elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return elm$core$Result$Err(A2(elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.d6,
		impl.ez,
		impl.eu,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_dispatchEffects(managers, result.b, subscriptions(model));
	}

	_Platform_dispatchEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				p: bag.n,
				q: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.q)
		{
			x = temp.p(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		r: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		r: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.cY.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done(elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done(elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.cY.b, xhr)); });
		elm$core$Maybe$isJust(request.dK) && _Http_track(router, xhr, request.dK.a);

		try {
			xhr.open(request.co, request.cI, true);
		} catch (e) {
			return done(elm$http$Http$BadUrl_(request.cI));
		}

		_Http_configureRequest(xhr, request);

		request.cb.a && xhr.setRequestHeader('Content-Type', request.cb.a);
		xhr.send(request.cb.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.ci; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.cG.a || 0;
	xhr.responseType = request.cY.d;
	xhr.withCredentials = request.V;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? elm$http$Http$GoodStatus_ : elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		cI: xhr.responseURL,
		er: xhr.status,
		es: xhr.statusText,
		ci: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return elm$core$Dict$empty;
	}

	var headers = elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3(elm$core$Dict$update, key, function(oldValue) {
				return elm$core$Maybe$Just(elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2(elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, elm$http$Http$Sending({
			eq: event.loaded,
			cz: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2(elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, elm$http$Http$Receiving({
			eh: event.loaded,
			cz: event.lengthComputable ? elm$core$Maybe$Just(event.total) : elm$core$Maybe$Nothing
		}))));
	});
}



// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2(elm$json$Json$Decode$map, func, handler.a)
				:
			A3(elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		D: func(record.D),
		cC: record.cC,
		cv: record.cv
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.D;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.cC;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.cv) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.d6,
		impl.ez,
		impl.eu,
		function(sendToApp, initialModel) {
			var view = impl.eD;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.d6,
		impl.ez,
		impl.eu,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.aC && impl.aC(sendToApp)
			var view = impl.eD;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.cb);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.ew) && (_VirtualDom_doc.title = title = doc.ew);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.eb;
	var onUrlRequest = impl.ec;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		aC: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.$7 === next.$7
							&& curr.c$ === next.c$
							&& curr.dj.a === next.dj.a
						)
							? elm$browser$Browser$Internal(next)
							: elm$browser$Browser$External(href)
					));
				}
			});
		},
		d6: function(flags)
		{
			return A3(impl.d6, flags, _Browser_getUrl(), key);
		},
		eD: impl.eD,
		ez: impl.ez,
		eu: impl.eu
	});
}

function _Browser_getUrl()
{
	return elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2(elm$core$Task$perform, elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2(elm$core$Task$perform, elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2(elm$core$Task$perform, elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return elm$core$Result$isOk(result) ? elm$core$Maybe$Just(result.a) : elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { d1: 'hidden', dR: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { d1: 'mozHidden', dR: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { d1: 'msHidden', dR: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { d1: 'webkitHidden', dR: 'webkitvisibilitychange' }
		: { d1: 'hidden', dR: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail(elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		dv: _Browser_getScene(),
		dN: {
			b6: _Browser_window.pageXOffset,
			b7: _Browser_window.pageYOffset,
			ak: _Browser_doc.documentElement.clientWidth,
			d$: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		ak: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		d$: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			dv: {
				ak: node.scrollWidth,
				d$: node.scrollHeight
			},
			dN: {
				b6: node.scrollLeft,
				b7: node.scrollTop,
				ak: node.clientWidth,
				d$: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			dv: _Browser_getScene(),
			dN: {
				b6: x,
				b7: y,
				ak: _Browser_doc.documentElement.clientWidth,
				d$: _Browser_doc.documentElement.clientHeight
			},
			dX: {
				b6: x + rect.left,
				b7: y + rect.top,
				ak: rect.width,
				d$: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2(elm$core$Task$perform, elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2(elm$core$Task$perform, elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



// DECODER

var _File_decoder = _Json_decodePrim(function(value) {
	// NOTE: checks if `File` exists in case this is run on node
	return (typeof File !== 'undefined' && value instanceof File)
		? elm$core$Result$Ok(value)
		: _Json_expecting('a FILE', value);
});


// METADATA

function _File_name(file) { return file.name; }
function _File_mime(file) { return file.type; }
function _File_size(file) { return file.size; }

function _File_lastModified(file)
{
	return elm$time$Time$millisToPosix(file.lastModified);
}


// DOWNLOAD

var _File_downloadNode;

function _File_getDownloadNode()
{
	return _File_downloadNode || (_File_downloadNode = document.createElement('a'));
}

var _File_download = F3(function(name, mime, content)
{
	return _Scheduler_binding(function(callback)
	{
		var blob = new Blob([content], {type: mime});

		// for IE10+
		if (navigator.msSaveOrOpenBlob)
		{
			navigator.msSaveOrOpenBlob(blob, name);
			return;
		}

		// for HTML5
		var node = _File_getDownloadNode();
		var objectUrl = URL.createObjectURL(blob);
		node.href = objectUrl;
		node.download = name;
		_File_click(node);
		URL.revokeObjectURL(objectUrl);
	});
});

function _File_downloadUrl(href)
{
	return _Scheduler_binding(function(callback)
	{
		var node = _File_getDownloadNode();
		node.href = href;
		node.download = '';
		node.origin === location.origin || (node.target = '_blank');
		_File_click(node);
	});
}


// IE COMPATIBILITY

function _File_makeBytesSafeForInternetExplorer(bytes)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/10
	// all other browsers can just run `new Blob([bytes])` directly with no problem
	//
	return new Uint8Array(bytes.buffer, bytes.byteOffset, bytes.byteLength);
}

function _File_click(node)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/11
	// all other browsers have MouseEvent and do not need this conditional stuff
	//
	if (typeof MouseEvent === 'function')
	{
		node.dispatchEvent(new MouseEvent('click'));
	}
	else
	{
		var event = document.createEvent('MouseEvents');
		event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
		document.body.appendChild(node);
		node.dispatchEvent(event);
		document.body.removeChild(node);
	}
}


// UPLOAD

var _File_node;

function _File_uploadOne(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.accept = A2(elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			callback(_Scheduler_succeed(event.target.files[0]));
		});
		_File_click(_File_node);
	});
}

function _File_uploadOneOrMore(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.multiple = true;
		_File_node.accept = A2(elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			var elmFiles = _List_fromArray(event.target.files);
			callback(_Scheduler_succeed(_Utils_Tuple2(elmFiles.a, elmFiles.b)));
		});
		_File_click(_File_node);
	});
}


// CONTENT

function _File_toString(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsText(blob);
		return function() { reader.abort(); };
	});
}

function _File_toBytes(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(new DataView(reader.result)));
		});
		reader.readAsArrayBuffer(blob);
		return function() { reader.abort(); };
	});
}

function _File_toUrl(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsDataURL(blob);
		return function() { reader.abort(); };
	});
}

var author$project$Main$LoadingUserData = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var author$project$Main$UserDataLoaded = function (a) {
	return {$: 0, a: a};
};
var author$project$Route$CreatePage = {$: 0};
var elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var elm$core$Array$foldr = F3(
	function (func, baseCase, _n0) {
		var tree = _n0.c;
		var tail = _n0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3(elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3(elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			elm$core$Elm$JsArray$foldr,
			helper,
			A3(elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var elm$core$Basics$EQ = 1;
var elm$core$Basics$LT = 0;
var elm$core$List$cons = _List_cons;
var elm$core$Array$toList = function (array) {
	return A3(elm$core$Array$foldr, elm$core$List$cons, _List_Nil, array);
};
var elm$core$Basics$GT = 2;
var elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3(elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var elm$core$Dict$toList = function (dict) {
	return A3(
		elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var elm$core$Dict$keys = function (dict) {
	return A3(
		elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2(elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var elm$core$Set$toList = function (_n0) {
	var dict = _n0;
	return elm$core$Dict$keys(dict);
};
var elm$core$Basics$gt = _Utils_gt;
var elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var elm$core$Maybe$Nothing = {$: 1};
var elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return elm$core$Maybe$Just(x);
	} else {
		return elm$core$Maybe$Nothing;
	}
};
var elm$core$Basics$add = _Basics_add;
var elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var elm$core$List$length = function (xs) {
	return A3(
		elm$core$List$foldl,
		F2(
			function (_n0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var elm$core$List$reverse = function (list) {
	return A3(elm$core$List$foldl, elm$core$List$cons, _List_Nil, list);
};
var elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return elm$core$Maybe$Just(
				f(value));
		} else {
			return elm$core$Maybe$Nothing;
		}
	});
var elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var elm$core$String$indexes = _String_indexes;
var elm$core$String$slice = _String_slice;
var elm$core$Basics$lt = _Utils_lt;
var elm$core$String$length = _String_length;
var elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			elm$core$String$slice,
			n,
			elm$core$String$length(string),
			string);
	});
var elm$core$String$startsWith = _String_startsWith;
var elm$url$Url$Http = 0;
var elm$url$Url$Https = 1;
var elm$core$Basics$eq = _Utils_equal;
var elm$core$String$isEmpty = function (string) {
	return string === '';
};
var elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(elm$core$String$slice, 0, n, string);
	});
var elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var elm$core$Basics$or = _Basics_or;
var elm$core$String$contains = _String_contains;
var elm$core$String$toInt = _String_toInt;
var elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {cg: fragment, c$: host, ee: path, dj: port_, $7: protocol, eg: query};
	});
var elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if (elm$core$String$isEmpty(str) || A2(elm$core$String$contains, '@', str)) {
			return elm$core$Maybe$Nothing;
		} else {
			var _n0 = A2(elm$core$String$indexes, ':', str);
			if (!_n0.b) {
				return elm$core$Maybe$Just(
					A6(elm$url$Url$Url, protocol, str, elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_n0.b.b) {
					var i = _n0.a;
					var _n1 = elm$core$String$toInt(
						A2(elm$core$String$dropLeft, i + 1, str));
					if (_n1.$ === 1) {
						return elm$core$Maybe$Nothing;
					} else {
						var port_ = _n1;
						return elm$core$Maybe$Just(
							A6(
								elm$url$Url$Url,
								protocol,
								A2(elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return elm$core$Maybe$Nothing;
				}
			}
		}
	});
var elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if (elm$core$String$isEmpty(str)) {
			return elm$core$Maybe$Nothing;
		} else {
			var _n0 = A2(elm$core$String$indexes, '/', str);
			if (!_n0.b) {
				return A5(elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _n0.a;
				return A5(
					elm$url$Url$chompBeforePath,
					protocol,
					A2(elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2(elm$core$String$left, i, str));
			}
		}
	});
var elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if (elm$core$String$isEmpty(str)) {
			return elm$core$Maybe$Nothing;
		} else {
			var _n0 = A2(elm$core$String$indexes, '?', str);
			if (!_n0.b) {
				return A4(elm$url$Url$chompBeforeQuery, protocol, elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _n0.a;
				return A4(
					elm$url$Url$chompBeforeQuery,
					protocol,
					elm$core$Maybe$Just(
						A2(elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2(elm$core$String$left, i, str));
			}
		}
	});
var elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if (elm$core$String$isEmpty(str)) {
			return elm$core$Maybe$Nothing;
		} else {
			var _n0 = A2(elm$core$String$indexes, '#', str);
			if (!_n0.b) {
				return A3(elm$url$Url$chompBeforeFragment, protocol, elm$core$Maybe$Nothing, str);
			} else {
				var i = _n0.a;
				return A3(
					elm$url$Url$chompBeforeFragment,
					protocol,
					elm$core$Maybe$Just(
						A2(elm$core$String$dropLeft, i + 1, str)),
					A2(elm$core$String$left, i, str));
			}
		}
	});
var elm$url$Url$fromString = function (str) {
	return A2(elm$core$String$startsWith, 'http://', str) ? A2(
		elm$url$Url$chompAfterProtocol,
		0,
		A2(elm$core$String$dropLeft, 7, str)) : (A2(elm$core$String$startsWith, 'https://', str) ? A2(
		elm$url$Url$chompAfterProtocol,
		1,
		A2(elm$core$String$dropLeft, 8, str)) : elm$core$Maybe$Nothing);
};
var elm$core$Basics$append = _Utils_append;
var elm$core$String$fromInt = _String_fromNumber;
var elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + elm$core$String$fromInt(port_));
		}
	});
var elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var elm$url$Url$toString = function (url) {
	var http = function () {
		var _n0 = url.$7;
		if (!_n0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		elm$url$Url$addPrefixed,
		'#',
		url.cg,
		A3(
			elm$url$Url$addPrefixed,
			'?',
			url.eg,
			_Utils_ap(
				A2(
					elm$url$Url$addPort,
					url.dj,
					_Utils_ap(http, url.c$)),
				url.ee)));
};
var author$project$Route$getRootUrl = function (initialUrl) {
	var url = elm$url$Url$fromString(initialUrl);
	return A2(
		elm$core$Maybe$withDefault,
		'',
		A2(
			elm$core$Maybe$map,
			function (url_) {
				var candidate = elm$url$Url$toString(
					_Utils_update(
						url_,
						{cg: elm$core$Maybe$Nothing, eg: elm$core$Maybe$Nothing}));
				var slashes = A2(elm$core$String$indexes, '/', candidate);
				var lastSlash = elm$core$List$head(
					elm$core$List$reverse(slashes));
				return (elm$core$List$length(slashes) > 2) ? A2(
					elm$core$Maybe$withDefault,
					candidate,
					A2(
						elm$core$Maybe$map,
						function (idx) {
							return A3(elm$core$String$slice, 0, idx, candidate);
						},
						lastSlash)) : candidate;
			},
			url));
};
var author$project$PromotionId$PromotionId = elm$core$Basics$identity;
var elm$core$Basics$identity = function (x) {
	return x;
};
var elm$url$Url$Parser$Parser = elm$core$Basics$identity;
var elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {M: frag, R: params, G: unvisited, y: value, T: visited};
	});
var elm$url$Url$Parser$custom = F2(
	function (tipe, stringToSomething) {
		return function (_n0) {
			var visited = _n0.T;
			var unvisited = _n0.G;
			var params = _n0.R;
			var frag = _n0.M;
			var value = _n0.y;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				var _n2 = stringToSomething(next);
				if (!_n2.$) {
					var nextValue = _n2.a;
					return _List_fromArray(
						[
							A5(
							elm$url$Url$Parser$State,
							A2(elm$core$List$cons, next, visited),
							rest,
							params,
							frag,
							value(nextValue))
						]);
				} else {
					return _List_Nil;
				}
			}
		};
	});
var author$project$PromotionId$parser = A2(
	elm$url$Url$Parser$custom,
	'PROMOTION_ID',
	function (segment) {
		return A2(
			elm$core$Maybe$map,
			elm$core$Basics$identity,
			elm$core$String$toInt(segment));
	});
var author$project$Route$IdleScreen = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var author$project$Route$ScreenTypePage = function (a) {
	return {$: 1, a: a};
};
var author$project$Route$WaitScreen = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var author$project$Screen$ColourTouch = 1;
var author$project$Screen$DoubleTouch = 3;
var author$project$Screen$ExtraLargeTouch = 2;
var author$project$Screen$SmallColour = 0;
var author$project$Screen$Uno = 4;
var elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var author$project$Screen$screenTypeFromString = function (val) {
	switch (val) {
		case 'SMALL_COLOUR':
			return elm$core$Result$Ok(0);
		case 'COLOUR_TOUCH':
			return elm$core$Result$Ok(1);
		case 'EXTRA_LARGE_TOUCH':
			return elm$core$Result$Ok(2);
		case 'DOUBLE_TOUCH':
			return elm$core$Result$Ok(3);
		case 'UNO':
			return elm$core$Result$Ok(4);
		default:
			return elm$core$Result$Err('ScreenType: ' + (val + ' is not a recognized screen type'));
	}
};
var elm$core$Result$toMaybe = function (result) {
	if (!result.$) {
		var v = result.a;
		return elm$core$Maybe$Just(v);
	} else {
		return elm$core$Maybe$Nothing;
	}
};
var author$project$Route$screenTypeParser = A2(
	elm$url$Url$Parser$custom,
	'SCREEN_TYPE',
	function (segment) {
		return elm$core$Result$toMaybe(
			author$project$Screen$screenTypeFromString(segment));
	});
var elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							elm$core$List$foldl,
							fn,
							acc,
							elm$core$List$reverse(r4)) : A4(elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4(elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var elm$url$Url$Parser$mapState = F2(
	function (func, _n0) {
		var visited = _n0.T;
		var unvisited = _n0.G;
		var params = _n0.R;
		var frag = _n0.M;
		var value = _n0.y;
		return A5(
			elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var elm$url$Url$Parser$map = F2(
	function (subValue, _n0) {
		var parseArg = _n0;
		return function (_n1) {
			var visited = _n1.T;
			var unvisited = _n1.G;
			var params = _n1.R;
			var frag = _n1.M;
			var value = _n1.y;
			return A2(
				elm$core$List$map,
				elm$url$Url$Parser$mapState(value),
				parseArg(
					A5(elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3(elm$core$List$foldr, elm$core$List$cons, ys, xs);
		}
	});
var elm$core$List$concat = function (lists) {
	return A3(elm$core$List$foldr, elm$core$List$append, _List_Nil, lists);
};
var elm$core$List$concatMap = F2(
	function (f, list) {
		return elm$core$List$concat(
			A2(elm$core$List$map, f, list));
	});
var elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			elm$core$List$concatMap,
			function (_n0) {
				var parser = _n0;
				return parser(state);
			},
			parsers);
	};
};
var elm$url$Url$Parser$s = function (str) {
	return function (_n0) {
		var visited = _n0.T;
		var unvisited = _n0.G;
		var params = _n0.R;
		var frag = _n0.M;
		var value = _n0.y;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					elm$url$Url$Parser$State,
					A2(elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var elm$url$Url$Parser$slash = F2(
	function (_n0, _n1) {
		var parseBefore = _n0;
		var parseAfter = _n1;
		return function (state) {
			return A2(
				elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var author$project$Route$parser = elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2(elm$url$Url$Parser$map, author$project$Route$CreatePage, elm$url$Url$Parser$top),
			A2(
			elm$url$Url$Parser$map,
			author$project$Route$ScreenTypePage,
			A2(
				elm$url$Url$Parser$slash,
				elm$url$Url$Parser$s('wizard'),
				A2(
					elm$url$Url$Parser$slash,
					author$project$PromotionId$parser,
					elm$url$Url$Parser$s('screentype')))),
			A2(
			elm$url$Url$Parser$map,
			author$project$Route$IdleScreen,
			A2(
				elm$url$Url$Parser$slash,
				elm$url$Url$Parser$s('wizard'),
				A2(
					elm$url$Url$Parser$slash,
					author$project$PromotionId$parser,
					A2(
						elm$url$Url$Parser$slash,
						author$project$Route$screenTypeParser,
						elm$url$Url$Parser$s('idle'))))),
			A2(
			elm$url$Url$Parser$map,
			author$project$Route$WaitScreen,
			A2(
				elm$url$Url$Parser$slash,
				elm$url$Url$Parser$s('wizard'),
				A2(
					elm$url$Url$Parser$slash,
					author$project$PromotionId$parser,
					A2(
						elm$url$Url$Parser$slash,
						author$project$Route$screenTypeParser,
						elm$url$Url$Parser$s('wait')))))
		]));
var elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _n1 = state.G;
			if (!_n1.b) {
				return elm$core$Maybe$Just(state.y);
			} else {
				if ((_n1.a === '') && (!_n1.b.b)) {
					return elm$core$Maybe$Just(state.y);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				elm$core$List$cons,
				segment,
				elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var elm$url$Url$Parser$preparePath = function (path) {
	var _n0 = A2(elm$core$String$split, '/', path);
	if (_n0.b && (_n0.a === '')) {
		var segments = _n0.b;
		return elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _n0;
		return elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var elm$core$Dict$empty = elm$core$Dict$RBEmpty_elm_builtin;
var elm$core$Basics$compare = _Utils_compare;
var elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _n1 = A2(elm$core$Basics$compare, targetKey, key);
				switch (_n1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var elm$core$Dict$Black = 1;
var elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var elm$core$Dict$Red = 0;
var elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _n1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _n3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5(elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5(elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5(elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _n5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _n6 = left.d;
				var _n7 = _n6.a;
				var llK = _n6.b;
				var llV = _n6.c;
				var llLeft = _n6.d;
				var llRight = _n6.e;
				var lRight = left.e;
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5(elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5(elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5(elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5(elm$core$Dict$RBNode_elm_builtin, 0, key, value, elm$core$Dict$RBEmpty_elm_builtin, elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _n1 = A2(elm$core$Basics$compare, key, nKey);
			switch (_n1) {
				case 0:
					return A5(
						elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3(elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5(elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3(elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _n0 = A3(elm$core$Dict$insertHelp, key, value, dict);
		if ((_n0.$ === -1) && (!_n0.a)) {
			var _n1 = _n0.a;
			var k = _n0.b;
			var v = _n0.c;
			var l = _n0.d;
			var r = _n0.e;
			return A5(elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _n0;
			return x;
		}
	});
var elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _n1 = dict.d;
			var lClr = _n1.a;
			var lK = _n1.b;
			var lV = _n1.c;
			var lLeft = _n1.d;
			var lRight = _n1.e;
			var _n2 = dict.e;
			var rClr = _n2.a;
			var rK = _n2.b;
			var rV = _n2.c;
			var rLeft = _n2.d;
			var _n3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _n2.e;
			return A5(
				elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5(elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5(elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _n4 = dict.d;
			var lClr = _n4.a;
			var lK = _n4.b;
			var lV = _n4.c;
			var lLeft = _n4.d;
			var lRight = _n4.e;
			var _n5 = dict.e;
			var rClr = _n5.a;
			var rK = _n5.b;
			var rV = _n5.c;
			var rLeft = _n5.d;
			var rRight = _n5.e;
			if (clr === 1) {
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5(elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5(elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5(elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5(elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _n1 = dict.d;
			var lClr = _n1.a;
			var lK = _n1.b;
			var lV = _n1.c;
			var _n2 = _n1.d;
			var _n3 = _n2.a;
			var llK = _n2.b;
			var llV = _n2.c;
			var llLeft = _n2.d;
			var llRight = _n2.e;
			var lRight = _n1.e;
			var _n4 = dict.e;
			var rClr = _n4.a;
			var rK = _n4.b;
			var rV = _n4.c;
			var rLeft = _n4.d;
			var rRight = _n4.e;
			return A5(
				elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5(elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5(elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _n5 = dict.d;
			var lClr = _n5.a;
			var lK = _n5.b;
			var lV = _n5.c;
			var lLeft = _n5.d;
			var lRight = _n5.e;
			var _n6 = dict.e;
			var rClr = _n6.a;
			var rK = _n6.b;
			var rV = _n6.c;
			var rLeft = _n6.d;
			var rRight = _n6.e;
			if (clr === 1) {
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5(elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5(elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5(elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5(elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _n1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5(elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_n2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _n3 = right.a;
							var _n4 = right.d;
							var _n5 = _n4.a;
							return elm$core$Dict$moveRedRight(dict);
						} else {
							break _n2$2;
						}
					} else {
						var _n6 = right.a;
						var _n7 = right.d;
						return elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _n2$2;
				}
			}
			return dict;
		}
	});
var elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _n3 = lLeft.a;
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					elm$core$Dict$removeMin(left),
					right);
			} else {
				var _n4 = elm$core$Dict$moveRedLeft(dict);
				if (_n4.$ === -1) {
					var nColor = _n4.a;
					var nKey = _n4.b;
					var nValue = _n4.c;
					var nLeft = _n4.d;
					var nRight = _n4.e;
					return A5(
						elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _n4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _n6 = lLeft.a;
						return A5(
							elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2(elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _n7 = elm$core$Dict$moveRedLeft(dict);
						if (_n7.$ === -1) {
							var nColor = _n7.a;
							var nKey = _n7.b;
							var nValue = _n7.c;
							var nLeft = _n7.d;
							var nRight = _n7.e;
							return A5(
								elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2(elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2(elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7(elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _n1 = elm$core$Dict$getMin(right);
				if (_n1.$ === -1) {
					var minKey = _n1.b;
					var minValue = _n1.c;
					return A5(
						elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						elm$core$Dict$removeMin(right));
				} else {
					return elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2(elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var elm$core$Dict$remove = F2(
	function (key, dict) {
		var _n0 = A2(elm$core$Dict$removeHelp, key, dict);
		if ((_n0.$ === -1) && (!_n0.a)) {
			var _n1 = _n0.a;
			var k = _n0.b;
			var v = _n0.c;
			var l = _n0.d;
			var r = _n0.e;
			return A5(elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _n0;
			return x;
		}
	});
var elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _n0 = alter(
			A2(elm$core$Dict$get, targetKey, dictionary));
		if (!_n0.$) {
			var value = _n0.a;
			return A3(elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2(elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var elm$url$Url$percentDecode = _Url_percentDecode;
var elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return elm$core$Maybe$Just(
				A2(elm$core$List$cons, value, list));
		}
	});
var elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _n0 = A2(elm$core$String$split, '=', segment);
		if ((_n0.b && _n0.b.b) && (!_n0.b.b.b)) {
			var rawKey = _n0.a;
			var _n1 = _n0.b;
			var rawValue = _n1.a;
			var _n2 = elm$url$Url$percentDecode(rawKey);
			if (_n2.$ === 1) {
				return dict;
			} else {
				var key = _n2.a;
				var _n3 = elm$url$Url$percentDecode(rawValue);
				if (_n3.$ === 1) {
					return dict;
				} else {
					var value = _n3.a;
					return A3(
						elm$core$Dict$update,
						key,
						elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			elm$core$List$foldr,
			elm$url$Url$Parser$addParam,
			elm$core$Dict$empty,
			A2(elm$core$String$split, '&', qry));
	}
};
var elm$url$Url$Parser$parse = F2(
	function (_n0, url) {
		var parser = _n0;
		return elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					elm$url$Url$Parser$State,
					_List_Nil,
					elm$url$Url$Parser$preparePath(url.ee),
					elm$url$Url$Parser$prepareQuery(url.eg),
					url.cg,
					elm$core$Basics$identity)));
	});
var author$project$Route$toPage = function (url) {
	return A2(
		elm$url$Url$Parser$parse,
		author$project$Route$parser,
		_Utils_update(
			url,
			{
				cg: elm$core$Maybe$Nothing,
				ee: A2(elm$core$Maybe$withDefault, '', url.cg)
			}));
};
var ChristophP$elm_i18next$I18Next$Translations = elm$core$Basics$identity;
var elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3(elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var ChristophP$elm_i18next$I18Next$foldTree = F3(
	function (initialValue, dict, namespace) {
		return A3(
			elm$core$Dict$foldl,
			F3(
				function (key, val, acc) {
					var newNamespace = function (currentKey) {
						return elm$core$String$isEmpty(namespace) ? currentKey : (namespace + ('.' + currentKey));
					};
					if (val.$ === 1) {
						var str = val.a;
						return A3(
							elm$core$Dict$insert,
							newNamespace(key),
							str,
							acc);
					} else {
						var children = val.a;
						return A3(
							ChristophP$elm_i18next$I18Next$foldTree,
							acc,
							children,
							newNamespace(key));
					}
				}),
			initialValue,
			dict);
	});
var ChristophP$elm_i18next$I18Next$initialTranslations = elm$core$Dict$empty;
var ChristophP$elm_i18next$I18Next$mapTreeToDict = function (tree) {
	if (!tree.$) {
		var dict = tree.a;
		return A3(ChristophP$elm_i18next$I18Next$foldTree, elm$core$Dict$empty, dict, '');
	} else {
		return ChristophP$elm_i18next$I18Next$initialTranslations;
	}
};
var ChristophP$elm_i18next$I18Next$Branch = function (a) {
	return {$: 0, a: a};
};
var ChristophP$elm_i18next$I18Next$Leaf = function (a) {
	return {$: 1, a: a};
};
var elm$core$Dict$fromList = function (assocs) {
	return A3(
		elm$core$List$foldl,
		F2(
			function (_n0, dict) {
				var key = _n0.a;
				var value = _n0.b;
				return A3(elm$core$Dict$insert, key, value, dict);
			}),
		elm$core$Dict$empty,
		assocs);
};
var elm$core$Array$branchFactor = 32;
var elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var elm$core$Basics$ceiling = _Basics_ceiling;
var elm$core$Basics$fdiv = _Basics_fdiv;
var elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var elm$core$Basics$toFloat = _Basics_toFloat;
var elm$core$Array$shiftStep = elm$core$Basics$ceiling(
	A2(elm$core$Basics$logBase, 2, elm$core$Array$branchFactor));
var elm$core$Elm$JsArray$empty = _JsArray_empty;
var elm$core$Array$empty = A4(elm$core$Array$Array_elm_builtin, 0, elm$core$Array$shiftStep, elm$core$Elm$JsArray$empty, elm$core$Elm$JsArray$empty);
var elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _n0 = A2(elm$core$Elm$JsArray$initializeFromList, elm$core$Array$branchFactor, nodes);
			var node = _n0.a;
			var remainingNodes = _n0.b;
			var newAcc = A2(
				elm$core$List$cons,
				elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var elm$core$Tuple$first = function (_n0) {
	var x = _n0.a;
	return x;
};
var elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = elm$core$Basics$ceiling(nodeListSize / elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2(elm$core$Elm$JsArray$initializeFromList, elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2(elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var elm$core$Basics$floor = _Basics_floor;
var elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var elm$core$Basics$mul = _Basics_mul;
var elm$core$Basics$sub = _Basics_sub;
var elm$core$Elm$JsArray$length = _JsArray_length;
var elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.f) {
			return A4(
				elm$core$Array$Array_elm_builtin,
				elm$core$Elm$JsArray$length(builder.h),
				elm$core$Array$shiftStep,
				elm$core$Elm$JsArray$empty,
				builder.h);
		} else {
			var treeLen = builder.f * elm$core$Array$branchFactor;
			var depth = elm$core$Basics$floor(
				A2(elm$core$Basics$logBase, elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? elm$core$List$reverse(builder.i) : builder.i;
			var tree = A2(elm$core$Array$treeFromBuilder, correctNodeList, builder.f);
			return A4(
				elm$core$Array$Array_elm_builtin,
				elm$core$Elm$JsArray$length(builder.h) + treeLen,
				A2(elm$core$Basics$max, 5, depth * elm$core$Array$shiftStep),
				tree,
				builder.h);
		}
	});
var elm$core$Basics$False = 1;
var elm$core$Basics$idiv = _Basics_idiv;
var elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					elm$core$Array$builderToArray,
					false,
					{i: nodeList, f: (len / elm$core$Array$branchFactor) | 0, h: tail});
			} else {
				var leaf = elm$core$Array$Leaf(
					A3(elm$core$Elm$JsArray$initialize, elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2(elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var elm$core$Basics$le = _Utils_le;
var elm$core$Basics$remainderBy = _Basics_remainderBy;
var elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return elm$core$Array$empty;
		} else {
			var tailLen = len % elm$core$Array$branchFactor;
			var tail = A3(elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - elm$core$Array$branchFactor;
			return A5(elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var elm$core$Basics$True = 0;
var elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var elm$core$Basics$and = _Basics_and;
var elm$core$Char$toCode = _Char_toCode;
var elm$core$Char$isLower = function (_char) {
	var code = elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var elm$core$Char$isUpper = function (_char) {
	var code = elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var elm$core$Char$isAlpha = function (_char) {
	return elm$core$Char$isLower(_char) || elm$core$Char$isUpper(_char);
};
var elm$core$Char$isDigit = function (_char) {
	var code = elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var elm$core$Char$isAlphaNum = function (_char) {
	return elm$core$Char$isLower(_char) || (elm$core$Char$isUpper(_char) || elm$core$Char$isDigit(_char));
};
var elm$core$List$map2 = _List_map2;
var elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2(elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var elm$core$List$range = F2(
	function (lo, hi) {
		return A3(elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			elm$core$List$map2,
			f,
			A2(
				elm$core$List$range,
				0,
				elm$core$List$length(xs) - 1),
			xs);
	});
var elm$core$String$all = _String_all;
var elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var elm$core$String$uncons = _String_uncons;
var elm$json$Json$Decode$indent = function (str) {
	return A2(
		elm$core$String$join,
		'\n    ',
		A2(elm$core$String$split, '\n', str));
};
var elm$json$Json$Encode$encode = _Json_encode;
var elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + (elm$core$String$fromInt(i + 1) + (') ' + elm$json$Json$Decode$indent(
			elm$json$Json$Decode$errorToString(error))));
	});
var elm$json$Json$Decode$errorToString = function (error) {
	return A2(elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _n1 = elm$core$String$uncons(f);
						if (_n1.$ === 1) {
							return false;
						} else {
							var _n2 = _n1.a;
							var _char = _n2.a;
							var rest = _n2.b;
							return elm$core$Char$isAlpha(_char) && A2(elm$core$String$all, elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2(elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + (elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2(elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									elm$core$String$join,
									'',
									elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										elm$core$String$join,
										'',
										elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + (elm$core$String$fromInt(
								elm$core$List$length(errors)) + ' ways:'));
							return A2(
								elm$core$String$join,
								'\n\n',
								A2(
									elm$core$List$cons,
									introduction,
									A2(elm$core$List$indexedMap, elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								elm$core$String$join,
								'',
								elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + (elm$json$Json$Decode$indent(
						A2(elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var elm$json$Json$Decode$map = _Json_map1;
var elm$json$Json$Decode$dict = function (decoder) {
	return A2(
		elm$json$Json$Decode$map,
		elm$core$Dict$fromList,
		elm$json$Json$Decode$keyValuePairs(decoder));
};
var elm$json$Json$Decode$andThen = _Json_andThen;
var elm$json$Json$Decode$succeed = _Json_succeed;
var elm$json$Json$Decode$lazy = function (thunk) {
	return A2(
		elm$json$Json$Decode$andThen,
		thunk,
		elm$json$Json$Decode$succeed(0));
};
var elm$json$Json$Decode$oneOf = _Json_oneOf;
var elm$json$Json$Decode$string = _Json_decodeString;
function ChristophP$elm_i18next$I18Next$cyclic$treeDecoder() {
	return elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(elm$json$Json$Decode$map, ChristophP$elm_i18next$I18Next$Leaf, elm$json$Json$Decode$string),
				elm$json$Json$Decode$lazy(
				function (_n0) {
					return A2(
						elm$json$Json$Decode$map,
						ChristophP$elm_i18next$I18Next$Branch,
						elm$json$Json$Decode$dict(
							ChristophP$elm_i18next$I18Next$cyclic$treeDecoder()));
				})
			]));
}
var ChristophP$elm_i18next$I18Next$treeDecoder = ChristophP$elm_i18next$I18Next$cyclic$treeDecoder();
ChristophP$elm_i18next$I18Next$cyclic$treeDecoder = function () {
	return ChristophP$elm_i18next$I18Next$treeDecoder;
};
var ChristophP$elm_i18next$I18Next$translationsDecoder = A2(elm$json$Json$Decode$map, ChristophP$elm_i18next$I18Next$mapTreeToDict, ChristophP$elm_i18next$I18Next$treeDecoder);
var elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var elm$http$Http$NetworkError = {$: 2};
var elm$http$Http$Timeout = {$: 1};
var elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var elm$core$Platform$sendToApp = _Platform_sendToApp;
var elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return elm$core$Result$Err(e);
		}
	});
var elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var elm$http$Http$NetworkError_ = {$: 2};
var elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var elm$http$Http$Timeout_ = {$: 1};
var elm$http$Http$stringResolver = A2(_Http_expect, '', elm$core$Basics$identity);
var elm$json$Json$Decode$decodeString = _Json_runOnString;
var author$project$Endpoint$resolverHelper = function (decoder) {
	return elm$http$Http$stringResolver(
		function (res) {
			switch (res.$) {
				case 0:
					var url = res.a;
					return elm$core$Result$Err(
						elm$http$Http$BadUrl(url));
				case 1:
					return elm$core$Result$Err(elm$http$Http$Timeout);
				case 2:
					return elm$core$Result$Err(elm$http$Http$NetworkError);
				case 3:
					var meta = res.a;
					var body = res.b;
					return elm$core$Result$Err(
						elm$http$Http$BadStatus(meta.er));
				default:
					var body = res.b;
					var _n1 = A2(elm$json$Json$Decode$decodeString, decoder, body);
					if (!_n1.$) {
						var v = _n1.a;
						return elm$core$Result$Ok(v);
					} else {
						var err = _n1.a;
						return elm$core$Result$Err(
							elm$http$Http$BadBody(
								elm$json$Json$Decode$errorToString(err)));
					}
			}
		});
};
var author$project$Endpoint$toUrl = function (_n0) {
	var url = _n0;
	return url;
};
var elm$http$Http$emptyBody = _Http_emptyBody;
var elm$core$Task$fail = _Scheduler_fail;
var elm$core$Task$succeed = _Scheduler_succeed;
var elm$http$Http$resultToTask = function (result) {
	if (!result.$) {
		var a = result.a;
		return elm$core$Task$succeed(a);
	} else {
		var x = result.a;
		return elm$core$Task$fail(x);
	}
};
var elm$http$Http$riskyTask = function (r) {
	return A3(
		_Http_toTask,
		0,
		elm$http$Http$resultToTask,
		{V: true, cb: r.cb, cY: r.el, ci: r.ci, co: r.co, cG: r.cG, dK: elm$core$Maybe$Nothing, cI: r.cI});
};
var author$project$Endpoint$getTask = F2(
	function (endpoint, decoder) {
		return elm$http$Http$riskyTask(
			{
				cb: elm$http$Http$emptyBody,
				ci: _List_Nil,
				co: 'GET',
				el: author$project$Endpoint$resolverHelper(decoder),
				cG: elm$core$Maybe$Nothing,
				cI: author$project$Endpoint$toUrl(endpoint)
			});
	});
var author$project$Endpoint$Endpoint = elm$core$Basics$identity;
var author$project$Endpoint$translations = F2(
	function (_n0, locale) {
		var rootUrl = _n0.ep;
		var version = _n0.eC;
		return rootUrl + ('/languages/translations-' + (locale + ('.json?version=' + version)));
	});
var author$project$Endpoint$user = function (_n0) {
	var apiBaseUrl = _n0.dP;
	return apiBaseUrl + '/rest/user';
};
var author$project$User$UserResult = F3(
	function (user, referenceLanguage, preferredLanguage) {
		return {ef: preferredLanguage, ei: referenceLanguage, aK: user};
	});
var author$project$User$referenceLocale = 'en-GB';
var author$project$User$User = F3(
	function (name, licenseAccount, locale) {
		return {db: licenseAccount, cm: locale, Q: name};
	});
var author$project$User$LicenseName = function (a) {
	return {$: 1, a: a};
};
var author$project$User$Unassigned = {$: 0};
var author$project$User$licenseAccountDecoder = A2(
	elm$json$Json$Decode$andThen,
	function (rawString) {
		if (rawString === 'Unassigned') {
			return elm$json$Json$Decode$succeed(author$project$User$Unassigned);
		} else {
			var nameOfLicenseAccount = rawString;
			return elm$json$Json$Decode$succeed(
				author$project$User$LicenseName(nameOfLicenseAccount));
		}
	},
	elm$json$Json$Decode$string);
var elm$json$Json$Decode$field = _Json_decodeField;
var elm$json$Json$Decode$map3 = _Json_map3;
var author$project$User$userDecoder = A4(
	elm$json$Json$Decode$map3,
	author$project$User$User,
	A2(elm$json$Json$Decode$field, 'username', elm$json$Json$Decode$string),
	A2(elm$json$Json$Decode$field, 'licenseAccountName', author$project$User$licenseAccountDecoder),
	A2(elm$json$Json$Decode$field, 'locale', elm$json$Json$Decode$string));
var elm$core$Basics$neq = _Utils_notEqual;
var elm$core$Task$andThen = _Scheduler_andThen;
var elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var elm$core$Task$Perform = elm$core$Basics$identity;
var elm$core$Task$init = elm$core$Task$succeed(0);
var elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			elm$core$Task$andThen,
			function (a) {
				return elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			elm$core$Task$andThen,
			function (a) {
				return A2(
					elm$core$Task$andThen,
					function (b) {
						return elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var elm$core$Task$sequence = function (tasks) {
	return A3(
		elm$core$List$foldr,
		elm$core$Task$map2(elm$core$List$cons),
		elm$core$Task$succeed(_List_Nil),
		tasks);
};
var elm$core$Task$spawnCmd = F2(
	function (router, _n0) {
		var task = _n0;
		return _Scheduler_spawn(
			A2(
				elm$core$Task$andThen,
				elm$core$Platform$sendToApp(router),
				task));
	});
var elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			elm$core$Task$map,
			function (_n0) {
				return 0;
			},
			elm$core$Task$sequence(
				A2(
					elm$core$List$map,
					elm$core$Task$spawnCmd(router),
					commands)));
	});
var elm$core$Task$onSelfMsg = F3(
	function (_n0, _n1, _n2) {
		return elm$core$Task$succeed(0);
	});
var elm$core$Task$cmdMap = F2(
	function (tagger, _n0) {
		var task = _n0;
		return A2(elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager(elm$core$Task$init, elm$core$Task$onEffects, elm$core$Task$onSelfMsg, elm$core$Task$cmdMap);
var elm$core$Task$command = _Platform_leaf('Task');
var elm$core$Task$onError = _Scheduler_onError;
var elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return elm$core$Task$command(
			A2(
				elm$core$Task$onError,
				A2(
					elm$core$Basics$composeL,
					A2(elm$core$Basics$composeL, elm$core$Task$succeed, resultToMessage),
					elm$core$Result$Err),
				A2(
					elm$core$Task$andThen,
					A2(
						elm$core$Basics$composeL,
						A2(elm$core$Basics$composeL, elm$core$Task$succeed, resultToMessage),
						elm$core$Result$Ok),
					task)));
	});
var author$project$User$fetchUserAndTranslations = F2(
	function (ctx, toMsg) {
		return A2(
			elm$core$Task$attempt,
			toMsg,
			A2(
				elm$core$Task$andThen,
				function (_n0) {
					var user = _n0.a;
					var reference = _n0.b;
					return (!_Utils_eq(user.cm, author$project$User$referenceLocale)) ? A2(
						elm$core$Task$onError,
						function (_n1) {
							return elm$core$Task$succeed(
								A3(author$project$User$UserResult, user, reference, elm$core$Maybe$Nothing));
						},
						A2(
							elm$core$Task$map,
							function (preferred) {
								return A3(
									author$project$User$UserResult,
									user,
									reference,
									elm$core$Maybe$Just(preferred));
							},
							A2(
								author$project$Endpoint$getTask,
								A2(author$project$Endpoint$translations, ctx, user.cm),
								ChristophP$elm_i18next$I18Next$translationsDecoder))) : elm$core$Task$succeed(
						A3(author$project$User$UserResult, user, reference, elm$core$Maybe$Nothing));
				},
				A2(
					elm$core$Task$andThen,
					function (user) {
						return A2(
							elm$core$Task$map,
							function (reference) {
								return _Utils_Tuple2(user, reference);
							},
							A2(
								author$project$Endpoint$getTask,
								A2(author$project$Endpoint$translations, ctx, author$project$User$referenceLocale),
								ChristophP$elm_i18next$I18Next$translationsDecoder));
					},
					A2(
						author$project$Endpoint$getTask,
						author$project$Endpoint$user(ctx),
						author$project$User$userDecoder))));
	});
var elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return elm$core$Maybe$Nothing;
		}
	});
var author$project$Main$init = function (flags) {
	var rootUrl = author$project$Route$getRootUrl(flags.bi);
	var page = A2(
		elm$core$Maybe$withDefault,
		author$project$Route$CreatePage,
		A2(
			elm$core$Maybe$andThen,
			author$project$Route$toPage,
			elm$url$Url$fromString(flags.bi)));
	return _Utils_Tuple2(
		{
			dP: flags.dP,
			d: A4(author$project$Main$LoadingUserData, rootUrl, flags.dP, flags.eC, page),
			ep: rootUrl
		},
		A2(
			author$project$User$fetchUserAndTranslations,
			{dP: flags.dP, ep: rootUrl, eC: flags.eC},
			author$project$Main$UserDataLoaded));
};
var author$project$Main$UrlChanged = function (a) {
	return {$: 1, a: a};
};
var author$project$Main$WizardMsg = function (a) {
	return {$: 5, a: a};
};
var author$project$Main$urlDidChange = _Platform_incomingPort('urlDidChange', elm$json$Json$Decode$string);
var author$project$PromotionWizard$PreviewToggled = function (a) {
	return {$: 11, a: a};
};
var elm$browser$Browser$AnimationManager$Time = function (a) {
	return {$: 0, a: a};
};
var elm$browser$Browser$AnimationManager$State = F3(
	function (subs, request, oldTime) {
		return {cq: oldTime, dr: request, dB: subs};
	});
var elm$browser$Browser$AnimationManager$init = elm$core$Task$succeed(
	A3(elm$browser$Browser$AnimationManager$State, _List_Nil, elm$core$Maybe$Nothing, 0));
var elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var elm$browser$Browser$Dom$NotFound = elm$core$Basics$identity;
var elm$core$Basics$never = function (_n0) {
	never:
	while (true) {
		var nvr = _n0;
		var $temp$_n0 = nvr;
		_n0 = $temp$_n0;
		continue never;
	}
};
var elm$core$Task$perform = F2(
	function (toMessage, task) {
		return elm$core$Task$command(
			A2(elm$core$Task$map, toMessage, task));
	});
var elm$json$Json$Decode$map2 = _Json_map2;
var elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var elm$browser$Browser$AnimationManager$now = _Browser_now(0);
var elm$browser$Browser$AnimationManager$rAF = _Browser_rAF(0);
var elm$core$Process$kill = _Scheduler_kill;
var elm$core$Process$spawn = _Scheduler_spawn;
var elm$browser$Browser$AnimationManager$onEffects = F3(
	function (router, subs, _n0) {
		var request = _n0.dr;
		var oldTime = _n0.cq;
		var _n1 = _Utils_Tuple2(request, subs);
		if (_n1.a.$ === 1) {
			if (!_n1.b.b) {
				var _n2 = _n1.a;
				return elm$browser$Browser$AnimationManager$init;
			} else {
				var _n4 = _n1.a;
				return A2(
					elm$core$Task$andThen,
					function (pid) {
						return A2(
							elm$core$Task$andThen,
							function (time) {
								return elm$core$Task$succeed(
									A3(
										elm$browser$Browser$AnimationManager$State,
										subs,
										elm$core$Maybe$Just(pid),
										time));
							},
							elm$browser$Browser$AnimationManager$now);
					},
					elm$core$Process$spawn(
						A2(
							elm$core$Task$andThen,
							elm$core$Platform$sendToSelf(router),
							elm$browser$Browser$AnimationManager$rAF)));
			}
		} else {
			if (!_n1.b.b) {
				var pid = _n1.a.a;
				return A2(
					elm$core$Task$andThen,
					function (_n3) {
						return elm$browser$Browser$AnimationManager$init;
					},
					elm$core$Process$kill(pid));
			} else {
				return elm$core$Task$succeed(
					A3(elm$browser$Browser$AnimationManager$State, subs, request, oldTime));
			}
		}
	});
var elm$time$Time$Posix = elm$core$Basics$identity;
var elm$time$Time$millisToPosix = elm$core$Basics$identity;
var elm$browser$Browser$AnimationManager$onSelfMsg = F3(
	function (router, newTime, _n0) {
		var subs = _n0.dB;
		var oldTime = _n0.cq;
		var send = function (sub) {
			if (!sub.$) {
				var tagger = sub.a;
				return A2(
					elm$core$Platform$sendToApp,
					router,
					tagger(
						elm$time$Time$millisToPosix(newTime)));
			} else {
				var tagger = sub.a;
				return A2(
					elm$core$Platform$sendToApp,
					router,
					tagger(newTime - oldTime));
			}
		};
		return A2(
			elm$core$Task$andThen,
			function (pid) {
				return A2(
					elm$core$Task$andThen,
					function (_n1) {
						return elm$core$Task$succeed(
							A3(
								elm$browser$Browser$AnimationManager$State,
								subs,
								elm$core$Maybe$Just(pid),
								newTime));
					},
					elm$core$Task$sequence(
						A2(elm$core$List$map, send, subs)));
			},
			elm$core$Process$spawn(
				A2(
					elm$core$Task$andThen,
					elm$core$Platform$sendToSelf(router),
					elm$browser$Browser$AnimationManager$rAF)));
	});
var elm$browser$Browser$AnimationManager$Delta = function (a) {
	return {$: 1, a: a};
};
var elm$browser$Browser$AnimationManager$subMap = F2(
	function (func, sub) {
		if (!sub.$) {
			var tagger = sub.a;
			return elm$browser$Browser$AnimationManager$Time(
				A2(elm$core$Basics$composeL, func, tagger));
		} else {
			var tagger = sub.a;
			return elm$browser$Browser$AnimationManager$Delta(
				A2(elm$core$Basics$composeL, func, tagger));
		}
	});
_Platform_effectManagers['Browser.AnimationManager'] = _Platform_createManager(elm$browser$Browser$AnimationManager$init, elm$browser$Browser$AnimationManager$onEffects, elm$browser$Browser$AnimationManager$onSelfMsg, 0, elm$browser$Browser$AnimationManager$subMap);
var elm$browser$Browser$AnimationManager$subscription = _Platform_leaf('Browser.AnimationManager');
var elm$browser$Browser$AnimationManager$onAnimationFrame = function (tagger) {
	return elm$browser$Browser$AnimationManager$subscription(
		elm$browser$Browser$AnimationManager$Time(tagger));
};
var elm$browser$Browser$Events$onAnimationFrame = elm$browser$Browser$AnimationManager$onAnimationFrame;
var elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2(elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var elm$core$Platform$Sub$batch = _Platform_batch;
var elm$core$Platform$Sub$none = elm$core$Platform$Sub$batch(_List_Nil);
var pzp1997$assoc_list$AssocList$toList = function (_n0) {
	var alist = _n0;
	return alist;
};
var author$project$PromotionWizard$subscription = F2(
	function (_n0, toMsg) {
		var screenPreviews = _n0.x;
		var maybeSub = elm$core$List$head(
			A2(
				elm$core$List$filter,
				function (_n4) {
					var k = _n4.a;
					var v = _n4.b;
					switch (v.$) {
						case 1:
							return true;
						case 3:
							return true;
						default:
							return false;
					}
				},
				pzp1997$assoc_list$AssocList$toList(screenPreviews)));
		if (!maybeSub.$) {
			var _n2 = maybeSub.a;
			var k = _n2.a;
			var v = _n2.b;
			return elm$browser$Browser$Events$onAnimationFrame(
				function (_n3) {
					return toMsg(
						author$project$PromotionWizard$PreviewToggled(k));
				});
		} else {
			return elm$core$Platform$Sub$none;
		}
	});
var author$project$Main$subscriptions = function (model) {
	var wizardSubs = function () {
		var _n0 = model.d;
		if (_n0.$ === 4) {
			var wizardModel = _n0.b;
			return _List_fromArray(
				[
					A2(author$project$PromotionWizard$subscription, wizardModel, author$project$Main$WizardMsg)
				]);
		} else {
			return _List_Nil;
		}
	}();
	var allSubscriptions = A2(
		elm$core$List$cons,
		author$project$Main$urlDidChange(author$project$Main$UrlChanged),
		wizardSubs);
	return elm$core$Platform$Sub$batch(allSubscriptions);
};
var author$project$CreatePromotion$NoPromotion = {$: 0};
var krisajenkins$remotedata$RemoteData$NotAsked = {$: 0};
var author$project$CreatePromotion$init = function (promotions) {
	return {v: author$project$CreatePromotion$NoPromotion, ap: '', L: _List_Nil, Q: '', aB: promotions, ai: krisajenkins$remotedata$RemoteData$NotAsked};
};
var author$project$CreatePromotion$BasedOnPromotionField = 1;
var author$project$CreatePromotion$NameField = 0;
var author$project$CreatePromotion$PromotionCreated = function (a) {
	return {$: 1, a: a};
};
var author$project$CreatePromotion$Unauthorized = {$: 2};
var author$project$CreatePromotion$WithCommand = function (a) {
	return {$: 0, a: a};
};
var elm$core$Platform$Cmd$batch = _Platform_batch;
var elm$core$Platform$Cmd$none = elm$core$Platform$Cmd$batch(_List_Nil);
var author$project$CreatePromotion$noCmd = author$project$CreatePromotion$WithCommand(elm$core$Platform$Cmd$none);
var author$project$CreatePromotion$NewPromotionResponse = function (a) {
	return {$: 4, a: a};
};
var elm$json$Json$Encode$int = _Json_wrap;
var author$project$PromotionId$encode = function (_n0) {
	var id = _n0;
	return elm$json$Json$Encode$int(id);
};
var elm$json$Json$Encode$null = _Json_encodeNull;
var elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			elm$core$List$foldl,
			F2(
				function (_n0, obj) {
					var k = _n0.a;
					var v = _n0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var elm$json$Json$Encode$string = _Json_wrap;
var author$project$CreatePromotion$encodePromotionRequest = function (req) {
	return elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				elm$json$Json$Encode$string(req.Q)),
				_Utils_Tuple2(
				'description',
				elm$json$Json$Encode$string(req.ap)),
				_Utils_Tuple2(
				'copyResourcesFromTemplateId',
				function () {
					var _n0 = req.v;
					if (_n0.$ === 2) {
						var id = _n0.a;
						return author$project$PromotionId$encode(id);
					} else {
						return elm$json$Json$Encode$null;
					}
				}())
			]));
};
var elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return elm$core$Result$Err(
				f(e));
		}
	});
var elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			elm$core$Basics$identity,
			A2(elm$core$Basics$composeR, toResult, toMsg));
	});
var elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return elm$core$Result$Err(
					elm$http$Http$BadUrl(url));
			case 1:
				return elm$core$Result$Err(elm$http$Http$Timeout);
			case 2:
				return elm$core$Result$Err(elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return elm$core$Result$Err(
					elm$http$Http$BadStatus(metadata.er));
			default:
				var body = response.b;
				return A2(
					elm$core$Result$mapError,
					elm$http$Http$BadBody,
					toResult(body));
		}
	});
var elm$http$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			elm$http$Http$expectStringResponse,
			toMsg,
			elm$http$Http$resolve(
				function (string) {
					return A2(
						elm$core$Result$mapError,
						elm$json$Json$Decode$errorToString,
						A2(elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var elm$http$Http$State = F2(
	function (reqs, subs) {
		return {dq: reqs, dB: subs};
	});
var elm$http$Http$init = elm$core$Task$succeed(
	A2(elm$http$Http$State, elm$core$Dict$empty, _List_Nil));
var elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _n2 = A2(elm$core$Dict$get, tracker, reqs);
					if (_n2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _n2.a;
						return A2(
							elm$core$Task$andThen,
							function (_n3) {
								return A3(
									elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2(elm$core$Dict$remove, tracker, reqs));
							},
							elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						elm$core$Task$andThen,
						function (pid) {
							var _n4 = req.dK;
							if (_n4.$ === 1) {
								return A3(elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _n4.a;
								return A3(
									elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3(elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			elm$core$Task$andThen,
			function (reqs) {
				return elm$core$Task$succeed(
					A2(elm$http$Http$State, reqs, subs));
			},
			A3(elm$http$Http$updateReqs, router, cmds, state.dq));
	});
var elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _n0 = f(mx);
		if (!_n0.$) {
			var x = _n0.a;
			return A2(elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			elm$core$List$foldr,
			elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _n0) {
		var actualTracker = _n0.a;
		var toMsg = _n0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? elm$core$Maybe$Just(
			A2(
				elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : elm$core$Maybe$Nothing;
	});
var elm$http$Http$onSelfMsg = F3(
	function (router, _n0, state) {
		var tracker = _n0.a;
		var progress = _n0.b;
		return A2(
			elm$core$Task$andThen,
			function (_n1) {
				return elm$core$Task$succeed(state);
			},
			elm$core$Task$sequence(
				A2(
					elm$core$List$filterMap,
					A3(elm$http$Http$maybeSend, router, tracker, progress),
					state.dB)));
	});
var elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return elm$http$Http$Request(
				{
					V: r.V,
					cb: r.cb,
					cY: A2(_Http_mapExpect, func, r.cY),
					ci: r.ci,
					co: r.co,
					cG: r.cG,
					dK: r.dK,
					cI: r.cI
				});
		}
	});
var elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var elm$http$Http$subMap = F2(
	function (func, _n0) {
		var tracker = _n0.a;
		var toMsg = _n0.b;
		return A2(
			elm$http$Http$MySub,
			tracker,
			A2(elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager(elm$http$Http$init, elm$http$Http$onEffects, elm$http$Http$onSelfMsg, elm$http$Http$cmdMap, elm$http$Http$subMap);
var elm$http$Http$command = _Platform_leaf('Http');
var elm$http$Http$subscription = _Platform_leaf('Http');
var elm$http$Http$riskyRequest = function (r) {
	return elm$http$Http$command(
		elm$http$Http$Request(
			{V: true, cb: r.cb, cY: r.cY, ci: r.ci, co: r.co, cG: r.cG, dK: r.dK, cI: r.cI}));
};
var krisajenkins$remotedata$RemoteData$Failure = function (a) {
	return {$: 2, a: a};
};
var krisajenkins$remotedata$RemoteData$Success = function (a) {
	return {$: 3, a: a};
};
var krisajenkins$remotedata$RemoteData$fromResult = function (result) {
	if (result.$ === 1) {
		var e = result.a;
		return krisajenkins$remotedata$RemoteData$Failure(e);
	} else {
		var x = result.a;
		return krisajenkins$remotedata$RemoteData$Success(x);
	}
};
var author$project$Endpoint$post = F4(
	function (endpoint, toMsg, body, decoder) {
		return elm$http$Http$riskyRequest(
			{
				cb: body,
				cY: A2(
					elm$http$Http$expectJson,
					A2(elm$core$Basics$composeR, krisajenkins$remotedata$RemoteData$fromResult, toMsg),
					decoder),
				ci: _List_Nil,
				co: 'POST',
				cG: elm$core$Maybe$Nothing,
				dK: elm$core$Maybe$Nothing,
				cI: author$project$Endpoint$toUrl(endpoint)
			});
	});
var author$project$Endpoint$promotions = function (_n0) {
	var apiBaseUrl = _n0.dP;
	return apiBaseUrl + '/rest/promotion';
};
var author$project$Promotion$Promotion = F6(
	function (id, name, description, created, createdBy, resources) {
		return {dV: created, dW: createdBy, ap: description, c2: id, Q: name, em: resources};
	});
var elm$json$Json$Decode$null = _Json_decodeNull;
var elm$json$Json$Decode$nullable = function (decoder) {
	return elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				elm$json$Json$Decode$null(elm$core$Maybe$Nothing),
				A2(elm$json$Json$Decode$map, elm$core$Maybe$Just, decoder)
			]));
};
var author$project$Promotion$nullableStringDecoder = function (field) {
	return A2(
		elm$json$Json$Decode$field,
		field,
		A2(
			elm$json$Json$Decode$andThen,
			function (name) {
				return A2(
					elm$core$Maybe$withDefault,
					elm$json$Json$Decode$succeed('<null>'),
					A2(elm$core$Maybe$map, elm$json$Json$Decode$succeed, name));
			},
			elm$json$Json$Decode$nullable(elm$json$Json$Decode$string)));
};
var author$project$Promotion$PromotionResource = F5(
	function (id, name, required, uploaded, uploadedFile) {
		return {c2: id, Q: name, ek: required, eA: uploaded, eB: uploadedFile};
	});
var author$project$Screen$PromotionAccountMultiFeed = 13;
var author$project$Screen$PromotionDoubleScreenBorder = 10;
var author$project$Screen$PromotionIdle = 0;
var author$project$Screen$PromotionIdleBottom = 4;
var author$project$Screen$PromotionIdleMk3 = 1;
var author$project$Screen$PromotionIdleMultiFeed = 11;
var author$project$Screen$PromotionIdleTop = 3;
var author$project$Screen$PromotionIdleUno = 2;
var author$project$Screen$PromotionInUseBorder = 9;
var author$project$Screen$PromotionWait = 5;
var author$project$Screen$PromotionWaitBottom = 8;
var author$project$Screen$PromotionWaitMk3 = 6;
var author$project$Screen$PromotionWaitMultiFeed = 12;
var author$project$Screen$PromotionWaitUno = 7;
var elm$json$Json$Decode$fail = _Json_fail;
var author$project$Screen$screenResourceDecoder = function (val) {
	switch (val) {
		case 'PROMOTION_IDLE':
			return elm$json$Json$Decode$succeed(0);
		case 'PROMOTION_IDLE_MK3':
			return elm$json$Json$Decode$succeed(1);
		case 'PROMOTION_IDLE_UNO':
			return elm$json$Json$Decode$succeed(2);
		case 'PROMOTION_IDLE_TOP':
			return elm$json$Json$Decode$succeed(3);
		case 'PROMOTION_IDLE_BOTTOM':
			return elm$json$Json$Decode$succeed(4);
		case 'PROMOTION_WAIT':
			return elm$json$Json$Decode$succeed(5);
		case 'PROMOTION_WAIT_MK3':
			return elm$json$Json$Decode$succeed(6);
		case 'PROMOTION_WAIT_UNO':
			return elm$json$Json$Decode$succeed(7);
		case 'PROMOTION_WAIT_BOTTOM':
			return elm$json$Json$Decode$succeed(8);
		case 'PROMOTION_IN_USE_BORDER':
			return elm$json$Json$Decode$succeed(9);
		case 'PROMOTION_DOUBLE_SCREEN_BORDER':
			return elm$json$Json$Decode$succeed(10);
		case 'PROMOTION_IDLE_MULTIFEED':
			return elm$json$Json$Decode$succeed(11);
		case 'PROMOTION_ACCOUNT_MULTIFEED':
			return elm$json$Json$Decode$succeed(13);
		case 'PROMOTION_WAIT_MULTIFEED':
			return elm$json$Json$Decode$succeed(12);
		default:
			return elm$json$Json$Decode$fail('Resource with name: ' + (val + ' is not a recognized resource name'));
	}
};
var elm$json$Json$Decode$bool = _Json_decodeBool;
var elm$json$Json$Decode$int = _Json_decodeInt;
var elm$json$Json$Decode$map5 = _Json_map5;
var author$project$Promotion$promotionResourceDecoder = A6(
	elm$json$Json$Decode$map5,
	author$project$Promotion$PromotionResource,
	A2(elm$json$Json$Decode$field, 'id', elm$json$Json$Decode$int),
	A2(
		elm$json$Json$Decode$andThen,
		author$project$Screen$screenResourceDecoder,
		A2(elm$json$Json$Decode$field, 'resourceName', elm$json$Json$Decode$string)),
	A2(elm$json$Json$Decode$field, 'required', elm$json$Json$Decode$bool),
	A2(elm$json$Json$Decode$field, 'uploaded', elm$json$Json$Decode$bool),
	elm$json$Json$Decode$succeed(elm$core$Maybe$Nothing));
var author$project$PromotionId$decoder = A2(elm$json$Json$Decode$map, elm$core$Basics$identity, elm$json$Json$Decode$int);
var elm$json$Json$Decode$list = _Json_decodeList;
var elm$json$Json$Decode$map6 = _Json_map6;
var pzp1997$assoc_list$AssocList$D = elm$core$Basics$identity;
var pzp1997$assoc_list$AssocList$remove = F2(
	function (targetKey, _n0) {
		var alist = _n0;
		return A2(
			elm$core$List$filter,
			function (_n1) {
				var key = _n1.a;
				return !_Utils_eq(key, targetKey);
			},
			alist);
	});
var pzp1997$assoc_list$AssocList$insert = F3(
	function (key, value, dict) {
		var _n0 = A2(pzp1997$assoc_list$AssocList$remove, key, dict);
		var alteredAlist = _n0;
		return A2(
			elm$core$List$cons,
			_Utils_Tuple2(key, value),
			alteredAlist);
	});
var pzp1997$assoc_list$AssocList$fromList = function (alist) {
	return A3(
		elm$core$List$foldl,
		F2(
			function (_n0, result) {
				var key = _n0.a;
				var value = _n0.b;
				return A3(pzp1997$assoc_list$AssocList$insert, key, value, result);
			}),
		_List_Nil,
		alist);
};
var author$project$Promotion$promotionDecoder = A7(
	elm$json$Json$Decode$map6,
	author$project$Promotion$Promotion,
	A2(elm$json$Json$Decode$field, 'id', author$project$PromotionId$decoder),
	author$project$Promotion$nullableStringDecoder('name'),
	author$project$Promotion$nullableStringDecoder('description'),
	A2(
		elm$json$Json$Decode$field,
		'created',
		A2(elm$json$Json$Decode$map, elm$time$Time$millisToPosix, elm$json$Json$Decode$int)),
	A2(elm$json$Json$Decode$field, 'createdBy', elm$json$Json$Decode$string),
	A2(
		elm$json$Json$Decode$andThen,
		function (items) {
			var mapRes = A2(
				elm$core$List$map,
				function (_n2) {
					var k = _n2.a;
					var v = _n2.b;
					return A2(
						elm$core$Result$map,
						function (st) {
							return _Utils_Tuple2(st, v);
						},
						author$project$Screen$screenTypeFromString(k));
				},
				items);
			var failures = A2(
				elm$core$List$filter,
				function (res) {
					if (res.$ === 1) {
						return true;
					} else {
						return false;
					}
				},
				mapRes);
			return (elm$core$List$length(failures) > 0) ? elm$json$Json$Decode$fail(
				A2(
					elm$core$String$join,
					'\n',
					A2(
						elm$core$List$map,
						function (res) {
							if (res.$ === 1) {
								var err = res.a;
								return err;
							} else {
								return '';
							}
						},
						failures))) : elm$json$Json$Decode$succeed(
				pzp1997$assoc_list$AssocList$fromList(
					A2(
						elm$core$List$filterMap,
						elm$core$Basics$identity,
						A2(elm$core$List$map, elm$core$Result$toMaybe, mapRes))));
		},
		A2(
			elm$json$Json$Decode$map,
			elm$core$Dict$toList,
			A2(
				elm$json$Json$Decode$field,
				'resources',
				elm$json$Json$Decode$dict(
					elm$json$Json$Decode$list(author$project$Promotion$promotionResourceDecoder))))));
var elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2(elm$json$Json$Encode$encode, 0, value));
};
var author$project$CreatePromotion$postPromotionRequest = F2(
	function (ctx, req) {
		return A4(
			author$project$Endpoint$post,
			author$project$Endpoint$promotions(ctx),
			author$project$CreatePromotion$NewPromotionResponse,
			elm$http$Http$jsonBody(
				author$project$CreatePromotion$encodePromotionRequest(req)),
			author$project$Promotion$promotionDecoder);
	});
var author$project$CreatePromotion$PromotionNotSelected = {$: 1};
var author$project$Language$FieldName = function (a) {
	return {$: 1, a: a};
};
var author$project$Language$TPL_BASE_REQUIRED = 26;
var author$project$Language$TPL_NAME = 22;
var author$project$Language$VLD_REQUIRED = 16;
var ChristophP$elm_i18next$I18Next$tf = F2(
	function (translationsList, key) {
		if (translationsList.b) {
			var translations = translationsList.a;
			var rest = translationsList.b;
			return A2(
				elm$core$Maybe$withDefault,
				A2(ChristophP$elm_i18next$I18Next$tf, rest, key),
				A2(elm$core$Dict$get, key, translations));
		} else {
			return key;
		}
	});
var author$project$Language$getTranslations = function (ctx) {
	return function (ts) {
		return _Utils_ap(
			ts,
			_List_fromArray(
				[ctx.ej]));
	}(
		A2(
			elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				elm$core$Maybe$map,
				function (l) {
					return _List_fromArray(
						[l]);
				},
				ctx.ey)));
};
var author$project$Language$keyToString = function (key) {
	switch (key) {
		case 0:
			return 'btn.next';
		case 1:
			return 'btn.back';
		case 2:
			return 'btn.edit';
		case 3:
			return 'btn.done';
		case 4:
			return 'btn.viewTemplates';
		case 5:
			return 'btn.createCampaign';
		case 6:
			return 'btn.upload';
		case 7:
			return 'btn.reset';
		case 8:
			return 'btn.close';
		case 9:
			return 'menu.dashboard';
		case 10:
			return 'menu.showTranslations';
		case 11:
			return 'menu.hideTranslations';
		case 12:
			return 'menu.licenceAccountUnassigned';
		case 14:
			return 'form.optional';
		case 13:
			return 'form.required';
		case 15:
			return 'form.maxCharacters';
		case 16:
			return 'validation.required';
		case 17:
			return 'errors.notAuthorized';
		case 18:
			return 'errors.notFound';
		case 19:
			return 'errors.serverError';
		case 20:
			return 'errors.needsLicenseAccountAssigned';
		case 21:
			return 'material.ingress';
		case 22:
			return 'template.name';
		case 23:
			return 'template.createNew';
		case 24:
			return 'template.createBasedOn';
		case 25:
			return 'template.description';
		case 26:
			return 'template.basedOnRequired';
		case 27:
			return 'promotion.step';
		case 28:
			return 'smallColor.heading';
		case 29:
			return 'smallColor.extraInfo';
		case 30:
			return 'largeTouch.heading';
		case 31:
			return 'largeTouch.extraInfo';
		case 32:
			return 'doubleTouch.heading';
		case 33:
			return 'doubleTouch.extraInfo';
		case 34:
			return 'uno.heading';
		case 35:
			return 'uno.extraInfo';
		case 36:
			return 'extraLargeTouch.heading';
		case 37:
			return 'extraLargeTouch.extraInfo';
		case 38:
			return 'extraLargeTouch.downloadIdleScreenRules';
		case 39:
			return 'promotion.screenAreaHeadings.main';
		case 40:
			return 'promotion.screenAreaHeadings.bottom';
		case 41:
			return 'promotion.screenAreaHeadings.background';
		case 42:
			return 'promotion.mediaTypes.idle';
		case 43:
			return 'promotion.mediaTypes.idleTop';
		case 44:
			return 'promotion.mediaTypes.idleBottom';
		case 45:
			return 'promotion.mediaTypes.wait';
		case 46:
			return 'promotion.mediaTypes.waitBottom';
		case 47:
			return 'promotion.mediaTypes.background';
		case 48:
			return 'promotion.mediaTypes.account';
		case 49:
			return 'promotion.screenTypePage.heading';
		case 50:
			return 'promotion.screenTypePage.ingress';
		case 51:
			return 'promotion.screenTypePage.showPreview';
		case 52:
			return 'promotion.idleScreenPage.heading';
		case 53:
			return 'promotion.idleScreenPage.ingress';
		case 54:
			return 'promotion.accountScreenPage.heading';
		case 55:
			return 'promotion.waitScreenPage.heading';
		case 56:
			return 'promotion.waitScreenPage.ingress';
		case 57:
			return 'upload.uploadingHeading';
		case 58:
			return 'upload.errorHeading';
		case 59:
			return 'upload.errorDetails';
		case 60:
			return 'reset.errorHeading';
		default:
			return 'reset.errorDetails';
	}
};
var author$project$Language$t = F2(
	function (ctx, key) {
		return A2(
			ChristophP$elm_i18next$I18Next$tf,
			author$project$Language$getTranslations(ctx),
			author$project$Language$keyToString(key));
	});
var ChristophP$elm_i18next$I18Next$Curly = {$: 0};
var ChristophP$elm_i18next$I18Next$delimsToTuple = function (delims) {
	switch (delims.$) {
		case 0:
			return _Utils_Tuple2('{{', '}}');
		case 1:
			return _Utils_Tuple2('__', '__');
		default:
			var tuple = delims.a;
			return tuple;
	}
};
var elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			elm$core$String$join,
			after,
			A2(elm$core$String$split, before, string));
	});
var ChristophP$elm_i18next$I18Next$replacePlaceholders = F3(
	function (replacements, delims, string) {
		var _n0 = ChristophP$elm_i18next$I18Next$delimsToTuple(delims);
		var start = _n0.a;
		var end = _n0.b;
		return A3(
			elm$core$List$foldl,
			F2(
				function (_n1, acc) {
					var key = _n1.a;
					var value = _n1.b;
					return A3(
						elm$core$String$replace,
						_Utils_ap(
							start,
							_Utils_ap(key, end)),
						value,
						acc);
				}),
			string,
			replacements);
	});
var ChristophP$elm_i18next$I18Next$trf = F4(
	function (translationsList, delims, key, replacements) {
		if (translationsList.b) {
			var translations = translationsList.a;
			var rest = translationsList.b;
			return A2(
				elm$core$Maybe$withDefault,
				A4(ChristophP$elm_i18next$I18Next$trf, rest, delims, key, replacements),
				A2(
					elm$core$Maybe$map,
					A2(ChristophP$elm_i18next$I18Next$replacePlaceholders, replacements, delims),
					A2(elm$core$Dict$get, key, translations)));
		} else {
			return key;
		}
	});
var author$project$Language$replacementToI18n = function (replacement) {
	switch (replacement.$) {
		case 0:
			var step = replacement.a;
			return _Utils_Tuple2(
				'number',
				elm$core$String$fromInt(step));
		case 1:
			var name = replacement.a;
			return _Utils_Tuple2('fieldName', name);
		default:
			var details = replacement.a;
			return _Utils_Tuple2('errorDetails', details);
	}
};
var author$project$Language$tr = F3(
	function (ctx, key, replacements) {
		return A4(
			ChristophP$elm_i18next$I18Next$trf,
			author$project$Language$getTranslations(ctx),
			ChristophP$elm_i18next$I18Next$Curly,
			author$project$Language$keyToString(key),
			A2(elm$core$List$map, author$project$Language$replacementToI18n, replacements));
	});
var rtfeldman$elm_validate$Validate$Validator = elm$core$Basics$identity;
var rtfeldman$elm_validate$Validate$all = function (validators) {
	var newGetErrors = function (subject) {
		var accumulateErrors = F2(
			function (_n0, totalErrors) {
				var getErrors = _n0;
				return _Utils_ap(
					totalErrors,
					getErrors(subject));
			});
		return A3(elm$core$List$foldl, accumulateErrors, _List_Nil, validators);
	};
	return newGetErrors;
};
var rtfeldman$elm_validate$Validate$ifTrue = F2(
	function (test, error) {
		var getErrors = function (subject) {
			return test(subject) ? _List_fromArray(
				[error]) : _List_Nil;
		};
		return getErrors;
	});
var rtfeldman$elm_validate$Validate$isWhitespaceChar = function (_char) {
	return (_char === ' ') || ((_char === '\n') || ((_char === '\t') || (_char === '\u000d')));
};
var rtfeldman$elm_validate$Validate$isBlank = function (str) {
	isBlank:
	while (true) {
		var _n0 = elm$core$String$uncons(str);
		if (!_n0.$) {
			var _n1 = _n0.a;
			var _char = _n1.a;
			var rest = _n1.b;
			if (rtfeldman$elm_validate$Validate$isWhitespaceChar(_char)) {
				var $temp$str = rest;
				str = $temp$str;
				continue isBlank;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}
};
var rtfeldman$elm_validate$Validate$ifBlank = F2(
	function (subjectToString, error) {
		return A2(
			rtfeldman$elm_validate$Validate$ifTrue,
			function (subject) {
				return rtfeldman$elm_validate$Validate$isBlank(
					subjectToString(subject));
			},
			error);
	});
var author$project$CreatePromotion$promotionRequestValidator = function (sharedState) {
	return rtfeldman$elm_validate$Validate$all(
		_List_fromArray(
			[
				A2(
				rtfeldman$elm_validate$Validate$ifBlank,
				function ($) {
					return $.Q;
				},
				_Utils_Tuple2(
					0,
					A3(
						author$project$Language$tr,
						sharedState,
						16,
						_List_fromArray(
							[
								author$project$Language$FieldName(
								A2(author$project$Language$t, sharedState, 22))
							])))),
				A2(
				rtfeldman$elm_validate$Validate$ifTrue,
				function (_n0) {
					var basedOnPromotion = _n0.v;
					return _Utils_eq(basedOnPromotion, author$project$CreatePromotion$PromotionNotSelected);
				},
				_Utils_Tuple2(
					1,
					A2(author$project$Language$t, sharedState, 26)))
			]));
};
var author$project$CreatePromotion$resetErrorsFor = F2(
	function (fieldKey, tpl) {
		return _Utils_update(
			tpl,
			{
				L: A2(
					elm$core$List$filter,
					function (_n0) {
						var k = _n0.a;
						return !_Utils_eq(k, fieldKey);
					},
					tpl.L)
			});
	});
var author$project$Endpoint$is401 = function (webdata) {
	if ((webdata.$ === 2) && (webdata.a.$ === 3)) {
		var status = webdata.a.a;
		return status === 401;
	} else {
		return false;
	}
};
var krisajenkins$remotedata$RemoteData$Loading = {$: 1};
var rtfeldman$elm_validate$Validate$Valid = elm$core$Basics$identity;
var rtfeldman$elm_validate$Validate$validate = F2(
	function (_n0, subject) {
		var getErrors = _n0;
		var _n1 = getErrors(subject);
		if (!_n1.b) {
			return elm$core$Result$Ok(subject);
		} else {
			var errors = _n1;
			return elm$core$Result$Err(errors);
		}
	});
var author$project$CreatePromotion$update = F3(
	function (msg, sharedState, model) {
		switch (msg.$) {
			case 2:
				var newName = msg.a;
				return _Utils_Tuple2(
					A2(
						author$project$CreatePromotion$resetErrorsFor,
						0,
						_Utils_update(
							model,
							{Q: newName})),
					author$project$CreatePromotion$noCmd);
			case 1:
				var newDesc = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ap: newDesc}),
					author$project$CreatePromotion$noCmd);
			case 0:
				var base = msg.a;
				return _Utils_Tuple2(
					A2(
						author$project$CreatePromotion$resetErrorsFor,
						1,
						_Utils_update(
							model,
							{v: base})),
					author$project$CreatePromotion$noCmd);
			case 3:
				var _n1 = A2(
					rtfeldman$elm_validate$Validate$validate,
					author$project$CreatePromotion$promotionRequestValidator(sharedState),
					model);
				if (_n1.$ === 1) {
					var errors = _n1.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{L: errors}),
						author$project$CreatePromotion$noCmd);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ai: krisajenkins$remotedata$RemoteData$Loading}),
						author$project$CreatePromotion$WithCommand(
							A2(author$project$CreatePromotion$postPromotionRequest, sharedState, model)));
				}
			default:
				var webdata = msg.a;
				if (webdata.$ === 3) {
					var template = webdata.a;
					return _Utils_Tuple2(
						author$project$CreatePromotion$init(model.aB),
						author$project$CreatePromotion$PromotionCreated(template));
				} else {
					return author$project$Endpoint$is401(webdata) ? _Utils_Tuple2(
						author$project$CreatePromotion$init(model.aB),
						author$project$CreatePromotion$Unauthorized) : _Utils_Tuple2(
						_Utils_update(
							model,
							{ai: webdata}),
						author$project$CreatePromotion$noCmd);
				}
		}
	});
var author$project$Main$CreateLoaded = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var author$project$Main$CreateMsg = function (a) {
	return {$: 4, a: a};
};
var author$project$Main$LicenseAccountUnassigned = function (a) {
	return {$: 6, a: a};
};
var author$project$Main$LoadingCreate = function (a) {
	return {$: 1, a: a};
};
var author$project$Main$LoadingFailed = function (a) {
	return {$: 5, a: a};
};
var author$project$Main$LoadingWizard = function (a) {
	return {$: 3, a: a};
};
var author$project$Main$WizardLoaded = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var author$project$Endpoint$get = F3(
	function (endpoint, toMsg, decoder) {
		return elm$http$Http$riskyRequest(
			{
				cb: elm$http$Http$emptyBody,
				cY: A2(
					elm$http$Http$expectJson,
					A2(elm$core$Basics$composeR, krisajenkins$remotedata$RemoteData$fromResult, toMsg),
					decoder),
				ci: _List_Nil,
				co: 'GET',
				cG: elm$core$Maybe$Nothing,
				dK: elm$core$Maybe$Nothing,
				cI: author$project$Endpoint$toUrl(endpoint)
			});
	});
var author$project$PromotionId$toString = function (_n0) {
	var id = _n0;
	return elm$core$String$fromInt(id);
};
var author$project$Endpoint$promotion = F2(
	function (_n0, id) {
		var apiBaseUrl = _n0.dP;
		return apiBaseUrl + ('/rest/promotion/' + author$project$PromotionId$toString(id));
	});
var author$project$Main$PromotionRetrieved = function (a) {
	return {$: 3, a: a};
};
var author$project$Main$getPromotion = F2(
	function (ctx, id) {
		return A3(
			author$project$Endpoint$get,
			A2(author$project$Endpoint$promotion, ctx, id),
			author$project$Main$PromotionRetrieved,
			author$project$Promotion$promotionDecoder);
	});
var author$project$Main$PromotionsRetrieved = function (a) {
	return {$: 2, a: a};
};
var author$project$Promotion$promotionsDecoder = A2(
	elm$json$Json$Decode$field,
	'promotionTemplates',
	elm$json$Json$Decode$list(author$project$Promotion$promotionDecoder));
var author$project$Main$getPromotions = function (ctx) {
	return A3(
		author$project$Endpoint$get,
		author$project$Endpoint$promotions(ctx),
		author$project$Main$PromotionsRetrieved,
		author$project$Promotion$promotionsDecoder);
};
var author$project$Route$getCurrentPromotionId = function (page) {
	switch (page.$) {
		case 1:
			var id = page.a;
			return elm$core$Maybe$Just(id);
		case 2:
			var id = page.a;
			return elm$core$Maybe$Just(id);
		case 3:
			var id = page.a;
			return elm$core$Maybe$Just(id);
		default:
			return elm$core$Maybe$Nothing;
	}
};
var author$project$Main$handleUrlChange = F2(
	function (urlStr, model) {
		var pageRequested = A2(
			elm$core$Maybe$withDefault,
			author$project$Route$CreatePage,
			A2(
				elm$core$Maybe$andThen,
				author$project$Route$toPage,
				elm$url$Url$fromString(urlStr)));
		var newState = function (existing) {
			return _Utils_update(
				existing,
				{q: pageRequested});
		};
		var _n0 = model.d;
		switch (_n0.$) {
			case 4:
				var sharedState = _n0.a;
				var wizardModel = _n0.b;
				var _n1 = _Utils_Tuple2(
					author$project$Route$getCurrentPromotionId(pageRequested),
					author$project$Route$getCurrentPromotionId(sharedState.q));
				if (!_n1.a.$) {
					if (!_n1.b.$) {
						var reqId = _n1.a.a;
						var currId = _n1.b.a;
						return _Utils_eq(reqId, currId) ? _Utils_Tuple2(
							_Utils_update(
								model,
								{
									d: A2(
										author$project$Main$WizardLoaded,
										newState(sharedState),
										wizardModel)
								}),
							elm$core$Platform$Cmd$none) : _Utils_Tuple2(
							_Utils_update(
								model,
								{
									d: author$project$Main$LoadingWizard(
										newState(sharedState))
								}),
							A2(
								author$project$Main$getPromotion,
								newState(sharedState),
								reqId));
					} else {
						var reqId = _n1.a.a;
						var _n2 = _n1.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									d: author$project$Main$LoadingWizard(
										newState(sharedState))
								}),
							A2(
								author$project$Main$getPromotion,
								newState(sharedState),
								reqId));
					}
				} else {
					var _n3 = _n1.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								d: author$project$Main$LoadingCreate(
									newState(sharedState))
							}),
						author$project$Main$getPromotions(
							newState(sharedState)));
				}
			case 2:
				var sharedState = _n0.a;
				var createModel = _n0.b;
				var _n4 = author$project$Route$getCurrentPromotionId(pageRequested);
				if (!_n4.$) {
					var reqId = _n4.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								d: author$project$Main$LoadingWizard(
									newState(sharedState))
							}),
						A2(
							author$project$Main$getPromotion,
							newState(sharedState),
							reqId));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			default:
				return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
		}
	});
var author$project$Main$performAuthRedirect = _Platform_outgoingPort('performAuthRedirect', elm$json$Json$Encode$string);
var author$project$Screen$screenTypeToString = function (screenType) {
	switch (screenType) {
		case 0:
			return 'SMALL_COLOUR';
		case 1:
			return 'COLOUR_TOUCH';
		case 2:
			return 'EXTRA_LARGE_TOUCH';
		case 3:
			return 'DOUBLE_TOUCH';
		default:
			return 'UNO';
	}
};
var author$project$Route$toHash = function (page) {
	switch (page.$) {
		case 0:
			return '';
		case 1:
			var id = page.a;
			return '/wizard/' + (author$project$PromotionId$toString(id) + '/screentype');
		case 2:
			var id = page.a;
			var screenType = page.b;
			return '/wizard/' + (author$project$PromotionId$toString(id) + ('/' + (author$project$Screen$screenTypeToString(screenType) + '/idle')));
		default:
			var id = page.a;
			var screenType = page.b;
			return '/wizard/' + (author$project$PromotionId$toString(id) + ('/' + (author$project$Screen$screenTypeToString(screenType) + '/wait')));
	}
};
var author$project$Main$maybeAuthRedirect = F2(
	function (page, webdata) {
		return author$project$Endpoint$is401(webdata) ? author$project$Main$performAuthRedirect(
			author$project$Route$toHash(page)) : elm$core$Platform$Cmd$none;
	});
var author$project$Main$setUrlHash = _Platform_outgoingPort('setUrlHash', elm$json$Json$Encode$string);
var elm$core$Basics$not = _Basics_not;
var author$project$Main$toggleTranslationTooltips = function (appState) {
	var toggle = function (state) {
		return _Utils_update(
			state,
			{dz: !state.dz});
	};
	switch (appState.$) {
		case 0:
			return appState;
		case 1:
			var sharedState = appState.a;
			return author$project$Main$LoadingCreate(
				toggle(sharedState));
		case 2:
			var sharedState = appState.a;
			var createModel = appState.b;
			return A2(
				author$project$Main$CreateLoaded,
				toggle(sharedState),
				createModel);
		case 3:
			var sharedState = appState.a;
			return author$project$Main$LoadingWizard(
				toggle(sharedState));
		case 4:
			var sharedState = appState.a;
			var wizardModel = appState.b;
			return A2(
				author$project$Main$WizardLoaded,
				toggle(sharedState),
				wizardModel);
		case 5:
			return appState;
		default:
			var sharedState = appState.a;
			return author$project$Main$LicenseAccountUnassigned(
				toggle(sharedState));
	}
};
var author$project$Machine$MachineType = F3(
	function (name, imageFilename, screenType) {
		return {d3: imageFilename, Q: name, dx: screenType};
	});
var author$project$Machine$machines = _List_fromArray(
	[
		A3(
		author$project$Machine$MachineType,
		'T-53',
		elm$core$Maybe$Just('machine_t53.jpg'),
		0),
		A3(
		author$project$Machine$MachineType,
		'T-63',
		elm$core$Maybe$Just('machine_t63.jpg'),
		0),
		A3(
		author$project$Machine$MachineType,
		'T-83',
		elm$core$Maybe$Just('machine_t83.jpg'),
		0),
		A3(
		author$project$Machine$MachineType,
		'T-705',
		elm$core$Maybe$Just('machine_t705.jpg'),
		0),
		A3(
		author$project$Machine$MachineType,
		'T-820',
		elm$core$Maybe$Just('machine_t820_mk3.jpg'),
		0),
		A3(
		author$project$Machine$MachineType,
		'T-8',
		elm$core$Maybe$Just('machine_t8.jpg'),
		1),
		A3(
		author$project$Machine$MachineType,
		'T-9',
		elm$core$Maybe$Just('machine_t9.jpg'),
		1),
		A3(
		author$project$Machine$MachineType,
		'T-90',
		elm$core$Maybe$Just('machine_t90.jpg'),
		1),
		A3(
		author$project$Machine$MachineType,
		'T-70',
		elm$core$Maybe$Just('machine_t70.jpg'),
		1),
		A3(
		author$project$Machine$MachineType,
		'T-820 Touch',
		elm$core$Maybe$Just('machine_t820.jpg'),
		1),
		A3(author$project$Machine$MachineType, 'H-30 small screen', elm$core$Maybe$Nothing, 1),
		A3(
		author$project$Machine$MachineType,
		'Uno Promo',
		elm$core$Maybe$Just('machine_uno_promo.jpg'),
		4),
		A3(
		author$project$Machine$MachineType,
		'H-10',
		elm$core$Maybe$Just('machine_h10.jpg'),
		3),
		A3(
		author$project$Machine$MachineType,
		'H-11',
		elm$core$Maybe$Just('machine_h10.jpg'),
		3),
		A3(
		author$project$Machine$MachineType,
		'H-30',
		elm$core$Maybe$Just('machine_h30.jpg'),
		3),
		A3(author$project$Machine$MachineType, 'R1', elm$core$Maybe$Nothing, 2)
	]);
var author$project$PromotionWizard$Hidden = {$: 0};
var pzp1997$assoc_list$AssocList$keys = function (_n0) {
	var alist = _n0;
	return A2(elm$core$List$map, elm$core$Tuple$first, alist);
};
var rundis$elm_bootstrap$Bootstrap$Popover$State = elm$core$Basics$identity;
var rundis$elm_bootstrap$Bootstrap$Popover$initialState = {
	ce: {
		cp: 0,
		bs: 0,
		cx: {d$: 0, N: 0, aH: 0, ak: 0}
	},
	ab: false
};
var author$project$PromotionWizard$init = function (promotion) {
	return {
		n: promotion,
		O: elm$core$Dict$fromList(
			A2(
				elm$core$List$map,
				function (m) {
					return _Utils_Tuple2(m.Q, rundis$elm_bootstrap$Bootstrap$Popover$initialState);
				},
				author$project$Machine$machines)),
		S: krisajenkins$remotedata$RemoteData$NotAsked,
		x: pzp1997$assoc_list$AssocList$fromList(
			A2(
				elm$core$List$map,
				function (k) {
					return _Utils_Tuple2(k, author$project$PromotionWizard$Hidden);
				},
				pzp1997$assoc_list$AssocList$keys(promotion.em))),
		H: krisajenkins$remotedata$RemoteData$NotAsked
	};
};
var author$project$PromotionWizard$PageChange = function (a) {
	return {$: 1, a: a};
};
var author$project$PromotionWizard$ScreenFileSelected = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var author$project$PromotionWizard$ScreenReset = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var author$project$PromotionWizard$WithCommand = function (a) {
	return {$: 0, a: a};
};
var author$project$PromotionWizard$cmdNone = author$project$PromotionWizard$WithCommand(elm$core$Platform$Cmd$none);
var author$project$PromotionWizard$Unauthorized = {$: 2};
var pzp1997$assoc_list$AssocList$get = F2(
	function (targetKey, _n0) {
		get:
		while (true) {
			var alist = _n0;
			if (!alist.b) {
				return elm$core$Maybe$Nothing;
			} else {
				var _n2 = alist.a;
				var key = _n2.a;
				var value = _n2.b;
				var rest = alist.b;
				if (_Utils_eq(key, targetKey)) {
					return elm$core$Maybe$Just(value);
				} else {
					var $temp$targetKey = targetKey,
						$temp$_n0 = rest;
					targetKey = $temp$targetKey;
					_n0 = $temp$_n0;
					continue get;
				}
			}
		}
	});
var pzp1997$assoc_list$AssocList$update = F3(
	function (targetKey, alter, dict) {
		var alist = dict;
		var maybeValue = A2(pzp1997$assoc_list$AssocList$get, targetKey, dict);
		if (!maybeValue.$) {
			var _n1 = alter(maybeValue);
			if (!_n1.$) {
				var alteredValue = _n1.a;
				return A2(
					elm$core$List$map,
					function (entry) {
						var key = entry.a;
						return _Utils_eq(key, targetKey) ? _Utils_Tuple2(targetKey, alteredValue) : entry;
					},
					alist);
			} else {
				return A2(pzp1997$assoc_list$AssocList$remove, targetKey, dict);
			}
		} else {
			var _n2 = alter(elm$core$Maybe$Nothing);
			if (!_n2.$) {
				var alteredValue = _n2.a;
				return A2(
					elm$core$List$cons,
					_Utils_Tuple2(targetKey, alteredValue),
					alist);
			} else {
				return dict;
			}
		}
	});
var author$project$PromotionWizard$mapPromotionResource = F3(
	function (mapFn, _n0, promotion) {
		var screenType = _n0.dx;
		var screenResource = _n0.bU;
		var maybeUpdate = function (resource) {
			return _Utils_eq(resource.Q, screenResource) ? mapFn(resource) : resource;
		};
		return _Utils_update(
			promotion,
			{
				em: A3(
					pzp1997$assoc_list$AssocList$update,
					screenType,
					function (maybeRes) {
						return A2(
							elm$core$Maybe$map,
							function (resources) {
								return A2(elm$core$List$map, maybeUpdate, resources);
							},
							maybeRes);
					},
					promotion.em)
			});
	});
var author$project$PromotionWizard$handleFileUploaded = F4(
	function (model, uploadKey, uploadedFile, res) {
		if (res.$ === 3) {
			var newResourceId = res.a;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						n: A3(
							author$project$PromotionWizard$mapPromotionResource,
							function (resource) {
								return _Utils_update(
									resource,
									{
										c2: newResourceId,
										eA: true,
										eB: elm$core$Maybe$Just(uploadedFile)
									});
							},
							uploadKey,
							model.n),
						H: res
					}),
				author$project$PromotionWizard$cmdNone);
		} else {
			return author$project$Endpoint$is401(res) ? _Utils_Tuple2(model, author$project$PromotionWizard$Unauthorized) : _Utils_Tuple2(
				_Utils_update(
					model,
					{H: res}),
				author$project$PromotionWizard$cmdNone);
		}
	});
var author$project$PromotionWizard$DownAsked = function (a) {
	return {$: 1, a: a};
};
var author$project$PromotionWizard$Shown = {$: 5};
var author$project$PromotionWizard$UpAsked = function (a) {
	return {$: 3, a: a};
};
var pzp1997$assoc_list$AssocList$map = F2(
	function (alter, _n0) {
		var alist = _n0;
		return A2(
			elm$core$List$map,
			function (_n1) {
				var key = _n1.a;
				var value = _n1.b;
				return _Utils_Tuple2(
					key,
					A2(alter, key, value));
			},
			alist);
	});
var author$project$PromotionWizard$handlePreviewHeightRetrieved = F3(
	function (model, screenType, height) {
		var newPreviews = A2(
			pzp1997$assoc_list$AssocList$map,
			F2(
				function (k, v) {
					return (_Utils_eq(k, screenType) && _Utils_eq(v, author$project$PromotionWizard$Hidden)) ? author$project$PromotionWizard$DownAsked(height) : ((_Utils_eq(k, screenType) && _Utils_eq(v, author$project$PromotionWizard$Shown)) ? author$project$PromotionWizard$UpAsked(height) : v);
				}),
			model.x);
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{x: newPreviews}),
			author$project$PromotionWizard$cmdNone);
	});
var author$project$PromotionWizard$NoOp = {$: 0};
var author$project$PromotionWizard$PreviewHeightRetrieved = F2(
	function (a, b) {
		return {$: 12, a: a, b: b};
	});
var author$project$PromotionWizard$SlideDown = function (a) {
	return {$: 2, a: a};
};
var author$project$PromotionWizard$SlideUp = {$: 4};
var author$project$PromotionWizard$previewId = function (screenType) {
	return 'preview_' + author$project$Screen$screenTypeToString(screenType);
};
var elm$browser$Browser$Dom$getElement = _Browser_getElement;
var author$project$PromotionWizard$handlePreviewToggled = F2(
	function (model, screenType) {
		var sizeTask = elm$browser$Browser$Dom$getElement(
			author$project$PromotionWizard$previewId(screenType));
		var sizeCmd = A2(
			elm$core$Task$attempt,
			function (res) {
				if (!res.$) {
					var dim = res.a;
					return A2(author$project$PromotionWizard$PreviewHeightRetrieved, screenType, dim.dX.d$);
				} else {
					return author$project$PromotionWizard$NoOp;
				}
			},
			sizeTask);
		var newPreviews = A3(
			pzp1997$assoc_list$AssocList$update,
			screenType,
			function (maybePreview) {
				if (!maybePreview.$) {
					switch (maybePreview.a.$) {
						case 0:
							var _n1 = maybePreview.a;
							return elm$core$Maybe$Just(author$project$PromotionWizard$Hidden);
						case 1:
							var size = maybePreview.a.a;
							return elm$core$Maybe$Just(
								author$project$PromotionWizard$SlideDown(size));
						case 2:
							return elm$core$Maybe$Just(author$project$PromotionWizard$Shown);
						case 5:
							var _n2 = maybePreview.a;
							return elm$core$Maybe$Just(author$project$PromotionWizard$Shown);
						case 3:
							return elm$core$Maybe$Just(author$project$PromotionWizard$SlideUp);
						default:
							var _n3 = maybePreview.a;
							return elm$core$Maybe$Just(author$project$PromotionWizard$Hidden);
					}
				} else {
					return elm$core$Maybe$Nothing;
				}
			},
			model.x);
		var cmd = A2(
			elm$core$Maybe$withDefault,
			elm$core$Platform$Cmd$none,
			A2(
				elm$core$Maybe$map,
				function (p) {
					return (_Utils_eq(p, author$project$PromotionWizard$Hidden) || _Utils_eq(p, author$project$PromotionWizard$Shown)) ? sizeCmd : elm$core$Platform$Cmd$none;
				},
				A2(pzp1997$assoc_list$AssocList$get, screenType, model.x)));
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{x: newPreviews}),
			author$project$PromotionWizard$WithCommand(cmd));
	});
var author$project$PromotionWizard$handleScreenReset = F3(
	function (model, uploadKey, res) {
		if (res.$ === 3) {
			var optionalNewResourceId = res.a;
			var newResourceId = A2(elm$core$Maybe$withDefault, 0, optionalNewResourceId);
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						n: A3(
							author$project$PromotionWizard$mapPromotionResource,
							function (resource) {
								return _Utils_update(
									resource,
									{c2: newResourceId, eA: false, eB: elm$core$Maybe$Nothing});
							},
							uploadKey,
							model.n),
						S: res
					}),
				author$project$PromotionWizard$cmdNone);
		} else {
			return author$project$Endpoint$is401(res) ? _Utils_Tuple2(model, author$project$PromotionWizard$Unauthorized) : _Utils_Tuple2(
				_Utils_update(
					model,
					{S: res}),
				author$project$PromotionWizard$cmdNone);
		}
	});
var author$project$Screen$screenResourceToString = function (screenResource) {
	switch (screenResource) {
		case 0:
			return 'PROMOTION_IDLE';
		case 1:
			return 'PROMOTION_IDLE_MK3';
		case 2:
			return 'PROMOTION_IDLE_UNO';
		case 3:
			return 'PROMOTION_IDLE_TOP';
		case 4:
			return 'PROMOTION_IDLE_BOTTOM';
		case 5:
			return 'PROMOTION_WAIT';
		case 6:
			return 'PROMOTION_WAIT_MK3';
		case 7:
			return 'PROMOTION_WAIT_UNO';
		case 8:
			return 'PROMOTION_WAIT_BOTTOM';
		case 9:
			return 'PROMOTION_IN_USE_BORDER';
		case 10:
			return 'PROMOTION_DOUBLE_SCREEN_BORDER';
		case 11:
			return 'PROMOTION_IDLE_MULTIFEED';
		case 13:
			return 'PROMOTION_ACCOUNT_MULTIFEED';
		default:
			return 'PROMOTION_WAIT_MULTIFEED';
	}
};
var author$project$Endpoint$promotionResourceReset = F3(
	function (_n0, id, resource) {
		var apiBaseUrl = _n0.dP;
		return apiBaseUrl + ('/rest/promotion/' + (author$project$PromotionId$toString(id) + ('/' + (author$project$Screen$screenResourceToString(resource) + '/reset'))));
	});
var author$project$PromotionWizard$resetScreenResource = F3(
	function (sharedState, _n0, responseMsg) {
		var promotionId = _n0.cw;
		var screenResource = _n0.bU;
		return A4(
			author$project$Endpoint$post,
			A3(author$project$Endpoint$promotionResourceReset, sharedState, promotionId, screenResource),
			responseMsg,
			elm$http$Http$emptyBody,
			A2(
				elm$json$Json$Decode$field,
				'uploadId',
				elm$json$Json$Decode$nullable(elm$json$Json$Decode$int)));
	});
var author$project$Endpoint$promotionResource = F3(
	function (_n0, id, resource) {
		var apiBaseUrl = _n0.dP;
		return apiBaseUrl + ('/rest/promotion/' + (author$project$PromotionId$toString(id) + ('/' + author$project$Screen$screenResourceToString(resource))));
	});
var author$project$PromotionWizard$FileUploaded = F3(
	function (a, b, c) {
		return {$: 8, a: a, b: b, c: c};
	});
var elm$http$Http$filePart = _Http_pair;
var elm$http$Http$multipartBody = function (parts) {
	return A2(
		_Http_pair,
		'',
		_Http_toFormData(parts));
};
var author$project$PromotionWizard$uploadFile = F3(
	function (sharedState, uploadKey, file) {
		var promotionId = uploadKey.cw;
		var screenResource = uploadKey.bU;
		return A4(
			author$project$Endpoint$post,
			A3(author$project$Endpoint$promotionResource, sharedState, promotionId, screenResource),
			A2(author$project$PromotionWizard$FileUploaded, uploadKey, file),
			elm$http$Http$multipartBody(
				_List_fromArray(
					[
						A2(elm$http$Http$filePart, 'promotionFile', file)
					])),
			A2(elm$json$Json$Decode$field, 'uploadId', elm$json$Json$Decode$int));
	});
var author$project$Screen$getMimeTypesFor = function (screenType) {
	var videoTypes = _List_fromArray(
		['video/mp4', 'video/mpg']);
	var imageTypes = _List_fromArray(
		['image/png', 'image/jpeg', 'image/gif', 'image/bmp']);
	return (!screenType) ? imageTypes : _Utils_ap(imageTypes, videoTypes);
};
var elm$core$Basics$always = F2(
	function (a, _n0) {
		return a;
	});
var elm$file$File$size = _File_size;
var elm$file$File$Select$file = F2(
	function (mimes, toMsg) {
		return A2(
			elm$core$Task$perform,
			toMsg,
			_File_uploadOne(mimes));
	});
var author$project$PromotionWizard$update = F3(
	function (msg, sharedState, model) {
		var currentPromotion = model.n;
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(model, author$project$PromotionWizard$cmdNone);
			case 3:
				var screenType = msg.a;
				return _Utils_Tuple2(
					model,
					author$project$PromotionWizard$PageChange(
						A2(author$project$Route$IdleScreen, currentPromotion.c2, screenType)));
			case 4:
				var screenType = msg.a;
				return _Utils_Tuple2(
					model,
					author$project$PromotionWizard$PageChange(
						A2(author$project$Route$WaitScreen, currentPromotion.c2, screenType)));
			case 5:
				return _Utils_Tuple2(
					model,
					author$project$PromotionWizard$PageChange(
						author$project$Route$ScreenTypePage(currentPromotion.c2)));
			case 12:
				var screenType = msg.a;
				var height = msg.b;
				return A3(author$project$PromotionWizard$handlePreviewHeightRetrieved, model, screenType, height);
			case 11:
				var val = msg.a;
				return A2(author$project$PromotionWizard$handlePreviewToggled, model, val);
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{H: krisajenkins$remotedata$RemoteData$NotAsked}),
					author$project$PromotionWizard$cmdNone);
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{S: krisajenkins$remotedata$RemoteData$NotAsked}),
					author$project$PromotionWizard$cmdNone);
			case 6:
				var uploadKey = msg.a;
				return _Utils_Tuple2(
					model,
					author$project$PromotionWizard$WithCommand(
						A2(
							elm$file$File$Select$file,
							author$project$Screen$getMimeTypesFor(uploadKey.dx),
							author$project$PromotionWizard$ScreenFileSelected(uploadKey))));
			case 7:
				var uploadKey = msg.a;
				var file = msg.b;
				return (_Utils_cmp(
					elm$file$File$size(file),
					(1024 * 1024) * 20) > 0) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{
							H: krisajenkins$remotedata$RemoteData$Failure(
								elm$http$Http$BadBody('Max file size is 20mb'))
						}),
					author$project$PromotionWizard$cmdNone) : _Utils_Tuple2(
					_Utils_update(
						model,
						{H: krisajenkins$remotedata$RemoteData$Loading}),
					author$project$PromotionWizard$WithCommand(
						A3(author$project$PromotionWizard$uploadFile, sharedState, uploadKey, file)));
			case 8:
				var uploadKey = msg.a;
				var uploadedFilename = msg.b;
				var res = msg.c;
				return A4(author$project$PromotionWizard$handleFileUploaded, model, uploadKey, uploadedFilename, res);
			case 9:
				var uploadKey = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{S: krisajenkins$remotedata$RemoteData$Loading}),
					author$project$PromotionWizard$WithCommand(
						A3(
							author$project$PromotionWizard$resetScreenResource,
							sharedState,
							uploadKey,
							author$project$PromotionWizard$ScreenReset(uploadKey))));
			case 10:
				var uploadKey = msg.a;
				var res = msg.b;
				return A3(author$project$PromotionWizard$handleScreenReset, model, uploadKey, res);
			case 13:
				var machineName = msg.a;
				var popState = msg.b;
				var machinePopoversUpd = A3(
					elm$core$Dict$update,
					machineName,
					function (maybeState) {
						return A2(
							elm$core$Maybe$map,
							elm$core$Basics$always(popState),
							maybeState);
					},
					model.O);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{O: machinePopoversUpd}),
					author$project$PromotionWizard$cmdNone);
			default:
				return _Utils_Tuple2(
					model,
					author$project$PromotionWizard$PageChange(author$project$Route$CreatePage));
		}
	});
var elm$core$Platform$Cmd$map = _Platform_map;
var author$project$Main$update = F2(
	function (msg, model) {
		var _n0 = _Utils_Tuple2(msg, model.d);
		_n0$7:
		while (true) {
			switch (_n0.a.$) {
				case 6:
					var _n1 = _n0.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								d: author$project$Main$toggleTranslationTooltips(model.d)
							}),
						elm$core$Platform$Cmd$none);
				case 0:
					if (!_n0.b.$) {
						var res = _n0.a.a;
						var _n2 = _n0.b;
						var rootUrl = _n2.a;
						var apiBaseUrl = _n2.b;
						var version = _n2.c;
						var page = _n2.d;
						if (!res.$) {
							var userData = res.a;
							var sharedState = {dP: apiBaseUrl, q: page, ej: userData.ei, ep: rootUrl, dz: false, ey: userData.ef, aK: userData.aK, eC: version};
							if (_Utils_eq(sharedState.aK.db, author$project$User$Unassigned)) {
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											d: author$project$Main$LicenseAccountUnassigned(sharedState)
										}),
									elm$core$Platform$Cmd$none);
							} else {
								if (!page.$) {
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{
												d: author$project$Main$LoadingCreate(sharedState)
											}),
										author$project$Main$getPromotions(sharedState));
								} else {
									return A2(
										elm$core$Maybe$withDefault,
										_Utils_Tuple2(
											_Utils_update(
												model,
												{
													d: author$project$Main$LoadingFailed('Failed to load template (missing id).')
												}),
											elm$core$Platform$Cmd$none),
										A2(
											elm$core$Maybe$map,
											function (id) {
												return _Utils_Tuple2(
													_Utils_update(
														model,
														{
															d: author$project$Main$LoadingWizard(sharedState)
														}),
													A2(author$project$Main$getPromotion, sharedState, id));
											},
											author$project$Route$getCurrentPromotionId(sharedState.q)));
								}
							}
						} else {
							var err = res.a;
							if ((err.$ === 3) && (err.a === 401)) {
								return _Utils_Tuple2(
									model,
									author$project$Main$performAuthRedirect(
										author$project$Route$toHash(page)));
							} else {
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											d: author$project$Main$LoadingFailed('Failed to load details about your user account.')
										}),
									elm$core$Platform$Cmd$none);
							}
						}
					} else {
						break _n0$7;
					}
				case 2:
					if (_n0.b.$ === 1) {
						var res = _n0.a.a;
						var sharedState = _n0.b.a;
						if (res.$ === 3) {
							var promotions = res.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										d: A2(
											author$project$Main$CreateLoaded,
											sharedState,
											author$project$CreatePromotion$init(promotions))
									}),
								elm$core$Platform$Cmd$none);
						} else {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										d: author$project$Main$LoadingFailed('Error loading promotion templates')
									}),
								A2(author$project$Main$maybeAuthRedirect, sharedState.q, res));
						}
					} else {
						break _n0$7;
					}
				case 3:
					if (_n0.b.$ === 3) {
						var res = _n0.a.a;
						var sharedState = _n0.b.a;
						if (res.$ === 3) {
							var promotion = res.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										d: A2(
											author$project$Main$WizardLoaded,
											sharedState,
											author$project$PromotionWizard$init(promotion))
									}),
								elm$core$Platform$Cmd$none);
						} else {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										d: author$project$Main$LoadingFailed('Error loading promotion template')
									}),
								A2(author$project$Main$maybeAuthRedirect, sharedState.q, res));
						}
					} else {
						break _n0$7;
					}
				case 4:
					if (_n0.b.$ === 2) {
						var subMsg = _n0.a.a;
						var _n8 = _n0.b;
						var sharedState = _n8.a;
						var createModel = _n8.b;
						var _n9 = A3(author$project$CreatePromotion$update, subMsg, sharedState, createModel);
						switch (_n9.b.$) {
							case 0:
								var subModel = _n9.a;
								var cmd = _n9.b.a;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											d: A2(author$project$Main$CreateLoaded, sharedState, subModel)
										}),
									A2(elm$core$Platform$Cmd$map, author$project$Main$CreateMsg, cmd));
							case 1:
								var promotion = _n9.b.a;
								var sharedState_ = _Utils_update(
									sharedState,
									{
										q: author$project$Route$ScreenTypePage(promotion.c2)
									});
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											d: A2(
												author$project$Main$WizardLoaded,
												sharedState_,
												author$project$PromotionWizard$init(promotion))
										}),
									author$project$Main$setUrlHash(
										author$project$Route$toHash(sharedState_.q)));
							default:
								var _n10 = _n9.b;
								return _Utils_Tuple2(
									model,
									author$project$Main$performAuthRedirect(
										author$project$Route$toHash(sharedState.q)));
						}
					} else {
						break _n0$7;
					}
				case 5:
					if (_n0.b.$ === 4) {
						var subMsg = _n0.a.a;
						var _n11 = _n0.b;
						var sharedState = _n11.a;
						var wizardModel = _n11.b;
						var _n12 = A3(author$project$PromotionWizard$update, subMsg, sharedState, wizardModel);
						switch (_n12.b.$) {
							case 0:
								var subModel = _n12.a;
								var cmd = _n12.b.a;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											d: A2(author$project$Main$WizardLoaded, sharedState, subModel)
										}),
									A2(elm$core$Platform$Cmd$map, author$project$Main$WizardMsg, cmd));
							case 1:
								var subModel = _n12.a;
								var page = _n12.b.a;
								var sharedState_ = _Utils_update(
									sharedState,
									{q: page});
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											d: A2(author$project$Main$WizardLoaded, sharedState_, subModel)
										}),
									author$project$Main$setUrlHash(
										author$project$Route$toHash(sharedState_.q)));
							default:
								var _n13 = _n12.b;
								return _Utils_Tuple2(
									model,
									author$project$Main$performAuthRedirect(
										author$project$Route$toHash(sharedState.q)));
						}
					} else {
						break _n0$7;
					}
				default:
					var urlStr = _n0.a.a;
					return A2(author$project$Main$handleUrlChange, urlStr, model);
			}
		}
		return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
	});
var author$project$Endpoint$errorToString = function (err) {
	switch (err.$) {
		case 4:
			var msg = err.a;
			return msg;
		case 3:
			var status = err.a;
			return 'Unexpected status code ' + elm$core$String$fromInt(status);
		case 2:
			return 'Networking error';
		case 0:
			var msg = err.a;
			return 'Bad url ' + msg;
		default:
			return 'Request timed out';
	}
};
var author$project$Language$ERROR_500 = 19;
var author$project$Language$ErrorDetails = function (a) {
	return {$: 2, a: a};
};
var elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var elm$html$Html$text = elm$virtual_dom$VirtualDom$text;
var rundis$elm_bootstrap$Bootstrap$Alert$Shown = 0;
var rundis$elm_bootstrap$Bootstrap$Alert$Config = elm$core$Basics$identity;
var rundis$elm_bootstrap$Bootstrap$Alert$attrs = F2(
	function (attributes, _n0) {
		var configRec = _n0;
		return _Utils_update(
			configRec,
			{a$: attributes});
	});
var rundis$elm_bootstrap$Bootstrap$Alert$children = F2(
	function (children_, _n0) {
		var configRec = _n0;
		return _Utils_update(
			configRec,
			{cQ: children_});
	});
var rundis$elm_bootstrap$Bootstrap$Internal$Role$Secondary = 1;
var rundis$elm_bootstrap$Bootstrap$Alert$config = {a$: _List_Nil, cQ: _List_Nil, J: elm$core$Maybe$Nothing, bR: 1, j: 0, U: false};
var rundis$elm_bootstrap$Bootstrap$Alert$role = F2(
	function (role_, _n0) {
		var configRec = _n0;
		return _Utils_update(
			configRec,
			{bR: role_});
	});
var elm$html$Html$div = _VirtualDom_node('div');
var elm$html$Html$button = _VirtualDom_node('button');
var elm$html$Html$span = _VirtualDom_node('span');
var elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var elm$html$Html$Attributes$attribute = elm$virtual_dom$VirtualDom$attribute;
var elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			elm$json$Json$Encode$string(string));
	});
var elm$html$Html$Attributes$class = elm$html$Html$Attributes$stringProperty('className');
var elm$html$Html$Attributes$type_ = elm$html$Html$Attributes$stringProperty('type');
var elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			elm$virtual_dom$VirtualDom$on,
			event,
			elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var elm$html$Html$Events$onClick = function (msg) {
	return A2(
		elm$html$Html$Events$on,
		'click',
		elm$json$Json$Decode$succeed(msg));
};
var rundis$elm_bootstrap$Bootstrap$Alert$Closed = 3;
var rundis$elm_bootstrap$Bootstrap$Alert$StartClose = 1;
var rundis$elm_bootstrap$Bootstrap$Alert$clickHandler = F2(
	function (visibility, configRec) {
		var handleClick = F2(
			function (viz, toMsg) {
				return elm$html$Html$Events$onClick(
					toMsg(viz));
			});
		var _n0 = configRec.J;
		if (!_n0.$) {
			var dismissMsg = _n0.a;
			return _List_fromArray(
				[
					configRec.U ? A2(handleClick, 1, dismissMsg) : A2(handleClick, 3, dismissMsg)
				]);
		} else {
			return _List_Nil;
		}
	});
var rundis$elm_bootstrap$Bootstrap$Alert$injectButton = F2(
	function (btn, children_) {
		if (children_.b) {
			var head = children_.a;
			var tail = children_.b;
			return A2(
				elm$core$List$cons,
				head,
				A2(elm$core$List$cons, btn, tail));
		} else {
			return _List_fromArray(
				[btn]);
		}
	});
var rundis$elm_bootstrap$Bootstrap$Alert$isDismissable = function (configRec) {
	var _n0 = configRec.J;
	if (!_n0.$) {
		return true;
	} else {
		return false;
	}
};
var rundis$elm_bootstrap$Bootstrap$Alert$maybeAddDismissButton = F3(
	function (visibilty, configRec, children_) {
		return rundis$elm_bootstrap$Bootstrap$Alert$isDismissable(configRec) ? A2(
			rundis$elm_bootstrap$Bootstrap$Alert$injectButton,
			A2(
				elm$html$Html$button,
				_Utils_ap(
					_List_fromArray(
						[
							elm$html$Html$Attributes$type_('button'),
							elm$html$Html$Attributes$class('close'),
							A2(elm$html$Html$Attributes$attribute, 'aria-label', 'close')
						]),
					A2(rundis$elm_bootstrap$Bootstrap$Alert$clickHandler, visibilty, configRec)),
				_List_fromArray(
					[
						A2(
						elm$html$Html$span,
						_List_fromArray(
							[
								A2(elm$html$Html$Attributes$attribute, 'aria-hidden', 'true')
							]),
						_List_fromArray(
							[
								elm$html$Html$text('×')
							]))
					])),
			children_) : children_;
	});
var elm$core$Tuple$second = function (_n0) {
	var y = _n0.b;
	return y;
};
var elm$html$Html$Attributes$classList = function (classes) {
	return elm$html$Html$Attributes$class(
		A2(
			elm$core$String$join,
			' ',
			A2(
				elm$core$List$map,
				elm$core$Tuple$first,
				A2(elm$core$List$filter, elm$core$Tuple$second, classes))));
};
var elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var elm$html$Html$Attributes$style = elm$virtual_dom$VirtualDom$style;
var rundis$elm_bootstrap$Bootstrap$Internal$Role$toClass = F2(
	function (prefix, role) {
		return elm$html$Html$Attributes$class(
			prefix + ('-' + function () {
				switch (role) {
					case 0:
						return 'primary';
					case 1:
						return 'secondary';
					case 2:
						return 'success';
					case 3:
						return 'info';
					case 4:
						return 'warning';
					case 5:
						return 'danger';
					case 6:
						return 'light';
					default:
						return 'dark';
				}
			}()));
	});
var rundis$elm_bootstrap$Bootstrap$Alert$viewAttributes = F2(
	function (visibility, configRec) {
		var visibiltyAttributes = (visibility === 3) ? _List_fromArray(
			[
				A2(elm$html$Html$Attributes$style, 'display', 'none')
			]) : _List_Nil;
		var animationAttributes = function () {
			if (configRec.U) {
				var _n0 = configRec.J;
				if (!_n0.$) {
					var dismissMsg = _n0.a;
					return _List_fromArray(
						[
							A2(
							elm$html$Html$Events$on,
							'transitionend',
							elm$json$Json$Decode$succeed(
								dismissMsg(3)))
						]);
				} else {
					return _List_Nil;
				}
			} else {
				return _List_Nil;
			}
		}();
		var alertAttributes = _List_fromArray(
			[
				A2(elm$html$Html$Attributes$attribute, 'role', 'alert'),
				elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('alert', true),
						_Utils_Tuple2(
						'alert-dismissible',
						rundis$elm_bootstrap$Bootstrap$Alert$isDismissable(configRec)),
						_Utils_Tuple2('fade', configRec.U),
						_Utils_Tuple2('show', !visibility)
					])),
				A2(rundis$elm_bootstrap$Bootstrap$Internal$Role$toClass, 'alert', configRec.bR)
			]);
		return elm$core$List$concat(
			_List_fromArray(
				[configRec.a$, alertAttributes, visibiltyAttributes, animationAttributes]));
	});
var rundis$elm_bootstrap$Bootstrap$Alert$view = F2(
	function (visibility, _n0) {
		var configRec = _n0;
		return A2(
			elm$html$Html$div,
			A2(rundis$elm_bootstrap$Bootstrap$Alert$viewAttributes, visibility, configRec),
			A3(rundis$elm_bootstrap$Bootstrap$Alert$maybeAddDismissButton, visibility, configRec, configRec.cQ));
	});
var rundis$elm_bootstrap$Bootstrap$Alert$simple = F3(
	function (role_, attributes, children_) {
		return A2(
			rundis$elm_bootstrap$Bootstrap$Alert$view,
			0,
			A2(
				rundis$elm_bootstrap$Bootstrap$Alert$children,
				children_,
				A2(
					rundis$elm_bootstrap$Bootstrap$Alert$attrs,
					attributes,
					A2(rundis$elm_bootstrap$Bootstrap$Alert$role, role_, rundis$elm_bootstrap$Bootstrap$Alert$config))));
	});
var rundis$elm_bootstrap$Bootstrap$Internal$Role$Danger = 5;
var rundis$elm_bootstrap$Bootstrap$Alert$simpleDanger = rundis$elm_bootstrap$Bootstrap$Alert$simple(5);
var author$project$CreatePromotion$viewErrors = F2(
	function (_n0, model) {
		var trSpan = _n0.dJ;
		var _n1 = model.ai;
		if (_n1.$ === 2) {
			var err = _n1.a;
			return _List_fromArray(
				[
					A2(
					rundis$elm_bootstrap$Bootstrap$Alert$simpleDanger,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							trSpan,
							19,
							_List_fromArray(
								[
									author$project$Language$ErrorDetails(
									author$project$Endpoint$errorToString(err))
								]))
						]))
				]);
		} else {
			return (elm$core$List$length(model.L) > 0) ? _List_fromArray(
				[
					A2(
					rundis$elm_bootstrap$Bootstrap$Alert$simpleDanger,
					_List_Nil,
					_List_fromArray(
						[
							elm$html$Html$text('There are validation errors')
						]))
				]) : _List_Nil;
		}
	});
var author$project$CreatePromotion$NewPromotionSubmitted = {$: 3};
var author$project$CreatePromotion$PromotionBaseChanged = function (a) {
	return {$: 0, a: a};
};
var author$project$CreatePromotion$PromotionDescriptionChanged = function (a) {
	return {$: 1, a: a};
};
var author$project$CreatePromotion$TemplateNameChanged = function (a) {
	return {$: 2, a: a};
};
var author$project$CreatePromotion$getFieldError = F2(
	function (fieldKey, tpl) {
		return A2(
			elm$core$Maybe$map,
			elm$core$Tuple$second,
			elm$core$List$head(
				A2(
					elm$core$List$filter,
					function (_n0) {
						var k = _n0.a;
						return _Utils_eq(k, fieldKey);
					},
					tpl.L)));
	});
var author$project$CreatePromotion$fieldErrorDefault = F3(
	function (whenError, field, model) {
		var _n0 = A2(author$project$CreatePromotion$getFieldError, field, model);
		if (!_n0.$) {
			return whenError;
		} else {
			return _List_Nil;
		}
	});
var rundis$elm_bootstrap$Bootstrap$Form$invalidFeedback = F2(
	function (attributes, children) {
		return A2(
			elm$html$Html$div,
			A2(
				elm$core$List$cons,
				elm$html$Html$Attributes$class('invalid-feedback'),
				attributes),
			children);
	});
var author$project$CreatePromotion$viewFieldError = F2(
	function (field, model) {
		var _n0 = A2(author$project$CreatePromotion$getFieldError, field, model);
		if (!_n0.$) {
			var err = _n0.a;
			return _List_fromArray(
				[
					A2(
					rundis$elm_bootstrap$Bootstrap$Form$invalidFeedback,
					_List_Nil,
					_List_fromArray(
						[
							elm$html$Html$text(err)
						]))
				]);
		} else {
			return _List_Nil;
		}
	});
var author$project$Language$BTN_BACK = 1;
var author$project$Language$BTN_NEXT = 0;
var elm$html$Html$i = _VirtualDom_node('i');
var elm$json$Json$Encode$bool = _Json_wrap;
var elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			elm$json$Json$Encode$bool(bool));
	});
var elm$html$Html$Attributes$disabled = elm$html$Html$Attributes$boolProperty('disabled');
var rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption = function (size) {
	switch (size) {
		case 0:
			return elm$core$Maybe$Nothing;
		case 1:
			return elm$core$Maybe$Just('sm');
		case 2:
			return elm$core$Maybe$Just('md');
		case 3:
			return elm$core$Maybe$Just('lg');
		default:
			return elm$core$Maybe$Just('xl');
	}
};
var rundis$elm_bootstrap$Bootstrap$Internal$Button$applyModifier = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var size = modifier.a;
				return _Utils_update(
					options,
					{
						cz: elm$core$Maybe$Just(size)
					});
			case 1:
				var coloring = modifier.a;
				return _Utils_update(
					options,
					{
						B: elm$core$Maybe$Just(coloring)
					});
			case 2:
				return _Utils_update(
					options,
					{a1: true});
			case 3:
				var val = modifier.a;
				return _Utils_update(
					options,
					{a9: val});
			default:
				var attrs = modifier.a;
				return _Utils_update(
					options,
					{
						a$: _Utils_ap(options.a$, attrs)
					});
		}
	});
var rundis$elm_bootstrap$Bootstrap$Internal$Button$defaultOptions = {a$: _List_Nil, a1: false, B: elm$core$Maybe$Nothing, a9: false, cz: elm$core$Maybe$Nothing};
var rundis$elm_bootstrap$Bootstrap$Internal$Button$roleClass = function (role) {
	switch (role) {
		case 0:
			return 'primary';
		case 1:
			return 'secondary';
		case 2:
			return 'success';
		case 3:
			return 'info';
		case 4:
			return 'warning';
		case 5:
			return 'danger';
		case 6:
			return 'dark';
		case 7:
			return 'light';
		default:
			return 'link';
	}
};
var rundis$elm_bootstrap$Bootstrap$Internal$Button$buttonAttributes = function (modifiers) {
	var options = A3(elm$core$List$foldl, rundis$elm_bootstrap$Bootstrap$Internal$Button$applyModifier, rundis$elm_bootstrap$Bootstrap$Internal$Button$defaultOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('btn', true),
						_Utils_Tuple2('btn-block', options.a1),
						_Utils_Tuple2('disabled', options.a9)
					])),
				elm$html$Html$Attributes$disabled(options.a9)
			]),
		_Utils_ap(
			function () {
				var _n0 = A2(elm$core$Maybe$andThen, rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption, options.cz);
				if (!_n0.$) {
					var s = _n0.a;
					return _List_fromArray(
						[
							elm$html$Html$Attributes$class('btn-' + s)
						]);
				} else {
					return _List_Nil;
				}
			}(),
			_Utils_ap(
				function () {
					var _n1 = options.B;
					if (!_n1.$) {
						if (!_n1.a.$) {
							var role = _n1.a.a;
							return _List_fromArray(
								[
									elm$html$Html$Attributes$class(
									'btn-' + rundis$elm_bootstrap$Bootstrap$Internal$Button$roleClass(role))
								]);
						} else {
							var role = _n1.a.a;
							return _List_fromArray(
								[
									elm$html$Html$Attributes$class(
									'btn-outline-' + rundis$elm_bootstrap$Bootstrap$Internal$Button$roleClass(role))
								]);
						}
					} else {
						return _List_Nil;
					}
				}(),
				options.a$)));
};
var rundis$elm_bootstrap$Bootstrap$Button$button = F2(
	function (options, children) {
		return A2(
			elm$html$Html$button,
			rundis$elm_bootstrap$Bootstrap$Internal$Button$buttonAttributes(options),
			children);
	});
var rundis$elm_bootstrap$Bootstrap$Internal$Button$Coloring = function (a) {
	return {$: 1, a: a};
};
var rundis$elm_bootstrap$Bootstrap$Internal$Button$Primary = 0;
var rundis$elm_bootstrap$Bootstrap$Internal$Button$Roled = function (a) {
	return {$: 0, a: a};
};
var rundis$elm_bootstrap$Bootstrap$Button$primary = rundis$elm_bootstrap$Bootstrap$Internal$Button$Coloring(
	rundis$elm_bootstrap$Bootstrap$Internal$Button$Roled(0));
var rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml2 = elm$html$Html$Attributes$class('ml-2');
var author$project$Widgets$nextBtn = F2(
	function (_n0, options) {
		var tSpan = _n0.cF;
		return A2(
			rundis$elm_bootstrap$Bootstrap$Button$button,
			A2(elm$core$List$cons, rundis$elm_bootstrap$Bootstrap$Button$primary, options),
			_List_fromArray(
				[
					tSpan(0),
					A2(
					elm$html$Html$i,
					_List_fromArray(
						[
							elm$html$Html$Attributes$class('fa fa-angle-right'),
							rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml2
						]),
					_List_Nil)
				]));
	});
var rundis$elm_bootstrap$Bootstrap$Grid$Column = function (a) {
	return {$: 0, a: a};
};
var rundis$elm_bootstrap$Bootstrap$Grid$col = F2(
	function (options, children) {
		return rundis$elm_bootstrap$Bootstrap$Grid$Column(
			{cQ: children, cr: options});
	});
var elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var elm$html$Html$Keyed$node = elm$virtual_dom$VirtualDom$keyedNode;
var rundis$elm_bootstrap$Bootstrap$General$Internal$XS = 0;
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$Col = 0;
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$Width = F2(
	function (screenSize, columnCount) {
		return {cS: columnCount, dw: screenSize};
	});
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColAlign = F2(
	function (align_, options) {
		var _n0 = align_.dw;
		switch (_n0) {
			case 0:
				return _Utils_update(
					options,
					{
						aY: elm$core$Maybe$Just(align_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						aW: elm$core$Maybe$Just(align_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						aV: elm$core$Maybe$Just(align_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						aU: elm$core$Maybe$Just(align_)
					});
			default:
				return _Utils_update(
					options,
					{
						aX: elm$core$Maybe$Just(align_)
					});
		}
	});
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColOffset = F2(
	function (offset_, options) {
		var _n0 = offset_.dw;
		switch (_n0) {
			case 0:
				return _Utils_update(
					options,
					{
						bu: elm$core$Maybe$Just(offset_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						br: elm$core$Maybe$Just(offset_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						bq: elm$core$Maybe$Just(offset_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						bp: elm$core$Maybe$Just(offset_)
					});
			default:
				return _Utils_update(
					options,
					{
						bt: elm$core$Maybe$Just(offset_)
					});
		}
	});
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColOrder = F2(
	function (order_, options) {
		var _n0 = order_.dw;
		switch (_n0) {
			case 0:
				return _Utils_update(
					options,
					{
						bE: elm$core$Maybe$Just(order_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						bC: elm$core$Maybe$Just(order_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						bB: elm$core$Maybe$Just(order_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						bA: elm$core$Maybe$Just(order_)
					});
			default:
				return _Utils_update(
					options,
					{
						bD: elm$core$Maybe$Just(order_)
					});
		}
	});
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColPull = F2(
	function (pull_, options) {
		var _n0 = pull_.dw;
		switch (_n0) {
			case 0:
				return _Utils_update(
					options,
					{
						bK: elm$core$Maybe$Just(pull_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						bI: elm$core$Maybe$Just(pull_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						bH: elm$core$Maybe$Just(pull_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						bG: elm$core$Maybe$Just(pull_)
					});
			default:
				return _Utils_update(
					options,
					{
						bJ: elm$core$Maybe$Just(pull_)
					});
		}
	});
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColPush = F2(
	function (push_, options) {
		var _n0 = push_.dw;
		switch (_n0) {
			case 0:
				return _Utils_update(
					options,
					{
						bP: elm$core$Maybe$Just(push_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						bN: elm$core$Maybe$Just(push_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						bM: elm$core$Maybe$Just(push_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						bL: elm$core$Maybe$Just(push_)
					});
			default:
				return _Utils_update(
					options,
					{
						bO: elm$core$Maybe$Just(push_)
					});
		}
	});
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColWidth = F2(
	function (width_, options) {
		var _n0 = width_.dw;
		switch (_n0) {
			case 0:
				return _Utils_update(
					options,
					{
						aP: elm$core$Maybe$Just(width_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						aN: elm$core$Maybe$Just(width_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						aM: elm$core$Maybe$Just(width_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						aL: elm$core$Maybe$Just(width_)
					});
			default:
				return _Utils_update(
					options,
					{
						aO: elm$core$Maybe$Just(width_)
					});
		}
	});
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColOption = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 6:
				var attrs = modifier.a;
				return _Utils_update(
					options,
					{
						a$: _Utils_ap(options.a$, attrs)
					});
			case 0:
				var width_ = modifier.a;
				return A2(rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColWidth, width_, options);
			case 1:
				var offset_ = modifier.a;
				return A2(rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColOffset, offset_, options);
			case 2:
				var pull_ = modifier.a;
				return A2(rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColPull, pull_, options);
			case 3:
				var push_ = modifier.a;
				return A2(rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColPush, push_, options);
			case 4:
				var order_ = modifier.a;
				return A2(rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColOrder, order_, options);
			case 5:
				var align = modifier.a;
				return A2(rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColAlign, align, options);
			default:
				var align = modifier.a;
				return _Utils_update(
					options,
					{
						bX: elm$core$Maybe$Just(align)
					});
		}
	});
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$columnCountOption = function (size) {
	switch (size) {
		case 0:
			return elm$core$Maybe$Nothing;
		case 1:
			return elm$core$Maybe$Just('1');
		case 2:
			return elm$core$Maybe$Just('2');
		case 3:
			return elm$core$Maybe$Just('3');
		case 4:
			return elm$core$Maybe$Just('4');
		case 5:
			return elm$core$Maybe$Just('5');
		case 6:
			return elm$core$Maybe$Just('6');
		case 7:
			return elm$core$Maybe$Just('7');
		case 8:
			return elm$core$Maybe$Just('8');
		case 9:
			return elm$core$Maybe$Just('9');
		case 10:
			return elm$core$Maybe$Just('10');
		case 11:
			return elm$core$Maybe$Just('11');
		case 12:
			return elm$core$Maybe$Just('12');
		default:
			return elm$core$Maybe$Just('auto');
	}
};
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$colWidthClass = function (_n0) {
	var screenSize = _n0.dw;
	var columnCount = _n0.cS;
	return elm$html$Html$Attributes$class(
		'col' + (A2(
			elm$core$Maybe$withDefault,
			'',
			A2(
				elm$core$Maybe$map,
				function (v) {
					return '-' + v;
				},
				rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(screenSize))) + A2(
			elm$core$Maybe$withDefault,
			'',
			A2(
				elm$core$Maybe$map,
				function (v) {
					return '-' + v;
				},
				rundis$elm_bootstrap$Bootstrap$Grid$Internal$columnCountOption(columnCount)))));
};
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$colWidthsToAttributes = function (widths) {
	var width_ = function (w) {
		return A2(elm$core$Maybe$map, rundis$elm_bootstrap$Bootstrap$Grid$Internal$colWidthClass, w);
	};
	return A2(
		elm$core$List$filterMap,
		elm$core$Basics$identity,
		A2(elm$core$List$map, width_, widths));
};
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$defaultColOptions = {aU: elm$core$Maybe$Nothing, aV: elm$core$Maybe$Nothing, aW: elm$core$Maybe$Nothing, aX: elm$core$Maybe$Nothing, aY: elm$core$Maybe$Nothing, a$: _List_Nil, bp: elm$core$Maybe$Nothing, bq: elm$core$Maybe$Nothing, br: elm$core$Maybe$Nothing, bt: elm$core$Maybe$Nothing, bu: elm$core$Maybe$Nothing, bA: elm$core$Maybe$Nothing, bB: elm$core$Maybe$Nothing, bC: elm$core$Maybe$Nothing, bD: elm$core$Maybe$Nothing, bE: elm$core$Maybe$Nothing, bG: elm$core$Maybe$Nothing, bH: elm$core$Maybe$Nothing, bI: elm$core$Maybe$Nothing, bJ: elm$core$Maybe$Nothing, bK: elm$core$Maybe$Nothing, bL: elm$core$Maybe$Nothing, bM: elm$core$Maybe$Nothing, bN: elm$core$Maybe$Nothing, bO: elm$core$Maybe$Nothing, bP: elm$core$Maybe$Nothing, bX: elm$core$Maybe$Nothing, aL: elm$core$Maybe$Nothing, aM: elm$core$Maybe$Nothing, aN: elm$core$Maybe$Nothing, aO: elm$core$Maybe$Nothing, aP: elm$core$Maybe$Nothing};
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$offsetCountOption = function (size) {
	switch (size) {
		case 0:
			return '0';
		case 1:
			return '1';
		case 2:
			return '2';
		case 3:
			return '3';
		case 4:
			return '4';
		case 5:
			return '5';
		case 6:
			return '6';
		case 7:
			return '7';
		case 8:
			return '8';
		case 9:
			return '9';
		case 10:
			return '10';
		default:
			return '11';
	}
};
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$screenSizeToPartialString = function (screenSize) {
	var _n0 = rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(screenSize);
	if (!_n0.$) {
		var s = _n0.a;
		return '-' + (s + '-');
	} else {
		return '-';
	}
};
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$offsetClass = function (_n0) {
	var screenSize = _n0.dw;
	var offsetCount = _n0.dh;
	return elm$html$Html$Attributes$class(
		'offset' + (rundis$elm_bootstrap$Bootstrap$Grid$Internal$screenSizeToPartialString(screenSize) + rundis$elm_bootstrap$Bootstrap$Grid$Internal$offsetCountOption(offsetCount)));
};
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$offsetsToAttributes = function (offsets) {
	var offset_ = function (m) {
		return A2(elm$core$Maybe$map, rundis$elm_bootstrap$Bootstrap$Grid$Internal$offsetClass, m);
	};
	return A2(
		elm$core$List$filterMap,
		elm$core$Basics$identity,
		A2(elm$core$List$map, offset_, offsets));
};
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$orderColOption = function (size) {
	switch (size) {
		case 0:
			return 'first';
		case 1:
			return '1';
		case 2:
			return '2';
		case 3:
			return '3';
		case 4:
			return '4';
		case 5:
			return '5';
		case 6:
			return '6';
		case 7:
			return '7';
		case 8:
			return '8';
		case 9:
			return '9';
		case 10:
			return '10';
		case 11:
			return '11';
		case 12:
			return '12';
		default:
			return 'last';
	}
};
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$orderToAttributes = function (orders) {
	var order_ = function (m) {
		if (!m.$) {
			var screenSize = m.a.dw;
			var moveCount = m.a.ae;
			return elm$core$Maybe$Just(
				elm$html$Html$Attributes$class(
					'order' + (rundis$elm_bootstrap$Bootstrap$Grid$Internal$screenSizeToPartialString(screenSize) + rundis$elm_bootstrap$Bootstrap$Grid$Internal$orderColOption(moveCount))));
		} else {
			return elm$core$Maybe$Nothing;
		}
	};
	return A2(
		elm$core$List$filterMap,
		elm$core$Basics$identity,
		A2(elm$core$List$map, order_, orders));
};
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$moveCountOption = function (size) {
	switch (size) {
		case 0:
			return '0';
		case 1:
			return '1';
		case 2:
			return '2';
		case 3:
			return '3';
		case 4:
			return '4';
		case 5:
			return '5';
		case 6:
			return '6';
		case 7:
			return '7';
		case 8:
			return '8';
		case 9:
			return '9';
		case 10:
			return '10';
		case 11:
			return '11';
		default:
			return '12';
	}
};
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$pullsToAttributes = function (pulls) {
	var pull_ = function (m) {
		if (!m.$) {
			var screenSize = m.a.dw;
			var moveCount = m.a.ae;
			return elm$core$Maybe$Just(
				elm$html$Html$Attributes$class(
					'pull' + (rundis$elm_bootstrap$Bootstrap$Grid$Internal$screenSizeToPartialString(screenSize) + rundis$elm_bootstrap$Bootstrap$Grid$Internal$moveCountOption(moveCount))));
		} else {
			return elm$core$Maybe$Nothing;
		}
	};
	return A2(
		elm$core$List$filterMap,
		elm$core$Basics$identity,
		A2(elm$core$List$map, pull_, pulls));
};
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$pushesToAttributes = function (pushes) {
	var push_ = function (m) {
		if (!m.$) {
			var screenSize = m.a.dw;
			var moveCount = m.a.ae;
			return elm$core$Maybe$Just(
				elm$html$Html$Attributes$class(
					'push' + (rundis$elm_bootstrap$Bootstrap$Grid$Internal$screenSizeToPartialString(screenSize) + rundis$elm_bootstrap$Bootstrap$Grid$Internal$moveCountOption(moveCount))));
		} else {
			return elm$core$Maybe$Nothing;
		}
	};
	return A2(
		elm$core$List$filterMap,
		elm$core$Basics$identity,
		A2(elm$core$List$map, push_, pushes));
};
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$verticalAlignOption = function (align) {
	switch (align) {
		case 0:
			return 'start';
		case 1:
			return 'center';
		default:
			return 'end';
	}
};
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$vAlignClass = F2(
	function (prefix, _n0) {
		var align = _n0.cL;
		var screenSize = _n0.dw;
		return elm$html$Html$Attributes$class(
			_Utils_ap(
				prefix,
				_Utils_ap(
					A2(
						elm$core$Maybe$withDefault,
						'',
						A2(
							elm$core$Maybe$map,
							function (v) {
								return v + '-';
							},
							rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(screenSize))),
					rundis$elm_bootstrap$Bootstrap$Grid$Internal$verticalAlignOption(align))));
	});
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$vAlignsToAttributes = F2(
	function (prefix, aligns) {
		var align = function (a) {
			return A2(
				elm$core$Maybe$map,
				rundis$elm_bootstrap$Bootstrap$Grid$Internal$vAlignClass(prefix),
				a);
		};
		return A2(
			elm$core$List$filterMap,
			elm$core$Basics$identity,
			A2(elm$core$List$map, align, aligns));
	});
var rundis$elm_bootstrap$Bootstrap$Internal$Text$textAlignDirOption = function (dir) {
	switch (dir) {
		case 1:
			return 'center';
		case 0:
			return 'left';
		default:
			return 'right';
	}
};
var rundis$elm_bootstrap$Bootstrap$Internal$Text$textAlignClass = function (_n0) {
	var dir = _n0.cU;
	var size = _n0.cz;
	return elm$html$Html$Attributes$class(
		'text' + (A2(
			elm$core$Maybe$withDefault,
			'-',
			A2(
				elm$core$Maybe$map,
				function (s) {
					return '-' + (s + '-');
				},
				rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(size))) + rundis$elm_bootstrap$Bootstrap$Internal$Text$textAlignDirOption(dir)));
};
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$colAttributes = function (modifiers) {
	var options = A3(elm$core$List$foldl, rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColOption, rundis$elm_bootstrap$Bootstrap$Grid$Internal$defaultColOptions, modifiers);
	var shouldAddDefaultXs = !elm$core$List$length(
		A2(
			elm$core$List$filterMap,
			elm$core$Basics$identity,
			_List_fromArray(
				[options.aP, options.aN, options.aM, options.aL, options.aO])));
	return _Utils_ap(
		rundis$elm_bootstrap$Bootstrap$Grid$Internal$colWidthsToAttributes(
			_List_fromArray(
				[
					shouldAddDefaultXs ? elm$core$Maybe$Just(
					A2(rundis$elm_bootstrap$Bootstrap$Grid$Internal$Width, 0, 0)) : options.aP,
					options.aN,
					options.aM,
					options.aL,
					options.aO
				])),
		_Utils_ap(
			rundis$elm_bootstrap$Bootstrap$Grid$Internal$offsetsToAttributes(
				_List_fromArray(
					[options.bu, options.br, options.bq, options.bp, options.bt])),
			_Utils_ap(
				rundis$elm_bootstrap$Bootstrap$Grid$Internal$pullsToAttributes(
					_List_fromArray(
						[options.bK, options.bI, options.bH, options.bG, options.bJ])),
				_Utils_ap(
					rundis$elm_bootstrap$Bootstrap$Grid$Internal$pushesToAttributes(
						_List_fromArray(
							[options.bP, options.bN, options.bM, options.bL, options.bO])),
					_Utils_ap(
						rundis$elm_bootstrap$Bootstrap$Grid$Internal$orderToAttributes(
							_List_fromArray(
								[options.bE, options.bC, options.bB, options.bA, options.bD])),
						_Utils_ap(
							A2(
								rundis$elm_bootstrap$Bootstrap$Grid$Internal$vAlignsToAttributes,
								'align-self-',
								_List_fromArray(
									[options.aY, options.aW, options.aV, options.aU, options.aX])),
							_Utils_ap(
								function () {
									var _n0 = options.bX;
									if (!_n0.$) {
										var a = _n0.a;
										return _List_fromArray(
											[
												rundis$elm_bootstrap$Bootstrap$Internal$Text$textAlignClass(a)
											]);
									} else {
										return _List_Nil;
									}
								}(),
								options.a$)))))));
};
var rundis$elm_bootstrap$Bootstrap$Grid$renderCol = function (column) {
	switch (column.$) {
		case 0:
			var options = column.a.cr;
			var children = column.a.cQ;
			return A2(
				elm$html$Html$div,
				rundis$elm_bootstrap$Bootstrap$Grid$Internal$colAttributes(options),
				children);
		case 1:
			var e = column.a;
			return e;
		default:
			var options = column.a.cr;
			var children = column.a.cQ;
			return A3(
				elm$html$Html$Keyed$node,
				'div',
				rundis$elm_bootstrap$Bootstrap$Grid$Internal$colAttributes(options),
				children);
	}
};
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyRowHAlign = F2(
	function (align, options) {
		var _n0 = align.dw;
		switch (_n0) {
			case 0:
				return _Utils_update(
					options,
					{
						bf: elm$core$Maybe$Just(align)
					});
			case 1:
				return _Utils_update(
					options,
					{
						bd: elm$core$Maybe$Just(align)
					});
			case 2:
				return _Utils_update(
					options,
					{
						bc: elm$core$Maybe$Just(align)
					});
			case 3:
				return _Utils_update(
					options,
					{
						bb: elm$core$Maybe$Just(align)
					});
			default:
				return _Utils_update(
					options,
					{
						be: elm$core$Maybe$Just(align)
					});
		}
	});
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyRowVAlign = F2(
	function (align_, options) {
		var _n0 = align_.dw;
		switch (_n0) {
			case 0:
				return _Utils_update(
					options,
					{
						b3: elm$core$Maybe$Just(align_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						b1: elm$core$Maybe$Just(align_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						b0: elm$core$Maybe$Just(align_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						b$: elm$core$Maybe$Just(align_)
					});
			default:
				return _Utils_update(
					options,
					{
						b2: elm$core$Maybe$Just(align_)
					});
		}
	});
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyRowOption = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 2:
				var attrs = modifier.a;
				return _Utils_update(
					options,
					{
						a$: _Utils_ap(options.a$, attrs)
					});
			case 0:
				var align = modifier.a;
				return A2(rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyRowVAlign, align, options);
			default:
				var align = modifier.a;
				return A2(rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyRowHAlign, align, options);
		}
	});
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$defaultRowOptions = {a$: _List_Nil, bb: elm$core$Maybe$Nothing, bc: elm$core$Maybe$Nothing, bd: elm$core$Maybe$Nothing, be: elm$core$Maybe$Nothing, bf: elm$core$Maybe$Nothing, b$: elm$core$Maybe$Nothing, b0: elm$core$Maybe$Nothing, b1: elm$core$Maybe$Nothing, b2: elm$core$Maybe$Nothing, b3: elm$core$Maybe$Nothing};
var rundis$elm_bootstrap$Bootstrap$General$Internal$horizontalAlignOption = function (align) {
	switch (align) {
		case 0:
			return 'start';
		case 1:
			return 'center';
		case 2:
			return 'end';
		case 3:
			return 'around';
		default:
			return 'between';
	}
};
var rundis$elm_bootstrap$Bootstrap$General$Internal$hAlignClass = function (_n0) {
	var align = _n0.cL;
	var screenSize = _n0.dw;
	return elm$html$Html$Attributes$class(
		'justify-content-' + (A2(
			elm$core$Maybe$withDefault,
			'',
			A2(
				elm$core$Maybe$map,
				function (v) {
					return v + '-';
				},
				rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(screenSize))) + rundis$elm_bootstrap$Bootstrap$General$Internal$horizontalAlignOption(align)));
};
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$hAlignsToAttributes = function (aligns) {
	var align = function (a) {
		return A2(elm$core$Maybe$map, rundis$elm_bootstrap$Bootstrap$General$Internal$hAlignClass, a);
	};
	return A2(
		elm$core$List$filterMap,
		elm$core$Basics$identity,
		A2(elm$core$List$map, align, aligns));
};
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$rowAttributes = function (modifiers) {
	var options = A3(elm$core$List$foldl, rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyRowOption, rundis$elm_bootstrap$Bootstrap$Grid$Internal$defaultRowOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				elm$html$Html$Attributes$class('row')
			]),
		_Utils_ap(
			A2(
				rundis$elm_bootstrap$Bootstrap$Grid$Internal$vAlignsToAttributes,
				'align-items-',
				_List_fromArray(
					[options.b3, options.b1, options.b0, options.b$, options.b2])),
			_Utils_ap(
				rundis$elm_bootstrap$Bootstrap$Grid$Internal$hAlignsToAttributes(
					_List_fromArray(
						[options.bf, options.bd, options.bc, options.bb, options.be])),
				options.a$)));
};
var rundis$elm_bootstrap$Bootstrap$Grid$row = F2(
	function (options, cols) {
		return A2(
			elm$html$Html$div,
			rundis$elm_bootstrap$Bootstrap$Grid$Internal$rowAttributes(options),
			A2(elm$core$List$map, rundis$elm_bootstrap$Bootstrap$Grid$renderCol, cols));
	});
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$ColAttrs = function (a) {
	return {$: 6, a: a};
};
var rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs = function (attrs_) {
	return rundis$elm_bootstrap$Bootstrap$Grid$Internal$ColAttrs(attrs_);
};
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$Col12 = 12;
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$ColWidth = function (a) {
	return {$: 0, a: a};
};
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$width = F2(
	function (size, count) {
		return rundis$elm_bootstrap$Bootstrap$Grid$Internal$ColWidth(
			A2(rundis$elm_bootstrap$Bootstrap$Grid$Internal$Width, size, count));
	});
var rundis$elm_bootstrap$Bootstrap$Grid$Col$xs12 = A2(rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 0, 12);
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$RowAttrs = function (a) {
	return {$: 2, a: a};
};
var rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs = function (attrs_) {
	return rundis$elm_bootstrap$Bootstrap$Grid$Internal$RowAttrs(attrs_);
};
var author$project$Widgets$simpleRow = F2(
	function (attributes, children) {
		return A2(
			rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[
					rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
					_Utils_ap(
						_List_fromArray(
							[
								A2(elm$html$Html$Attributes$style, 'margin-left', '0'),
								A2(elm$html$Html$Attributes$style, 'margin-right', '0')
							]),
						attributes))
				]),
			_List_fromArray(
				[
					A2(
					rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_fromArray(
						[
							rundis$elm_bootstrap$Bootstrap$Grid$Col$xs12,
							rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs(
							_List_fromArray(
								[
									A2(elm$html$Html$Attributes$style, 'padding-left', '0'),
									A2(elm$html$Html$Attributes$style, 'padding-right', '0')
								]))
						]),
					children)
				]));
	});
var elm$html$Html$img = _VirtualDom_node('img');
var elm$html$Html$Attributes$src = function (url) {
	return A2(
		elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var author$project$Widgets$spinnerImg = F2(
	function (rootUrl, attributes) {
		return A2(
			elm$html$Html$img,
			A2(
				elm$core$List$cons,
				elm$html$Html$Attributes$src(rootUrl + '/images/img_ajax_loader.gif'),
				attributes),
			_List_Nil);
	});
var elm$html$Html$Attributes$href = function (url) {
	return A2(
		elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var krisajenkins$remotedata$RemoteData$isLoading = function (data) {
	if (data.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var rundis$elm_bootstrap$Bootstrap$Internal$Button$Attrs = function (a) {
	return {$: 4, a: a};
};
var rundis$elm_bootstrap$Bootstrap$Button$attrs = function (attrs_) {
	return rundis$elm_bootstrap$Bootstrap$Internal$Button$Attrs(attrs_);
};
var rundis$elm_bootstrap$Bootstrap$Internal$Button$Disabled = function (a) {
	return {$: 3, a: a};
};
var rundis$elm_bootstrap$Bootstrap$Button$disabled = function (disabled_) {
	return rundis$elm_bootstrap$Bootstrap$Internal$Button$Disabled(disabled_);
};
var elm$html$Html$a = _VirtualDom_node('a');
var rundis$elm_bootstrap$Bootstrap$Button$linkButton = F2(
	function (options, children) {
		return A2(
			elm$html$Html$a,
			A2(
				elm$core$List$cons,
				A2(elm$html$Html$Attributes$attribute, 'role', 'button'),
				rundis$elm_bootstrap$Bootstrap$Internal$Button$buttonAttributes(options)),
			children);
	});
var rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mr2 = elm$html$Html$Attributes$class('mr-2');
var author$project$CreatePromotion$viewNavButtons = F2(
	function (viewCtx, model) {
		var tSpan = viewCtx.cF;
		var isInProgress = krisajenkins$remotedata$RemoteData$isLoading(model.ai);
		return A2(
			author$project$Widgets$simpleRow,
			_List_Nil,
			_Utils_ap(
				_List_fromArray(
					[
						A2(
						rundis$elm_bootstrap$Bootstrap$Button$linkButton,
						_List_fromArray(
							[
								rundis$elm_bootstrap$Bootstrap$Button$attrs(
								_List_fromArray(
									[
										elm$html$Html$Attributes$href(viewCtx.dP + '/page/promotion/dashboard')
									])),
								rundis$elm_bootstrap$Bootstrap$Button$primary,
								rundis$elm_bootstrap$Bootstrap$Button$disabled(isInProgress)
							]),
						_List_fromArray(
							[
								A2(
								elm$html$Html$i,
								_List_fromArray(
									[
										elm$html$Html$Attributes$class('fa fa-angle-left'),
										rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mr2
									]),
								_List_Nil),
								tSpan(1)
							])),
						A2(
						author$project$Widgets$nextBtn,
						viewCtx,
						_List_fromArray(
							[
								rundis$elm_bootstrap$Bootstrap$Button$disabled(isInProgress),
								rundis$elm_bootstrap$Bootstrap$Button$attrs(
								_List_fromArray(
									[rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml2]))
							]))
					]),
				isInProgress ? _List_fromArray(
					[
						A2(
						author$project$Widgets$spinnerImg,
						viewCtx.ep,
						_List_fromArray(
							[
								A2(elm$html$Html$Attributes$style, 'height', '20px')
							]))
					]) : _List_Nil));
	});
var author$project$CreatePromotion$SelectedPromotion = function (a) {
	return {$: 2, a: a};
};
var elm$html$Html$option = _VirtualDom_node('option');
var rundis$elm_bootstrap$Bootstrap$Form$Select$Item = elm$core$Basics$identity;
var rundis$elm_bootstrap$Bootstrap$Form$Select$item = F2(
	function (attributes, children) {
		return A2(elm$html$Html$option, attributes, children);
	});
var author$project$CreatePromotion$emptyOption = A2(
	rundis$elm_bootstrap$Bootstrap$Form$Select$item,
	_List_Nil,
	_List_fromArray(
		[
			elm$html$Html$text('--')
		]));
var elm$html$Html$Attributes$selected = elm$html$Html$Attributes$boolProperty('selected');
var elm$html$Html$Attributes$value = elm$html$Html$Attributes$stringProperty('value');
var author$project$CreatePromotion$viewTemplatesOption = F2(
	function (isSelected, _n0) {
		var id = _n0.c2;
		var name = _n0.Q;
		return A2(
			rundis$elm_bootstrap$Bootstrap$Form$Select$item,
			_List_fromArray(
				[
					elm$html$Html$Attributes$selected(isSelected),
					elm$html$Html$Attributes$value(
					author$project$PromotionId$toString(id))
				]),
			_List_fromArray(
				[
					elm$html$Html$text(name)
				]));
	});
var elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3(elm$core$List$foldr, elm$json$Json$Decode$field, decoder, fields);
	});
var elm$html$Html$Events$targetValue = A2(
	elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	elm$json$Json$Decode$string);
var author$project$PromotionId$targetValue = A2(
	elm$json$Json$Decode$andThen,
	function (s) {
		var _n0 = A2(elm$json$Json$Decode$decodeString, author$project$PromotionId$decoder, s);
		if (!_n0.$) {
			var id = _n0.a;
			return elm$json$Json$Decode$succeed(id);
		} else {
			var err = _n0.a;
			return elm$json$Json$Decode$fail(
				elm$json$Json$Decode$errorToString(err));
		}
	},
	elm$html$Html$Events$targetValue);
var rundis$elm_bootstrap$Bootstrap$Form$Select$Attrs = function (a) {
	return {$: 6, a: a};
};
var rundis$elm_bootstrap$Bootstrap$Form$Select$attrs = function (attrs_) {
	return rundis$elm_bootstrap$Bootstrap$Form$Select$Attrs(attrs_);
};
var rundis$elm_bootstrap$Bootstrap$Form$Select$Custom = {$: 2};
var rundis$elm_bootstrap$Bootstrap$Form$Select$Select = elm$core$Basics$identity;
var rundis$elm_bootstrap$Bootstrap$Form$Select$create = F2(
	function (options, items) {
		return {c8: items, cr: options};
	});
var elm$html$Html$select = _VirtualDom_node('select');
var elm$html$Html$Attributes$id = elm$html$Html$Attributes$stringProperty('id');
var rundis$elm_bootstrap$Bootstrap$Form$Select$applyModifier = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var size_ = modifier.a;
				return _Utils_update(
					options,
					{
						cz: elm$core$Maybe$Just(size_)
					});
			case 1:
				var id_ = modifier.a;
				return _Utils_update(
					options,
					{
						c2: elm$core$Maybe$Just(id_)
					});
			case 2:
				return _Utils_update(
					options,
					{o: true});
			case 3:
				var val = modifier.a;
				return _Utils_update(
					options,
					{a9: val});
			case 4:
				var onChange_ = modifier.a;
				return _Utils_update(
					options,
					{
						bv: elm$core$Maybe$Just(onChange_)
					});
			case 5:
				var validation_ = modifier.a;
				return _Utils_update(
					options,
					{
						b4: elm$core$Maybe$Just(validation_)
					});
			default:
				var attrs_ = modifier.a;
				return _Utils_update(
					options,
					{
						a$: _Utils_ap(options.a$, attrs_)
					});
		}
	});
var rundis$elm_bootstrap$Bootstrap$Form$Select$customEventOnChange = function (tagger) {
	return A2(
		elm$html$Html$Events$on,
		'change',
		A2(elm$json$Json$Decode$map, tagger, elm$html$Html$Events$targetValue));
};
var rundis$elm_bootstrap$Bootstrap$Form$Select$defaultOptions = {a$: _List_Nil, o: false, a9: false, c2: elm$core$Maybe$Nothing, bv: elm$core$Maybe$Nothing, cz: elm$core$Maybe$Nothing, b4: elm$core$Maybe$Nothing};
var rundis$elm_bootstrap$Bootstrap$Form$Select$sizeAttribute = F2(
	function (isCustom, size_) {
		var prefix = isCustom ? 'custom-select-' : 'form-control-';
		return A2(
			elm$core$Maybe$map,
			function (s) {
				return elm$html$Html$Attributes$class(
					_Utils_ap(prefix, s));
			},
			rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(size_));
	});
var rundis$elm_bootstrap$Bootstrap$Form$FormInternal$validationToString = function (validation) {
	if (!validation) {
		return 'is-valid';
	} else {
		return 'is-invalid';
	}
};
var rundis$elm_bootstrap$Bootstrap$Form$Select$validationAttribute = function (validation_) {
	return elm$html$Html$Attributes$class(
		rundis$elm_bootstrap$Bootstrap$Form$FormInternal$validationToString(validation_));
};
var rundis$elm_bootstrap$Bootstrap$Form$Select$toAttributes = function (modifiers) {
	var options = A3(elm$core$List$foldl, rundis$elm_bootstrap$Bootstrap$Form$Select$applyModifier, rundis$elm_bootstrap$Bootstrap$Form$Select$defaultOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('form-control', !options.o),
						_Utils_Tuple2('custom-select', options.o)
					])),
				elm$html$Html$Attributes$disabled(options.a9)
			]),
		_Utils_ap(
			A2(
				elm$core$List$filterMap,
				elm$core$Basics$identity,
				_List_fromArray(
					[
						A2(elm$core$Maybe$map, elm$html$Html$Attributes$id, options.c2),
						A2(
						elm$core$Maybe$andThen,
						rundis$elm_bootstrap$Bootstrap$Form$Select$sizeAttribute(options.o),
						options.cz),
						A2(elm$core$Maybe$map, rundis$elm_bootstrap$Bootstrap$Form$Select$customEventOnChange, options.bv),
						A2(elm$core$Maybe$map, rundis$elm_bootstrap$Bootstrap$Form$Select$validationAttribute, options.b4)
					])),
			options.a$));
};
var rundis$elm_bootstrap$Bootstrap$Form$Select$view = function (_n0) {
	var options = _n0.cr;
	var items = _n0.c8;
	return A2(
		elm$html$Html$select,
		rundis$elm_bootstrap$Bootstrap$Form$Select$toAttributes(options),
		A2(
			elm$core$List$map,
			function (_n1) {
				var e = _n1;
				return e;
			},
			items));
};
var rundis$elm_bootstrap$Bootstrap$Form$Select$custom = function (options) {
	return A2(
		elm$core$Basics$composeL,
		rundis$elm_bootstrap$Bootstrap$Form$Select$view,
		rundis$elm_bootstrap$Bootstrap$Form$Select$create(
			A2(elm$core$List$cons, rundis$elm_bootstrap$Bootstrap$Form$Select$Custom, options)));
};
var rundis$elm_bootstrap$Bootstrap$Form$FormInternal$Danger = 1;
var rundis$elm_bootstrap$Bootstrap$Form$Select$Validation = function (a) {
	return {$: 5, a: a};
};
var rundis$elm_bootstrap$Bootstrap$Form$Select$danger = rundis$elm_bootstrap$Bootstrap$Form$Select$Validation(1);
var rundis$elm_bootstrap$Bootstrap$Form$Select$Disabled = function (a) {
	return {$: 3, a: a};
};
var rundis$elm_bootstrap$Bootstrap$Form$Select$disabled = function (disabled_) {
	return rundis$elm_bootstrap$Bootstrap$Form$Select$Disabled(disabled_);
};
var author$project$CreatePromotion$viewTemplatesSelect = function (model) {
	var isSelected = function (opt) {
		var _n0 = model.v;
		if (_n0.$ === 2) {
			var id = _n0.a;
			return _Utils_eq(id, opt.c2);
		} else {
			return false;
		}
	};
	var decoder = elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				elm$json$Json$Decode$map,
				function (id) {
					return author$project$CreatePromotion$PromotionBaseChanged(
						author$project$CreatePromotion$SelectedPromotion(id));
				},
				author$project$PromotionId$targetValue),
				elm$json$Json$Decode$succeed(
				author$project$CreatePromotion$PromotionBaseChanged(author$project$CreatePromotion$PromotionNotSelected))
			]));
	return _Utils_ap(
		_List_fromArray(
			[
				A2(
				rundis$elm_bootstrap$Bootstrap$Form$Select$custom,
				_Utils_ap(
					_List_fromArray(
						[
							rundis$elm_bootstrap$Bootstrap$Form$Select$disabled(
							_Utils_eq(model.v, author$project$CreatePromotion$NoPromotion)),
							rundis$elm_bootstrap$Bootstrap$Form$Select$attrs(
							_List_fromArray(
								[
									A2(elm$html$Html$Events$on, 'change', decoder)
								]))
						]),
					A3(
						author$project$CreatePromotion$fieldErrorDefault,
						_List_fromArray(
							[rundis$elm_bootstrap$Bootstrap$Form$Select$danger]),
						1,
						model)),
				A2(
					elm$core$List$cons,
					author$project$CreatePromotion$emptyOption,
					A2(
						elm$core$List$map,
						function (template) {
							return A2(
								author$project$CreatePromotion$viewTemplatesOption,
								isSelected(template),
								template);
						},
						model.aB)))
			]),
		A2(author$project$CreatePromotion$viewFieldError, 1, model));
};
var author$project$Language$FORM_MAX_CHARACTERS = 15;
var author$project$Language$FORM_OPTIONAL = 14;
var author$project$Language$FORM_REQUIRED = 13;
var author$project$Language$Number = function (a) {
	return {$: 0, a: a};
};
var author$project$Language$TPL_BASE = 24;
var author$project$Language$TPL_DESC = 25;
var author$project$Language$TPL_NEW = 23;
var elm$html$Html$Attributes$for = elm$html$Html$Attributes$stringProperty('htmlFor');
var elm$html$Html$Attributes$maxlength = function (n) {
	return A2(
		_VirtualDom_attribute,
		'maxlength',
		elm$core$String$fromInt(n));
};
var elm$html$Html$Events$alwaysPreventDefault = function (msg) {
	return _Utils_Tuple2(msg, true);
};
var elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			elm$virtual_dom$VirtualDom$on,
			event,
			elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var elm$html$Html$Events$onSubmit = function (msg) {
	return A2(
		elm$html$Html$Events$preventDefaultOn,
		'submit',
		A2(
			elm$json$Json$Decode$map,
			elm$html$Html$Events$alwaysPreventDefault,
			elm$json$Json$Decode$succeed(msg)));
};
var elm$html$Html$form = _VirtualDom_node('form');
var rundis$elm_bootstrap$Bootstrap$Form$form = F2(
	function (attributes, children) {
		return A2(elm$html$Html$form, attributes, children);
	});
var rundis$elm_bootstrap$Bootstrap$Form$applyModifier = F2(
	function (modifier, options) {
		var value = modifier;
		return _Utils_update(
			options,
			{
				a$: _Utils_ap(options.a$, value)
			});
	});
var rundis$elm_bootstrap$Bootstrap$Form$defaultOptions = {a$: _List_Nil};
var rundis$elm_bootstrap$Bootstrap$Form$toAttributes = function (modifiers) {
	var options = A3(elm$core$List$foldl, rundis$elm_bootstrap$Bootstrap$Form$applyModifier, rundis$elm_bootstrap$Bootstrap$Form$defaultOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				elm$html$Html$Attributes$class('form-group')
			]),
		options.a$);
};
var rundis$elm_bootstrap$Bootstrap$Form$group = F2(
	function (options, children) {
		return A2(
			elm$html$Html$div,
			rundis$elm_bootstrap$Bootstrap$Form$toAttributes(options),
			children);
	});
var elm$html$Html$label = _VirtualDom_node('label');
var rundis$elm_bootstrap$Bootstrap$Form$label = F2(
	function (attributes, children) {
		return A2(
			elm$html$Html$label,
			A2(
				elm$core$List$cons,
				elm$html$Html$Attributes$class('form-control-label'),
				attributes),
			children);
	});
var rundis$elm_bootstrap$Bootstrap$Form$Input$Attrs = function (a) {
	return {$: 9, a: a};
};
var rundis$elm_bootstrap$Bootstrap$Form$Input$attrs = function (attrs_) {
	return rundis$elm_bootstrap$Bootstrap$Form$Input$Attrs(attrs_);
};
var rundis$elm_bootstrap$Bootstrap$Form$Input$Validation = function (a) {
	return {$: 6, a: a};
};
var rundis$elm_bootstrap$Bootstrap$Form$Input$danger = rundis$elm_bootstrap$Bootstrap$Form$Input$Validation(1);
var rundis$elm_bootstrap$Bootstrap$Form$Input$Id = function (a) {
	return {$: 1, a: a};
};
var rundis$elm_bootstrap$Bootstrap$Form$Input$id = function (id_) {
	return rundis$elm_bootstrap$Bootstrap$Form$Input$Id(id_);
};
var rundis$elm_bootstrap$Bootstrap$Form$Input$OnInput = function (a) {
	return {$: 5, a: a};
};
var rundis$elm_bootstrap$Bootstrap$Form$Input$onInput = function (toMsg) {
	return rundis$elm_bootstrap$Bootstrap$Form$Input$OnInput(toMsg);
};
var rundis$elm_bootstrap$Bootstrap$Form$Input$Text = 0;
var rundis$elm_bootstrap$Bootstrap$Form$Input$Input = elm$core$Basics$identity;
var rundis$elm_bootstrap$Bootstrap$Form$Input$Type = function (a) {
	return {$: 2, a: a};
};
var rundis$elm_bootstrap$Bootstrap$Form$Input$create = F2(
	function (tipe, options) {
		return {
			cr: A2(
				elm$core$List$cons,
				rundis$elm_bootstrap$Bootstrap$Form$Input$Type(tipe),
				options)
		};
	});
var elm$html$Html$input = _VirtualDom_node('input');
var elm$html$Html$Attributes$placeholder = elm$html$Html$Attributes$stringProperty('placeholder');
var elm$html$Html$Attributes$readonly = elm$html$Html$Attributes$boolProperty('readOnly');
var elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			elm$virtual_dom$VirtualDom$on,
			event,
			elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			elm$json$Json$Decode$map,
			elm$html$Html$Events$alwaysStop,
			A2(elm$json$Json$Decode$map, tagger, elm$html$Html$Events$targetValue)));
};
var rundis$elm_bootstrap$Bootstrap$Form$Input$applyModifier = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var size_ = modifier.a;
				return _Utils_update(
					options,
					{
						cz: elm$core$Maybe$Just(size_)
					});
			case 1:
				var id_ = modifier.a;
				return _Utils_update(
					options,
					{
						c2: elm$core$Maybe$Just(id_)
					});
			case 2:
				var tipe = modifier.a;
				return _Utils_update(
					options,
					{aF: tipe});
			case 3:
				var val = modifier.a;
				return _Utils_update(
					options,
					{a9: val});
			case 4:
				var value_ = modifier.a;
				return _Utils_update(
					options,
					{
						y: elm$core$Maybe$Just(value_)
					});
			case 7:
				var value_ = modifier.a;
				return _Utils_update(
					options,
					{
						bF: elm$core$Maybe$Just(value_)
					});
			case 5:
				var onInput_ = modifier.a;
				return _Utils_update(
					options,
					{
						by: elm$core$Maybe$Just(onInput_)
					});
			case 6:
				var validation_ = modifier.a;
				return _Utils_update(
					options,
					{
						b4: elm$core$Maybe$Just(validation_)
					});
			case 8:
				var val = modifier.a;
				return _Utils_update(
					options,
					{bQ: val});
			default:
				var attrs_ = modifier.a;
				return _Utils_update(
					options,
					{
						a$: _Utils_ap(options.a$, attrs_)
					});
		}
	});
var rundis$elm_bootstrap$Bootstrap$Form$Input$defaultOptions = {a$: _List_Nil, a9: false, c2: elm$core$Maybe$Nothing, by: elm$core$Maybe$Nothing, bF: elm$core$Maybe$Nothing, bQ: false, cz: elm$core$Maybe$Nothing, aF: 0, b4: elm$core$Maybe$Nothing, y: elm$core$Maybe$Nothing};
var rundis$elm_bootstrap$Bootstrap$Form$Input$sizeAttribute = function (size) {
	return A2(
		elm$core$Maybe$map,
		function (s) {
			return elm$html$Html$Attributes$class('form-control-' + s);
		},
		rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(size));
};
var rundis$elm_bootstrap$Bootstrap$Form$Input$typeAttribute = function (inputType) {
	return elm$html$Html$Attributes$type_(
		function () {
			switch (inputType) {
				case 0:
					return 'text';
				case 1:
					return 'password';
				case 2:
					return 'datetime-local';
				case 3:
					return 'date';
				case 4:
					return 'month';
				case 5:
					return 'time';
				case 6:
					return 'week';
				case 7:
					return 'number';
				case 8:
					return 'email';
				case 9:
					return 'url';
				case 10:
					return 'search';
				case 11:
					return 'tel';
				default:
					return 'color';
			}
		}());
};
var rundis$elm_bootstrap$Bootstrap$Form$Input$validationAttribute = function (validation) {
	return elm$html$Html$Attributes$class(
		rundis$elm_bootstrap$Bootstrap$Form$FormInternal$validationToString(validation));
};
var rundis$elm_bootstrap$Bootstrap$Form$Input$toAttributes = function (modifiers) {
	var options = A3(elm$core$List$foldl, rundis$elm_bootstrap$Bootstrap$Form$Input$applyModifier, rundis$elm_bootstrap$Bootstrap$Form$Input$defaultOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				elm$html$Html$Attributes$class('form-control'),
				elm$html$Html$Attributes$disabled(options.a9),
				elm$html$Html$Attributes$readonly(options.bQ),
				rundis$elm_bootstrap$Bootstrap$Form$Input$typeAttribute(options.aF)
			]),
		_Utils_ap(
			A2(
				elm$core$List$filterMap,
				elm$core$Basics$identity,
				_List_fromArray(
					[
						A2(elm$core$Maybe$map, elm$html$Html$Attributes$id, options.c2),
						A2(elm$core$Maybe$andThen, rundis$elm_bootstrap$Bootstrap$Form$Input$sizeAttribute, options.cz),
						A2(elm$core$Maybe$map, elm$html$Html$Attributes$value, options.y),
						A2(elm$core$Maybe$map, elm$html$Html$Attributes$placeholder, options.bF),
						A2(elm$core$Maybe$map, elm$html$Html$Events$onInput, options.by),
						A2(elm$core$Maybe$map, rundis$elm_bootstrap$Bootstrap$Form$Input$validationAttribute, options.b4)
					])),
			options.a$));
};
var rundis$elm_bootstrap$Bootstrap$Form$Input$view = function (_n0) {
	var options = _n0.cr;
	return A2(
		elm$html$Html$input,
		rundis$elm_bootstrap$Bootstrap$Form$Input$toAttributes(options),
		_List_Nil);
};
var rundis$elm_bootstrap$Bootstrap$Form$Input$input = F2(
	function (tipe, options) {
		return rundis$elm_bootstrap$Bootstrap$Form$Input$view(
			A2(rundis$elm_bootstrap$Bootstrap$Form$Input$create, tipe, options));
	});
var rundis$elm_bootstrap$Bootstrap$Form$Input$text = rundis$elm_bootstrap$Bootstrap$Form$Input$input(0);
var rundis$elm_bootstrap$Bootstrap$Form$Input$Value = function (a) {
	return {$: 4, a: a};
};
var rundis$elm_bootstrap$Bootstrap$Form$Input$value = function (value_) {
	return rundis$elm_bootstrap$Bootstrap$Form$Input$Value(value_);
};
var rundis$elm_bootstrap$Bootstrap$Form$Radio$Checked = function (a) {
	return {$: 1, a: a};
};
var rundis$elm_bootstrap$Bootstrap$Form$Radio$checked = function (isCheck) {
	return rundis$elm_bootstrap$Bootstrap$Form$Radio$Checked(isCheck);
};
var rundis$elm_bootstrap$Bootstrap$Form$Radio$Radio = elm$core$Basics$identity;
var rundis$elm_bootstrap$Bootstrap$Form$Radio$create = F2(
	function (options, label) {
		return {da: label, cr: options};
	});
var rundis$elm_bootstrap$Bootstrap$Form$Radio$Id = function (a) {
	return {$: 0, a: a};
};
var rundis$elm_bootstrap$Bootstrap$Form$Radio$id = function (theId) {
	return rundis$elm_bootstrap$Bootstrap$Form$Radio$Id(theId);
};
var rundis$elm_bootstrap$Bootstrap$Form$Radio$Inline = {$: 2};
var rundis$elm_bootstrap$Bootstrap$Form$Radio$inline = rundis$elm_bootstrap$Bootstrap$Form$Radio$Inline;
var rundis$elm_bootstrap$Bootstrap$Form$Radio$OnClick = function (a) {
	return {$: 4, a: a};
};
var rundis$elm_bootstrap$Bootstrap$Form$Radio$onClick = function (toMsg) {
	return rundis$elm_bootstrap$Bootstrap$Form$Radio$OnClick(toMsg);
};
var rundis$elm_bootstrap$Bootstrap$Form$Radio$addOption = F2(
	function (opt, _n0) {
		var radio_ = _n0;
		var options = radio_.cr;
		return _Utils_update(
			radio_,
			{
				cr: A2(elm$core$List$cons, opt, options)
			});
	});
var rundis$elm_bootstrap$Bootstrap$Form$Radio$Name = function (a) {
	return {$: 3, a: a};
};
var rundis$elm_bootstrap$Bootstrap$Form$Radio$name = function (name_) {
	return rundis$elm_bootstrap$Bootstrap$Form$Radio$Name(name_);
};
var rundis$elm_bootstrap$Bootstrap$Form$Radio$applyModifier = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var val = modifier.a;
				return _Utils_update(
					options,
					{
						c2: elm$core$Maybe$Just(val)
					});
			case 1:
				var val = modifier.a;
				return _Utils_update(
					options,
					{a3: val});
			case 3:
				var val = modifier.a;
				return _Utils_update(
					options,
					{
						Q: elm$core$Maybe$Just(val)
					});
			case 2:
				return _Utils_update(
					options,
					{at: true});
			case 4:
				var toMsg = modifier.a;
				return _Utils_update(
					options,
					{
						bx: elm$core$Maybe$Just(toMsg)
					});
			case 5:
				return _Utils_update(
					options,
					{o: true});
			case 6:
				var val = modifier.a;
				return _Utils_update(
					options,
					{a9: val});
			case 7:
				var validation = modifier.a;
				return _Utils_update(
					options,
					{
						b4: elm$core$Maybe$Just(validation)
					});
			default:
				var attrs_ = modifier.a;
				return _Utils_update(
					options,
					{
						a$: _Utils_ap(options.a$, attrs_)
					});
		}
	});
var rundis$elm_bootstrap$Bootstrap$Form$Radio$defaultOptions = {a$: _List_Nil, a3: false, o: false, a9: false, c2: elm$core$Maybe$Nothing, at: false, Q: elm$core$Maybe$Nothing, bx: elm$core$Maybe$Nothing, b4: elm$core$Maybe$Nothing};
var elm$html$Html$Attributes$checked = elm$html$Html$Attributes$boolProperty('checked');
var elm$html$Html$Attributes$name = elm$html$Html$Attributes$stringProperty('name');
var rundis$elm_bootstrap$Bootstrap$Form$Radio$toAttributes = function (options) {
	return _Utils_ap(
		_List_fromArray(
			[
				elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('form-check-input', !options.o),
						_Utils_Tuple2('custom-control-input', options.o)
					])),
				elm$html$Html$Attributes$type_('radio'),
				elm$html$Html$Attributes$disabled(options.a9),
				elm$html$Html$Attributes$checked(options.a3)
			]),
		_Utils_ap(
			A2(
				elm$core$List$filterMap,
				elm$core$Basics$identity,
				_List_fromArray(
					[
						A2(elm$core$Maybe$map, elm$html$Html$Events$onClick, options.bx),
						A2(elm$core$Maybe$map, elm$html$Html$Attributes$name, options.Q),
						A2(elm$core$Maybe$map, elm$html$Html$Attributes$id, options.c2)
					])),
			options.a$));
};
var rundis$elm_bootstrap$Bootstrap$Form$Radio$view = function (_n0) {
	var radio_ = _n0;
	var opts = A3(elm$core$List$foldl, rundis$elm_bootstrap$Bootstrap$Form$Radio$applyModifier, rundis$elm_bootstrap$Bootstrap$Form$Radio$defaultOptions, radio_.cr);
	return A2(
		elm$html$Html$div,
		_List_fromArray(
			[
				elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('form-check', !opts.o),
						_Utils_Tuple2('form-check-inline', (!opts.o) && opts.at),
						_Utils_Tuple2('custom-control', opts.o),
						_Utils_Tuple2('custom-radio', opts.o),
						_Utils_Tuple2('custom-control-inline', opts.at && opts.o)
					]))
			]),
		_List_fromArray(
			[
				A2(
				elm$html$Html$input,
				rundis$elm_bootstrap$Bootstrap$Form$Radio$toAttributes(opts),
				_List_Nil),
				A2(
				elm$html$Html$label,
				_Utils_ap(
					_List_fromArray(
						[
							elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('form-check-label', !opts.o),
									_Utils_Tuple2('custom-control-label', opts.o)
								]))
						]),
					function () {
						var _n1 = opts.c2;
						if (!_n1.$) {
							var v = _n1.a;
							return _List_fromArray(
								[
									elm$html$Html$Attributes$for(v)
								]);
						} else {
							return _List_Nil;
						}
					}()),
				_List_fromArray(
					[
						elm$html$Html$text(radio_.da)
					]))
			]));
};
var rundis$elm_bootstrap$Bootstrap$Form$Radio$radioList = F2(
	function (groupName, radios) {
		return A2(
			elm$core$List$map,
			A2(
				elm$core$Basics$composeL,
				rundis$elm_bootstrap$Bootstrap$Form$Radio$view,
				rundis$elm_bootstrap$Bootstrap$Form$Radio$addOption(
					rundis$elm_bootstrap$Bootstrap$Form$Radio$name(groupName))),
			radios);
	});
var rundis$elm_bootstrap$Bootstrap$Form$Textarea$Id = function (a) {
	return {$: 0, a: a};
};
var rundis$elm_bootstrap$Bootstrap$Form$Textarea$id = function (id_) {
	return rundis$elm_bootstrap$Bootstrap$Form$Textarea$Id(id_);
};
var rundis$elm_bootstrap$Bootstrap$Form$Textarea$OnInput = function (a) {
	return {$: 4, a: a};
};
var rundis$elm_bootstrap$Bootstrap$Form$Textarea$onInput = function (toMsg) {
	return rundis$elm_bootstrap$Bootstrap$Form$Textarea$OnInput(toMsg);
};
var rundis$elm_bootstrap$Bootstrap$Form$Textarea$Rows = function (a) {
	return {$: 1, a: a};
};
var rundis$elm_bootstrap$Bootstrap$Form$Textarea$rows = function (rows_) {
	return rundis$elm_bootstrap$Bootstrap$Form$Textarea$Rows(rows_);
};
var rundis$elm_bootstrap$Bootstrap$Form$Textarea$Textarea = elm$core$Basics$identity;
var rundis$elm_bootstrap$Bootstrap$Form$Textarea$create = function (options) {
	return {cr: options};
};
var elm$html$Html$textarea = _VirtualDom_node('textarea');
var elm$html$Html$Attributes$rows = function (n) {
	return A2(
		_VirtualDom_attribute,
		'rows',
		elm$core$String$fromInt(n));
};
var rundis$elm_bootstrap$Bootstrap$Form$Textarea$applyModifier = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var id_ = modifier.a;
				return _Utils_update(
					options,
					{
						c2: elm$core$Maybe$Just(id_)
					});
			case 1:
				var rows_ = modifier.a;
				return _Utils_update(
					options,
					{
						bS: elm$core$Maybe$Just(rows_)
					});
			case 2:
				return _Utils_update(
					options,
					{a9: true});
			case 3:
				var value_ = modifier.a;
				return _Utils_update(
					options,
					{
						y: elm$core$Maybe$Just(value_)
					});
			case 4:
				var onInput_ = modifier.a;
				return _Utils_update(
					options,
					{
						by: elm$core$Maybe$Just(onInput_)
					});
			case 5:
				var validation = modifier.a;
				return _Utils_update(
					options,
					{
						b4: elm$core$Maybe$Just(validation)
					});
			default:
				var attrs_ = modifier.a;
				return _Utils_update(
					options,
					{
						a$: _Utils_ap(options.a$, attrs_)
					});
		}
	});
var rundis$elm_bootstrap$Bootstrap$Form$Textarea$defaultOptions = {a$: _List_Nil, a9: false, c2: elm$core$Maybe$Nothing, by: elm$core$Maybe$Nothing, bS: elm$core$Maybe$Nothing, b4: elm$core$Maybe$Nothing, y: elm$core$Maybe$Nothing};
var rundis$elm_bootstrap$Bootstrap$Form$Textarea$validationAttribute = function (validation) {
	return elm$html$Html$Attributes$class(
		rundis$elm_bootstrap$Bootstrap$Form$FormInternal$validationToString(validation));
};
var rundis$elm_bootstrap$Bootstrap$Form$Textarea$toAttributes = function (modifiers) {
	var options = A3(elm$core$List$foldl, rundis$elm_bootstrap$Bootstrap$Form$Textarea$applyModifier, rundis$elm_bootstrap$Bootstrap$Form$Textarea$defaultOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				elm$html$Html$Attributes$class('form-control'),
				elm$html$Html$Attributes$disabled(options.a9)
			]),
		_Utils_ap(
			A2(
				elm$core$List$filterMap,
				elm$core$Basics$identity,
				_List_fromArray(
					[
						A2(elm$core$Maybe$map, elm$html$Html$Attributes$id, options.c2),
						A2(elm$core$Maybe$map, elm$html$Html$Attributes$rows, options.bS),
						A2(elm$core$Maybe$map, elm$html$Html$Attributes$value, options.y),
						A2(elm$core$Maybe$map, elm$html$Html$Events$onInput, options.by),
						A2(elm$core$Maybe$map, rundis$elm_bootstrap$Bootstrap$Form$Textarea$validationAttribute, options.b4)
					])),
			options.a$));
};
var rundis$elm_bootstrap$Bootstrap$Form$Textarea$view = function (_n0) {
	var options = _n0.cr;
	return A2(
		elm$html$Html$textarea,
		rundis$elm_bootstrap$Bootstrap$Form$Textarea$toAttributes(options),
		_List_Nil);
};
var rundis$elm_bootstrap$Bootstrap$Form$Textarea$textarea = A2(elm$core$Basics$composeL, rundis$elm_bootstrap$Bootstrap$Form$Textarea$view, rundis$elm_bootstrap$Bootstrap$Form$Textarea$create);
var rundis$elm_bootstrap$Bootstrap$Form$Textarea$Value = function (a) {
	return {$: 3, a: a};
};
var rundis$elm_bootstrap$Bootstrap$Form$Textarea$value = function (value_) {
	return rundis$elm_bootstrap$Bootstrap$Form$Textarea$Value(value_);
};
var author$project$CreatePromotion$viewForm = F2(
	function (viewCtx, model) {
		var tSpan = viewCtx.cF;
		var trSpan = viewCtx.dJ;
		var t = viewCtx.ev;
		var maxNameLength = 26;
		var maxLengthSpan = A2(
			trSpan,
			15,
			_List_fromArray(
				[
					author$project$Language$Number(maxNameLength)
				]));
		return A2(
			rundis$elm_bootstrap$Bootstrap$Form$form,
			_List_fromArray(
				[
					elm$html$Html$Events$onSubmit(author$project$CreatePromotion$NewPromotionSubmitted)
				]),
			_List_fromArray(
				[
					A2(
					rundis$elm_bootstrap$Bootstrap$Form$group,
					_List_Nil,
					_Utils_ap(
						_List_fromArray(
							[
								A2(
								rundis$elm_bootstrap$Bootstrap$Form$label,
								_List_fromArray(
									[
										elm$html$Html$Attributes$for('templateName')
									]),
								_List_fromArray(
									[
										tSpan(22),
										elm$html$Html$text(' ('),
										tSpan(13),
										elm$html$Html$text(') '),
										maxLengthSpan
									])),
								rundis$elm_bootstrap$Bootstrap$Form$Input$text(
								_Utils_ap(
									_List_fromArray(
										[
											rundis$elm_bootstrap$Bootstrap$Form$Input$id('templateName'),
											rundis$elm_bootstrap$Bootstrap$Form$Input$value(model.Q),
											rundis$elm_bootstrap$Bootstrap$Form$Input$onInput(author$project$CreatePromotion$TemplateNameChanged),
											rundis$elm_bootstrap$Bootstrap$Form$Input$attrs(
											_List_fromArray(
												[
													elm$html$Html$Attributes$maxlength(maxNameLength)
												]))
										]),
									A3(
										author$project$CreatePromotion$fieldErrorDefault,
										_List_fromArray(
											[rundis$elm_bootstrap$Bootstrap$Form$Input$danger]),
										0,
										model)))
							]),
						A2(author$project$CreatePromotion$viewFieldError, 0, model))),
					A2(
					rundis$elm_bootstrap$Bootstrap$Form$group,
					_List_Nil,
					_Utils_ap(
						A2(
							rundis$elm_bootstrap$Bootstrap$Form$Radio$radioList,
							'templateBase',
							_List_fromArray(
								[
									A2(
									rundis$elm_bootstrap$Bootstrap$Form$Radio$create,
									_List_fromArray(
										[
											rundis$elm_bootstrap$Bootstrap$Form$Radio$id('radio_create_new'),
											rundis$elm_bootstrap$Bootstrap$Form$Radio$inline,
											rundis$elm_bootstrap$Bootstrap$Form$Radio$checked(
											_Utils_eq(model.v, author$project$CreatePromotion$NoPromotion)),
											rundis$elm_bootstrap$Bootstrap$Form$Radio$onClick(
											author$project$CreatePromotion$PromotionBaseChanged(author$project$CreatePromotion$NoPromotion))
										]),
									t(23)),
									A2(
									rundis$elm_bootstrap$Bootstrap$Form$Radio$create,
									_List_fromArray(
										[
											rundis$elm_bootstrap$Bootstrap$Form$Radio$id('radio_create_new'),
											rundis$elm_bootstrap$Bootstrap$Form$Radio$inline,
											rundis$elm_bootstrap$Bootstrap$Form$Radio$checked(
											!_Utils_eq(model.v, author$project$CreatePromotion$NoPromotion)),
											rundis$elm_bootstrap$Bootstrap$Form$Radio$onClick(
											author$project$CreatePromotion$PromotionBaseChanged(author$project$CreatePromotion$PromotionNotSelected))
										]),
									t(24))
								])),
						author$project$CreatePromotion$viewTemplatesSelect(model))),
					A2(
					rundis$elm_bootstrap$Bootstrap$Form$group,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							rundis$elm_bootstrap$Bootstrap$Form$label,
							_List_fromArray(
								[
									elm$html$Html$Attributes$for('templateDescription')
								]),
							_List_fromArray(
								[
									tSpan(25),
									elm$html$Html$text(' ('),
									tSpan(14),
									elm$html$Html$text(')')
								])),
							rundis$elm_bootstrap$Bootstrap$Form$Textarea$textarea(
							_List_fromArray(
								[
									rundis$elm_bootstrap$Bootstrap$Form$Textarea$id('templateDescription'),
									rundis$elm_bootstrap$Bootstrap$Form$Textarea$rows(3),
									rundis$elm_bootstrap$Bootstrap$Form$Textarea$value(model.ap),
									rundis$elm_bootstrap$Bootstrap$Form$Textarea$onInput(author$project$CreatePromotion$PromotionDescriptionChanged)
								]))
						])),
					A2(author$project$CreatePromotion$viewNavButtons, viewCtx, model)
				]));
	});
var author$project$Language$spanAttrs = F2(
	function (ctx, key) {
		return _List_fromArray(
			[
				A2(
				elm$html$Html$Attributes$attribute,
				'data-tooltip',
				author$project$Language$keyToString(key)),
				elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('qtip', ctx.dz),
						_Utils_Tuple2('tip-top', ctx.dz)
					]))
			]);
	});
var author$project$Language$tSpan = F2(
	function (ctx, key) {
		return A2(
			elm$html$Html$span,
			A2(author$project$Language$spanAttrs, ctx, key),
			_List_fromArray(
				[
					elm$html$Html$text(
					A2(author$project$Language$t, ctx, key))
				]));
	});
var author$project$Language$trSpan = F3(
	function (ctx, key, replacements) {
		return A2(
			elm$html$Html$span,
			A2(author$project$Language$spanAttrs, ctx, key),
			_List_fromArray(
				[
					elm$html$Html$text(
					A3(author$project$Language$tr, ctx, key, replacements))
				]));
	});
var author$project$ViewContext$fromSharedState = function (sharedState) {
	return {
		dP: sharedState.dP,
		q: sharedState.q,
		ej: sharedState.ej,
		ep: sharedState.ep,
		ev: author$project$Language$t(sharedState),
		cF: author$project$Language$tSpan(sharedState),
		ex: author$project$Language$tr(sharedState),
		dJ: author$project$Language$trSpan(sharedState),
		ey: sharedState.ey,
		eC: sharedState.eC
	};
};
var elm$html$Html$h2 = _VirtualDom_node('h2');
var author$project$CreatePromotion$view = F2(
	function (sharedState, model) {
		var viewCtx = author$project$ViewContext$fromSharedState(sharedState);
		var tSpan = viewCtx.cF;
		return A2(
			elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					elm$html$Html$h2,
					_List_Nil,
					_List_fromArray(
						[
							tSpan(23)
						])),
					A2(
					rundis$elm_bootstrap$Bootstrap$Grid$row,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_Nil,
							_Utils_ap(
								A2(author$project$CreatePromotion$viewErrors, viewCtx, model),
								_List_fromArray(
									[
										A2(author$project$CreatePromotion$viewForm, viewCtx, model)
									])))
						]))
				]));
	});
var author$project$Main$divCenterCenter = function (children) {
	return A2(
		elm$html$Html$div,
		_List_fromArray(
			[
				A2(elm$html$Html$Attributes$style, 'display', 'flex'),
				A2(elm$html$Html$Attributes$style, 'flex-grow', '1')
			]),
		_List_fromArray(
			[
				A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('col align-self-center text-center')
					]),
				children)
			]));
};
var author$project$Main$viewBigAlertMessage = function (message) {
	return author$project$Main$divCenterCenter(
		_List_fromArray(
			[
				A2(
				rundis$elm_bootstrap$Bootstrap$Alert$simpleDanger,
				_List_fromArray(
					[
						A2(elm$html$Html$Attributes$style, 'display', 'inline-block')
					]),
				_List_fromArray(
					[
						A2(
						elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								elm$html$Html$i,
								_List_fromArray(
									[
										elm$html$Html$Attributes$class('fa fa-4x fa-exclamation-triangle text-danger')
									]),
								_List_Nil)
							])),
						A2(
						elm$html$Html$div,
						_List_fromArray(
							[
								elm$html$Html$Attributes$class('mt-4')
							]),
						_List_fromArray(
							[
								elm$html$Html$text(message)
							]))
					]))
			]));
};
var author$project$Language$ERROR_NEEDS_LICENSE_ACCOUNT_ASSIGNED = 20;
var author$project$Main$viewLicenseAccountUnassignedError = function (sharedState) {
	return author$project$Main$viewBigAlertMessage(
		A2(author$project$Language$t, sharedState, 20));
};
var rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt4 = elm$html$Html$Attributes$class('mt-4');
var author$project$Main$viewLoading = F2(
	function (rootUrl, message) {
		return author$project$Main$divCenterCenter(
			_List_fromArray(
				[
					A2(
					author$project$Widgets$spinnerImg,
					rootUrl,
					_List_fromArray(
						[
							A2(elm$html$Html$Attributes$style, 'height', '6rem')
						])),
					A2(
					elm$html$Html$div,
					_List_fromArray(
						[rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt4]),
					_List_fromArray(
						[
							elm$html$Html$text(message)
						]))
				]));
	});
var author$project$Language$MENU_DASHBOARD = 9;
var author$project$Main$getSharedState = function (model) {
	var _n0 = model.d;
	switch (_n0.$) {
		case 0:
			return elm$core$Maybe$Nothing;
		case 1:
			var sharedState = _n0.a;
			return elm$core$Maybe$Just(sharedState);
		case 2:
			var sharedState = _n0.a;
			return elm$core$Maybe$Just(sharedState);
		case 3:
			var sharedState = _n0.a;
			return elm$core$Maybe$Just(sharedState);
		case 4:
			var sharedState = _n0.a;
			return elm$core$Maybe$Just(sharedState);
		case 5:
			var string = _n0.a;
			return elm$core$Maybe$Nothing;
		default:
			var sharedState = _n0.a;
			return elm$core$Maybe$Just(sharedState);
	}
};
var author$project$Language$MENU_HIDE_TRANSLATIONS = 11;
var author$project$Language$MENU_SHOW_TRANSLATIONS = 10;
var author$project$Main$TranslationTooltipsToggled = {$: 6};
var author$project$Main$viewTranslateLink = function (sharedState) {
	return _List_fromArray(
		[
			A2(
			elm$html$Html$button,
			_List_fromArray(
				[
					elm$html$Html$Attributes$type_('button'),
					elm$html$Html$Attributes$class('btn btn-outline-info'),
					rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mr2,
					elm$html$Html$Events$onClick(author$project$Main$TranslationTooltipsToggled)
				]),
			_List_fromArray(
				[
					sharedState.dz ? A2(author$project$Language$tSpan, sharedState, 11) : A2(author$project$Language$tSpan, sharedState, 10)
				]))
		]);
};
var author$project$Language$MENU_LICENSE_ACCOUNT_UNASSIGNED = 12;
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$ColAuto = 13;
var rundis$elm_bootstrap$Bootstrap$Grid$Col$xsAuto = A2(rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 0, 13);
var rundis$elm_bootstrap$Bootstrap$General$Internal$Right = 2;
var rundis$elm_bootstrap$Bootstrap$General$Internal$HAlign = F2(
	function (screenSize, align) {
		return {cL: align, dw: screenSize};
	});
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$RowHAlign = function (a) {
	return {$: 1, a: a};
};
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$rowHAlign = F2(
	function (size, align) {
		return rundis$elm_bootstrap$Bootstrap$Grid$Internal$RowHAlign(
			A2(rundis$elm_bootstrap$Bootstrap$General$Internal$HAlign, size, align));
	});
var rundis$elm_bootstrap$Bootstrap$Grid$Row$rightXs = A2(rundis$elm_bootstrap$Bootstrap$Grid$Internal$rowHAlign, 0, 2);
var rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt2 = elm$html$Html$Attributes$class('mt-2');
var rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$py2 = elm$html$Html$Attributes$class('py-2');
var author$project$Main$viewUserBanner = function (sharedState) {
	var licenseAccountText = function () {
		var _n0 = sharedState.aK.db;
		if (!_n0.$) {
			return A2(author$project$Language$t, sharedState, 12);
		} else {
			var name = _n0.a;
			return name;
		}
	}();
	return _List_fromArray(
		[
			A2(
			rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[
					rundis$elm_bootstrap$Bootstrap$Grid$Row$rightXs,
					rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
					_List_fromArray(
						[
							A2(elm$html$Html$Attributes$style, 'font-size', '0.8rem'),
							A2(elm$html$Html$Attributes$style, 'background-color', '#f5f5f5'),
							A2(elm$html$Html$Attributes$style, 'margin-right', '0'),
							A2(elm$html$Html$Attributes$style, 'margin-left', '0'),
							elm$html$Html$Attributes$class('d-none d-md-flex'),
							rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt2
						]))
				]),
			_List_fromArray(
				[
					A2(
					rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_fromArray(
						[
							rundis$elm_bootstrap$Bootstrap$Grid$Col$xsAuto,
							rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs(
							_List_fromArray(
								[rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$py2]))
						]),
					_List_fromArray(
						[
							A2(
							elm$html$Html$span,
							_List_fromArray(
								[
									elm$html$Html$Attributes$class('fa fa-user'),
									rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mr2
								]),
							_List_Nil),
							elm$html$Html$text(sharedState.aK.Q)
						])),
					A2(
					rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_fromArray(
						[
							rundis$elm_bootstrap$Bootstrap$Grid$Col$xsAuto,
							rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs(
							_List_fromArray(
								[rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$py2]))
						]),
					_List_fromArray(
						[
							A2(
							elm$html$Html$span,
							_List_fromArray(
								[
									elm$html$Html$Attributes$class('fa fa-file-contract'),
									rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mr2
								]),
							_List_Nil),
							elm$html$Html$text(licenseAccountText)
						]))
				]))
		]);
};
var rundis$elm_bootstrap$Bootstrap$General$Internal$MD = 2;
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$Col4 = 4;
var rundis$elm_bootstrap$Bootstrap$Grid$Col$md4 = A2(rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 2, 4);
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$Col8 = 8;
var rundis$elm_bootstrap$Bootstrap$Grid$Col$md8 = A2(rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 2, 8);
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$TextAlign = function (a) {
	return {$: 7, a: a};
};
var rundis$elm_bootstrap$Bootstrap$Grid$Col$textAlign = function (align) {
	return rundis$elm_bootstrap$Bootstrap$Grid$Internal$TextAlign(align);
};
var rundis$elm_bootstrap$Bootstrap$Grid$Col$xs = A2(rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 0, 0);
var rundis$elm_bootstrap$Bootstrap$Internal$Text$Right = 2;
var rundis$elm_bootstrap$Bootstrap$Text$alignMd = function (dir) {
	return {cU: dir, cz: 2};
};
var rundis$elm_bootstrap$Bootstrap$Text$alignMdRight = rundis$elm_bootstrap$Bootstrap$Text$alignMd(2);
var rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$p0 = elm$html$Html$Attributes$class('p-0');
var rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pl0 = elm$html$Html$Attributes$class('pl-0');
var author$project$Main$viewNavbar = function (model) {
	var userBanner = function () {
		var _n1 = author$project$Main$getSharedState(model);
		if (!_n1.$) {
			var sharedState = _n1.a;
			return author$project$Main$viewUserBanner(sharedState);
		} else {
			return _List_Nil;
		}
	}();
	var translateLink = function () {
		var _n0 = author$project$Main$getSharedState(model);
		if (!_n0.$) {
			var sharedState = _n0.a;
			return author$project$Main$viewTranslateLink(sharedState);
		} else {
			return _List_Nil;
		}
	}();
	var backToDashboardText = A2(
		elm$core$Maybe$withDefault,
		elm$html$Html$text('Back to Dashboard'),
		A2(
			elm$core$Maybe$map,
			function (sharedState) {
				return A2(author$project$Language$tSpan, sharedState, 9);
			},
			author$project$Main$getSharedState(model)));
	return A2(
		author$project$Widgets$simpleRow,
		_List_fromArray(
			[
				A2(elm$html$Html$Attributes$style, 'min-height', '100px')
			]),
		_Utils_ap(
			_List_fromArray(
				[
					A2(
					rundis$elm_bootstrap$Bootstrap$Grid$row,
					_List_fromArray(
						[
							rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(_List_Nil)
						]),
					_List_fromArray(
						[
							A2(
							rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_fromArray(
								[rundis$elm_bootstrap$Bootstrap$Grid$Col$xs, rundis$elm_bootstrap$Bootstrap$Grid$Col$md4]),
							_List_fromArray(
								[
									A2(
									elm$html$Html$img,
									_List_fromArray(
										[
											elm$html$Html$Attributes$src(model.ep + '/images/tomra_plus_logo_rev4.png')
										]),
									_List_Nil),
									A2(
									elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											elm$html$Html$text('Promotion Wizard (beta)')
										]))
								])),
							A2(
							rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_fromArray(
								[
									rundis$elm_bootstrap$Bootstrap$Grid$Col$xs,
									rundis$elm_bootstrap$Bootstrap$Grid$Col$md8,
									rundis$elm_bootstrap$Bootstrap$Grid$Col$textAlign(rundis$elm_bootstrap$Bootstrap$Text$alignMdRight),
									rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs(
									_List_fromArray(
										[rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pl0]))
								]),
							_Utils_ap(
								translateLink,
								_List_fromArray(
									[
										A2(
										rundis$elm_bootstrap$Bootstrap$Button$linkButton,
										_List_fromArray(
											[
												rundis$elm_bootstrap$Bootstrap$Button$attrs(
												_List_fromArray(
													[
														elm$html$Html$Attributes$href(model.dP + '/page/promotion/dashboard'),
														rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$p0
													]))
											]),
										_List_fromArray(
											[backToDashboardText]))
									])))
						]))
				]),
			userBanner));
};
var author$project$Language$BTN_CLOSE = 8;
var author$project$Language$RESET_ERROR_DETAILS = 61;
var author$project$Language$RESET_ERROR_HEADING = 60;
var author$project$PromotionWizard$CloseResetFailedModalRequested = {$: 2};
var rundis$elm_bootstrap$Bootstrap$Modal$Body = elm$core$Basics$identity;
var rundis$elm_bootstrap$Bootstrap$Modal$Config = elm$core$Basics$identity;
var rundis$elm_bootstrap$Bootstrap$Modal$body = F3(
	function (attributes, children, _n0) {
		var conf = _n0;
		return _Utils_update(
			conf,
			{
				cb: elm$core$Maybe$Just(
					{a$: attributes, cQ: children})
			});
	});
var rundis$elm_bootstrap$Bootstrap$Modal$config = function (closeMsg) {
	return {
		cb: elm$core$Maybe$Nothing,
		ao: closeMsg,
		cf: elm$core$Maybe$Nothing,
		bh: elm$core$Maybe$Nothing,
		cr: {cc: true, ar: true, az: elm$core$Maybe$Nothing},
		U: elm$core$Maybe$Nothing
	};
};
var rundis$elm_bootstrap$Bootstrap$Modal$Footer = elm$core$Basics$identity;
var rundis$elm_bootstrap$Bootstrap$Modal$footer = F3(
	function (attributes, children, _n0) {
		var conf = _n0;
		return _Utils_update(
			conf,
			{
				cf: elm$core$Maybe$Just(
					{a$: attributes, cQ: children})
			});
	});
var rundis$elm_bootstrap$Bootstrap$Modal$Hide = 3;
var rundis$elm_bootstrap$Bootstrap$Modal$hidden = 3;
var rundis$elm_bootstrap$Bootstrap$Modal$Show = 0;
var rundis$elm_bootstrap$Bootstrap$Modal$shown = 0;
var elm$core$Basics$negate = function (n) {
	return -n;
};
var elm$html$Html$Attributes$tabindex = function (n) {
	return A2(
		_VirtualDom_attribute,
		'tabIndex',
		elm$core$String$fromInt(n));
};
var rundis$elm_bootstrap$Bootstrap$Modal$StartClose = 1;
var rundis$elm_bootstrap$Bootstrap$Modal$getCloseMsg = function (config_) {
	var _n0 = config_.U;
	if (!_n0.$) {
		var animationMsg = _n0.a;
		return animationMsg(1);
	} else {
		return config_.ao;
	}
};
var rundis$elm_bootstrap$Bootstrap$Modal$isFade = function (conf) {
	return A2(
		elm$core$Maybe$withDefault,
		false,
		A2(
			elm$core$Maybe$map,
			function (_n0) {
				return true;
			},
			conf.U));
};
var rundis$elm_bootstrap$Bootstrap$Modal$backdrop = F2(
	function (visibility, conf) {
		var attributes = function () {
			switch (visibility) {
				case 0:
					return _Utils_ap(
						_List_fromArray(
							[
								elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('modal-backdrop', true),
										_Utils_Tuple2(
										'fade',
										rundis$elm_bootstrap$Bootstrap$Modal$isFade(conf)),
										_Utils_Tuple2('show', true)
									]))
							]),
						conf.cr.ar ? _List_fromArray(
							[
								elm$html$Html$Events$onClick(
								rundis$elm_bootstrap$Bootstrap$Modal$getCloseMsg(conf))
							]) : _List_Nil);
				case 1:
					return _List_fromArray(
						[
							elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('modal-backdrop', true),
									_Utils_Tuple2('fade', true),
									_Utils_Tuple2('show', true)
								]))
						]);
				case 2:
					return _List_fromArray(
						[
							elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('modal-backdrop', true),
									_Utils_Tuple2('fade', true),
									_Utils_Tuple2('show', false)
								]))
						]);
				default:
					return _List_fromArray(
						[
							elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('modal-backdrop', false),
									_Utils_Tuple2(
									'fade',
									rundis$elm_bootstrap$Bootstrap$Modal$isFade(conf)),
									_Utils_Tuple2('show', false)
								]))
						]);
			}
		}();
		return _List_fromArray(
			[
				A2(elm$html$Html$div, attributes, _List_Nil)
			]);
	});
var rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$className = A2(
	elm$json$Json$Decode$at,
	_List_fromArray(
		['className']),
	elm$json$Json$Decode$string);
var rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$target = function (decoder) {
	return A2(elm$json$Json$Decode$field, 'target', decoder);
};
var rundis$elm_bootstrap$Bootstrap$Modal$containerClickDecoder = function (closeMsg) {
	return A2(
		elm$json$Json$Decode$andThen,
		function (c) {
			return A2(elm$core$String$contains, 'elm-bootstrap-modal', c) ? elm$json$Json$Decode$succeed(closeMsg) : elm$json$Json$Decode$fail('ignoring');
		},
		rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$target(rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$className));
};
var rundis$elm_bootstrap$Bootstrap$Modal$display = F2(
	function (visibility, conf) {
		switch (visibility) {
			case 0:
				return _List_fromArray(
					[
						A2(elm$html$Html$Attributes$style, 'pointer-events', 'none'),
						A2(elm$html$Html$Attributes$style, 'display', 'block'),
						elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('modal', true),
								_Utils_Tuple2(
								'fade',
								rundis$elm_bootstrap$Bootstrap$Modal$isFade(conf)),
								_Utils_Tuple2('show', true)
							]))
					]);
			case 1:
				return _List_fromArray(
					[
						A2(elm$html$Html$Attributes$style, 'pointer-events', 'none'),
						A2(elm$html$Html$Attributes$style, 'display', 'block'),
						elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('modal', true),
								_Utils_Tuple2('fade', true),
								_Utils_Tuple2('show', true)
							]))
					]);
			case 2:
				return _List_fromArray(
					[
						A2(elm$html$Html$Attributes$style, 'pointer-events', 'none'),
						A2(elm$html$Html$Attributes$style, 'display', 'block'),
						elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('modal', true),
								_Utils_Tuple2('fade', true),
								_Utils_Tuple2('show', false)
							])),
						A2(
						elm$html$Html$Events$on,
						'transitionend',
						elm$json$Json$Decode$succeed(conf.ao))
					]);
			default:
				return _List_fromArray(
					[
						A2(elm$html$Html$Attributes$style, 'height', '0px'),
						A2(elm$html$Html$Attributes$style, 'display', 'block'),
						elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('modal', true),
								_Utils_Tuple2(
								'fade',
								rundis$elm_bootstrap$Bootstrap$Modal$isFade(conf)),
								_Utils_Tuple2('show', false)
							]))
					]);
		}
	});
var rundis$elm_bootstrap$Bootstrap$Modal$modalClass = function (size) {
	var _n0 = rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(size);
	if (!_n0.$) {
		var s = _n0.a;
		return _List_fromArray(
			[
				elm$html$Html$Attributes$class('modal-' + s)
			]);
	} else {
		return _List_Nil;
	}
};
var rundis$elm_bootstrap$Bootstrap$Modal$modalAttributes = function (options) {
	return _Utils_ap(
		_List_fromArray(
			[
				elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('modal-dialog', true),
						_Utils_Tuple2('modal-dialog-centered', options.cc)
					])),
				A2(elm$html$Html$Attributes$style, 'pointer-events', 'auto')
			]),
		A2(
			elm$core$Maybe$withDefault,
			_List_Nil,
			A2(elm$core$Maybe$map, rundis$elm_bootstrap$Bootstrap$Modal$modalClass, options.az)));
};
var rundis$elm_bootstrap$Bootstrap$Modal$renderBody = function (maybeBody) {
	if (!maybeBody.$) {
		var cfg = maybeBody.a;
		return elm$core$Maybe$Just(
			A2(
				elm$html$Html$div,
				A2(
					elm$core$List$cons,
					elm$html$Html$Attributes$class('modal-body'),
					cfg.a$),
				cfg.cQ));
	} else {
		return elm$core$Maybe$Nothing;
	}
};
var rundis$elm_bootstrap$Bootstrap$Modal$renderFooter = function (maybeFooter) {
	if (!maybeFooter.$) {
		var cfg = maybeFooter.a;
		return elm$core$Maybe$Just(
			A2(
				elm$html$Html$div,
				A2(
					elm$core$List$cons,
					elm$html$Html$Attributes$class('modal-footer'),
					cfg.a$),
				cfg.cQ));
	} else {
		return elm$core$Maybe$Nothing;
	}
};
var rundis$elm_bootstrap$Bootstrap$Modal$closeButton = function (closeMsg) {
	return A2(
		elm$html$Html$button,
		_List_fromArray(
			[
				elm$html$Html$Attributes$class('close'),
				elm$html$Html$Events$onClick(closeMsg)
			]),
		_List_fromArray(
			[
				elm$html$Html$text('×')
			]));
};
var rundis$elm_bootstrap$Bootstrap$Modal$renderHeader = function (conf_) {
	var _n0 = conf_.bh;
	if (!_n0.$) {
		var cfg = _n0.a;
		return elm$core$Maybe$Just(
			A2(
				elm$html$Html$div,
				A2(
					elm$core$List$cons,
					elm$html$Html$Attributes$class('modal-header'),
					cfg.a$),
				_Utils_ap(
					cfg.cQ,
					_List_fromArray(
						[
							rundis$elm_bootstrap$Bootstrap$Modal$closeButton(
							rundis$elm_bootstrap$Bootstrap$Modal$getCloseMsg(conf_))
						]))));
	} else {
		return elm$core$Maybe$Nothing;
	}
};
var rundis$elm_bootstrap$Bootstrap$Modal$view = F2(
	function (visibility, _n0) {
		var conf = _n0;
		return A2(
			elm$html$Html$div,
			_List_Nil,
			_Utils_ap(
				_List_fromArray(
					[
						A2(
						elm$html$Html$div,
						_Utils_ap(
							_List_fromArray(
								[
									elm$html$Html$Attributes$tabindex(-1)
								]),
							A2(rundis$elm_bootstrap$Bootstrap$Modal$display, visibility, conf)),
						_List_fromArray(
							[
								A2(
								elm$html$Html$div,
								_Utils_ap(
									_List_fromArray(
										[
											A2(elm$html$Html$Attributes$attribute, 'role', 'document'),
											elm$html$Html$Attributes$class('elm-bootstrap-modal')
										]),
									_Utils_ap(
										rundis$elm_bootstrap$Bootstrap$Modal$modalAttributes(conf.cr),
										conf.cr.ar ? _List_fromArray(
											[
												A2(
												elm$html$Html$Events$on,
												'click',
												rundis$elm_bootstrap$Bootstrap$Modal$containerClickDecoder(conf.ao))
											]) : _List_Nil)),
								_List_fromArray(
									[
										A2(
										elm$html$Html$div,
										_List_fromArray(
											[
												elm$html$Html$Attributes$class('modal-content')
											]),
										A2(
											elm$core$List$filterMap,
											elm$core$Basics$identity,
											_List_fromArray(
												[
													rundis$elm_bootstrap$Bootstrap$Modal$renderHeader(conf),
													rundis$elm_bootstrap$Bootstrap$Modal$renderBody(conf.cb),
													rundis$elm_bootstrap$Bootstrap$Modal$renderFooter(conf.cf)
												])))
									]))
							]))
					]),
				A2(rundis$elm_bootstrap$Bootstrap$Modal$backdrop, visibility, conf)));
	});
var author$project$PromotionWizard$showResetFailedModal = F2(
	function (viewCtx, model) {
		var tSpan = viewCtx.cF;
		var trSpan = viewCtx.dJ;
		var modalConfig = rundis$elm_bootstrap$Bootstrap$Modal$config(author$project$PromotionWizard$CloseResetFailedModalRequested);
		var _n0 = model.S;
		if (_n0.$ === 2) {
			var err = _n0.a;
			return A2(
				rundis$elm_bootstrap$Bootstrap$Modal$view,
				rundis$elm_bootstrap$Bootstrap$Modal$shown,
				A3(
					rundis$elm_bootstrap$Bootstrap$Modal$footer,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							elm$html$Html$button,
							_List_fromArray(
								[
									elm$html$Html$Attributes$class('btn btn-primary'),
									elm$html$Html$Events$onClick(author$project$PromotionWizard$CloseResetFailedModalRequested)
								]),
							_List_fromArray(
								[
									tSpan(8)
								]))
						]),
					A3(
						rundis$elm_bootstrap$Bootstrap$Modal$body,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								elm$html$Html$h2,
								_List_Nil,
								_List_fromArray(
									[
										tSpan(60)
									])),
								A2(
								rundis$elm_bootstrap$Bootstrap$Alert$simpleDanger,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										trSpan,
										61,
										_List_fromArray(
											[
												author$project$Language$ErrorDetails(
												author$project$Endpoint$errorToString(err))
											]))
									]))
							]),
						modalConfig)));
		} else {
			return A2(
				rundis$elm_bootstrap$Bootstrap$Modal$view,
				rundis$elm_bootstrap$Bootstrap$Modal$hidden,
				A3(rundis$elm_bootstrap$Bootstrap$Modal$body, _List_Nil, _List_Nil, modalConfig));
		}
	});
var author$project$Language$UPLOADING_ERROR_DETAILS = 59;
var author$project$Language$UPLOADING_ERROR_HEADING = 58;
var author$project$Language$UPLOADING_HEADING = 57;
var author$project$PromotionWizard$CloseUploadModalRequested = {$: 1};
var author$project$PromotionWizard$showUploadModal = F2(
	function (viewCtx, model) {
		var tSpan = viewCtx.cF;
		var trSpan = viewCtx.dJ;
		var modalConfig = rundis$elm_bootstrap$Bootstrap$Modal$config(author$project$PromotionWizard$CloseUploadModalRequested);
		var _n0 = model.H;
		switch (_n0.$) {
			case 1:
				return A2(
					rundis$elm_bootstrap$Bootstrap$Modal$view,
					rundis$elm_bootstrap$Bootstrap$Modal$shown,
					A3(
						rundis$elm_bootstrap$Bootstrap$Modal$body,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								elm$html$Html$h2,
								_List_Nil,
								_List_fromArray(
									[
										tSpan(57)
									])),
								A2(
								elm$html$Html$div,
								_List_fromArray(
									[
										A2(elm$html$Html$Attributes$style, 'display', 'flex'),
										A2(elm$html$Html$Attributes$style, 'justify-content', 'center')
									]),
								_List_fromArray(
									[
										A2(author$project$Widgets$spinnerImg, viewCtx.ep, _List_Nil)
									]))
							]),
						modalConfig));
			case 2:
				var err = _n0.a;
				return A2(
					rundis$elm_bootstrap$Bootstrap$Modal$view,
					rundis$elm_bootstrap$Bootstrap$Modal$shown,
					A3(
						rundis$elm_bootstrap$Bootstrap$Modal$footer,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								elm$html$Html$button,
								_List_fromArray(
									[
										elm$html$Html$Attributes$class('btn btn-primary'),
										elm$html$Html$Events$onClick(author$project$PromotionWizard$CloseUploadModalRequested)
									]),
								_List_fromArray(
									[
										tSpan(8)
									]))
							]),
						A3(
							rundis$elm_bootstrap$Bootstrap$Modal$body,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									elm$html$Html$h2,
									_List_Nil,
									_List_fromArray(
										[
											tSpan(58)
										])),
									A2(
									rundis$elm_bootstrap$Bootstrap$Alert$simpleDanger,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											trSpan,
											59,
											_List_fromArray(
												[
													author$project$Language$ErrorDetails(
													author$project$Endpoint$errorToString(err))
												]))
										]))
								]),
							modalConfig)));
			default:
				return A2(
					rundis$elm_bootstrap$Bootstrap$Modal$view,
					rundis$elm_bootstrap$Bootstrap$Modal$hidden,
					A3(rundis$elm_bootstrap$Bootstrap$Modal$body, _List_Nil, _List_Nil, modalConfig));
		}
	});
var author$project$Language$PROMOTION_IDLE_INGRESS = 53;
var author$project$PromotionWizard$IdleStep = 0;
var author$project$Language$EXTRA_LARGE_TOUCH_DOWNLOAD_IDLE_RULES = 38;
var author$project$Language$PROMOTION_MEDIA_ACCOUNT = 48;
var author$project$Language$PROMOTION_MEDIA_IDLE = 42;
var author$project$PromotionWizard$getResourceBy = F3(
	function (screenType, screenResource, tpl) {
		return elm$core$List$head(
			A2(
				elm$core$List$filter,
				function (resource) {
					return _Utils_eq(resource.Q, screenResource);
				},
				A2(
					elm$core$Maybe$withDefault,
					_List_Nil,
					A2(pzp1997$assoc_list$AssocList$get, screenType, tpl.em))));
	});
var rundis$elm_bootstrap$Bootstrap$General$Internal$LG = 3;
var rundis$elm_bootstrap$Bootstrap$Grid$Internal$Col6 = 6;
var rundis$elm_bootstrap$Bootstrap$Grid$Col$lg6 = A2(rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 3, 6);
var author$project$PromotionWizard$screenLeftCol = function (children) {
	return A2(
		rundis$elm_bootstrap$Bootstrap$Grid$col,
		_List_fromArray(
			[rundis$elm_bootstrap$Bootstrap$Grid$Col$lg6]),
		children);
};
var rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt0Lg = elm$html$Html$Attributes$class('mt-lg-0');
var rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt0Xl = elm$html$Html$Attributes$class('mt-xl-0');
var author$project$PromotionWizard$screenRightCol = function (children) {
	return A2(
		rundis$elm_bootstrap$Bootstrap$Grid$col,
		_List_fromArray(
			[
				rundis$elm_bootstrap$Bootstrap$Grid$Col$lg6,
				rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs(
				_List_fromArray(
					[rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt2, rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt0Lg, rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt0Xl]))
			]),
		children);
};
var author$project$Language$DOUBLE_TOUCH_HEADING = 32;
var author$project$Language$EXTRA_LARGE_TOUCH_HEADING = 36;
var author$project$Language$LARGE_TOUCH_HEADING = 30;
var author$project$Language$SMALL_COLOR_HEADING = 28;
var author$project$Language$UNO_HEADING = 34;
var author$project$Language$DOUBLE_TOUCH_EXTRA = 33;
var author$project$Language$EXTRA_LARGE_TOUCH_EXTRA = 37;
var author$project$Language$LARGE_TOUCH_EXTRA = 31;
var author$project$Language$SMALL_COLOR_EXTRA = 29;
var author$project$Language$UNO_EXTRA = 35;
var author$project$Machine$getByScreenType = function (screenType) {
	return A2(
		elm$core$List$filter,
		function (m) {
			return _Utils_eq(m.dx, screenType);
		},
		author$project$Machine$machines);
};
var author$project$PromotionWizard$PopoverMsg = F2(
	function (a, b) {
		return {$: 13, a: a, b: b};
	});
var elm$core$List$intersperse = F2(
	function (sep, xs) {
		if (!xs.b) {
			return _List_Nil;
		} else {
			var hd = xs.a;
			var tl = xs.b;
			var step = F2(
				function (x, rest) {
					return A2(
						elm$core$List$cons,
						sep,
						A2(elm$core$List$cons, x, rest));
				});
			var spersed = A3(elm$core$List$foldr, step, _List_Nil, tl);
			return A2(elm$core$List$cons, hd, spersed);
		}
	});
var elm$core$Maybe$map2 = F3(
	function (func, ma, mb) {
		if (ma.$ === 1) {
			return elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				return elm$core$Maybe$Just(
					A2(func, a, b));
			}
		}
	});
var elm$html$Html$h6 = _VirtualDom_node('h6');
var rundis$elm_bootstrap$Bootstrap$Popover$Config = elm$core$Basics$identity;
var rundis$elm_bootstrap$Bootstrap$Popover$Top = 0;
var rundis$elm_bootstrap$Bootstrap$Popover$config = function (triggerElement) {
	return {dT: elm$core$Maybe$Nothing, C: 0, ew: elm$core$Maybe$Nothing, dM: triggerElement};
};
var rundis$elm_bootstrap$Bootstrap$Popover$Content = elm$core$Basics$identity;
var rundis$elm_bootstrap$Bootstrap$Popover$content = F3(
	function (attributes, children, _n0) {
		var conf = _n0;
		return _Utils_update(
			conf,
			{
				dT: elm$core$Maybe$Just(
					A2(
						elm$html$Html$div,
						A2(
							elm$core$List$cons,
							elm$html$Html$Attributes$class('popover-body'),
							attributes),
						children))
			});
	});
var rundis$elm_bootstrap$Bootstrap$Popover$forceClose = F2(
	function (_n0, toMsg) {
		var state = _n0;
		return elm$json$Json$Decode$succeed(
			toMsg(
				_Utils_update(
					state,
					{ab: false})));
	});
var rundis$elm_bootstrap$Bootstrap$Popover$DOMState = F3(
	function (rect, offsetWidth, offsetHeight) {
		return {cp: offsetHeight, bs: offsetWidth, cx: rect};
	});
var rundis$elm_bootstrap$Bootstrap$Popover$isPopover = A2(
	elm$json$Json$Decode$andThen,
	function (_class) {
		return A2(elm$core$String$contains, 'popover', _class) ? elm$json$Json$Decode$succeed(true) : elm$json$Json$Decode$succeed(false);
	},
	rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$className);
var rundis$elm_bootstrap$Bootstrap$Popover$popper = F2(
	function (path, decoder) {
		return elm$json$Json$Decode$oneOf(
			_List_fromArray(
				[
					A2(
					elm$json$Json$Decode$andThen,
					function (res) {
						return res ? A2(
							elm$json$Json$Decode$at,
							_Utils_ap(
								path,
								_List_fromArray(
									['nextSibling'])),
							decoder) : elm$json$Json$Decode$fail('');
					},
					A2(
						elm$json$Json$Decode$at,
						_Utils_ap(
							path,
							_List_fromArray(
								['nextSibling'])),
						rundis$elm_bootstrap$Bootstrap$Popover$isPopover)),
					A2(
					elm$json$Json$Decode$andThen,
					function (_n0) {
						return A2(
							rundis$elm_bootstrap$Bootstrap$Popover$popper,
							_Utils_ap(
								path,
								_List_fromArray(
									['parentElement'])),
							decoder);
					},
					A2(
						elm$json$Json$Decode$at,
						_Utils_ap(
							path,
							_List_fromArray(
								['parentElement'])),
						rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$className)),
					elm$json$Json$Decode$fail('No popover found')
				]));
	});
var rundis$elm_bootstrap$Bootstrap$Popover$isTrigger = A2(
	elm$json$Json$Decode$andThen,
	function (_class) {
		return A2(elm$core$String$contains, 'popover-trigger', _class) ? elm$json$Json$Decode$succeed(true) : elm$json$Json$Decode$succeed(false);
	},
	rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$className);
var elm$json$Json$Decode$float = _Json_decodeFloat;
var rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetHeight = A2(elm$json$Json$Decode$field, 'offsetHeight', elm$json$Json$Decode$float);
var rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetWidth = A2(elm$json$Json$Decode$field, 'offsetWidth', elm$json$Json$Decode$float);
var elm$json$Json$Decode$map4 = _Json_map4;
var rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetLeft = A2(elm$json$Json$Decode$field, 'offsetLeft', elm$json$Json$Decode$float);
var rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetParent = F2(
	function (x, decoder) {
		return elm$json$Json$Decode$oneOf(
			_List_fromArray(
				[
					A2(
					elm$json$Json$Decode$field,
					'offsetParent',
					elm$json$Json$Decode$null(x)),
					A2(elm$json$Json$Decode$field, 'offsetParent', decoder)
				]));
	});
var rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetTop = A2(elm$json$Json$Decode$field, 'offsetTop', elm$json$Json$Decode$float);
var rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$scrollLeft = A2(elm$json$Json$Decode$field, 'scrollLeft', elm$json$Json$Decode$float);
var rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$scrollTop = A2(elm$json$Json$Decode$field, 'scrollTop', elm$json$Json$Decode$float);
var rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$position = F2(
	function (x, y) {
		return A2(
			elm$json$Json$Decode$andThen,
			function (_n0) {
				var x_ = _n0.a;
				var y_ = _n0.b;
				return A2(
					rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetParent,
					_Utils_Tuple2(x_, y_),
					A2(rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$position, x_, y_));
			},
			A5(
				elm$json$Json$Decode$map4,
				F4(
					function (scrollLeft_, scrollTop_, offsetLeft_, offsetTop_) {
						return _Utils_Tuple2((x + offsetLeft_) - scrollLeft_, (y + offsetTop_) - scrollTop_);
					}),
				rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$scrollLeft,
				rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$scrollTop,
				rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetLeft,
				rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetTop));
	});
var rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$boundingArea = A4(
	elm$json$Json$Decode$map3,
	F3(
		function (_n0, width, height) {
			var x = _n0.a;
			var y = _n0.b;
			return {d$: height, N: x, aH: y, ak: width};
		}),
	A2(rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$position, 0, 0),
	rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetWidth,
	rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetHeight);
var rundis$elm_bootstrap$Bootstrap$Popover$trigger = function (path) {
	return elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				elm$json$Json$Decode$andThen,
				function (res) {
					return res ? A2(elm$json$Json$Decode$at, path, rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$boundingArea) : elm$json$Json$Decode$fail('');
				},
				A2(elm$json$Json$Decode$at, path, rundis$elm_bootstrap$Bootstrap$Popover$isTrigger)),
				A2(
				elm$json$Json$Decode$andThen,
				function (_n0) {
					return rundis$elm_bootstrap$Bootstrap$Popover$trigger(
						_Utils_ap(
							path,
							_List_fromArray(
								['parentElement'])));
				},
				A2(
					elm$json$Json$Decode$at,
					_Utils_ap(
						path,
						_List_fromArray(
							['parentElement'])),
					rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$className)),
				elm$json$Json$Decode$fail('No trigger found')
			]));
};
var rundis$elm_bootstrap$Bootstrap$Popover$stateDecoder = A4(
	elm$json$Json$Decode$map3,
	rundis$elm_bootstrap$Bootstrap$Popover$DOMState,
	rundis$elm_bootstrap$Bootstrap$Popover$trigger(
		_List_fromArray(
			['target'])),
	A2(
		rundis$elm_bootstrap$Bootstrap$Popover$popper,
		_List_fromArray(
			['target']),
		rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetWidth),
	A2(
		rundis$elm_bootstrap$Bootstrap$Popover$popper,
		_List_fromArray(
			['target']),
		rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetHeight));
var rundis$elm_bootstrap$Bootstrap$Popover$toggleState = F2(
	function (_n0, toMsg) {
		var state = _n0;
		var isActive = state.ab;
		return A2(
			elm$json$Json$Decode$andThen,
			function (v) {
				return elm$json$Json$Decode$succeed(
					toMsg(
						(!isActive) ? {ce: v, ab: true} : _Utils_update(
							state,
							{ab: false})));
			},
			rundis$elm_bootstrap$Bootstrap$Popover$stateDecoder);
	});
var rundis$elm_bootstrap$Bootstrap$Popover$onHover = F2(
	function (state, toMsg) {
		return _List_fromArray(
			[
				elm$html$Html$Attributes$class('popover-trigger'),
				A2(
				elm$html$Html$Events$on,
				'mouseenter',
				A2(rundis$elm_bootstrap$Bootstrap$Popover$toggleState, state, toMsg)),
				A2(
				elm$html$Html$Events$on,
				'mouseleave',
				A2(rundis$elm_bootstrap$Bootstrap$Popover$forceClose, state, toMsg))
			]);
	});
var rundis$elm_bootstrap$Bootstrap$Popover$Right = 1;
var rundis$elm_bootstrap$Bootstrap$Popover$right = function (_n0) {
	var conf = _n0;
	return _Utils_update(
		conf,
		{C: 1});
};
var elm$core$String$fromFloat = _String_fromNumber;
var rundis$elm_bootstrap$Bootstrap$Popover$calculatePos = F2(
	function (pos, _n0) {
		var rect = _n0.cx;
		var offsetWidth = _n0.bs;
		var offsetHeight = _n0.cp;
		switch (pos) {
			case 3:
				return {
					X: elm$core$Maybe$Nothing,
					Y: elm$core$Maybe$Just((offsetHeight / 2) - 12),
					N: (-offsetWidth) - 10,
					aH: (rect.d$ / 2) - (offsetHeight / 2)
				};
			case 1:
				return {
					X: elm$core$Maybe$Nothing,
					Y: elm$core$Maybe$Just((offsetHeight / 2) - 12),
					N: rect.ak,
					aH: (rect.d$ / 2) - (offsetHeight / 2)
				};
			case 0:
				return {
					X: elm$core$Maybe$Just((offsetWidth / 2) - 12),
					Y: elm$core$Maybe$Nothing,
					N: (rect.ak / 2) - (offsetWidth / 2),
					aH: (-offsetHeight) - 10
				};
			default:
				return {
					X: elm$core$Maybe$Just((offsetWidth / 2) - 12),
					Y: elm$core$Maybe$Nothing,
					N: (rect.ak / 2) - (offsetWidth / 2),
					aH: rect.d$
				};
		}
	});
var rundis$elm_bootstrap$Bootstrap$Popover$directionAttr = function (position) {
	return A2(
		elm$html$Html$Attributes$attribute,
		'x-placement',
		function () {
			switch (position) {
				case 3:
					return 'left';
				case 1:
					return 'right';
				case 0:
					return 'top';
				default:
					return 'bottom';
			}
		}());
};
var rundis$elm_bootstrap$Bootstrap$Popover$positionClass = function (position) {
	switch (position) {
		case 3:
			return _Utils_Tuple2('bs-popover-left', true);
		case 1:
			return _Utils_Tuple2('bs-popover-right', true);
		case 0:
			return _Utils_Tuple2('bs-popover-top', true);
		default:
			return _Utils_Tuple2('bs-popover-bottom', true);
	}
};
var rundis$elm_bootstrap$Bootstrap$Popover$popoverView = F2(
	function (_n0, _n1) {
		var isActive = _n0.ab;
		var domState = _n0.ce;
		var conf = _n1;
		var px = function (f) {
			return elm$core$String$fromFloat(f) + 'px';
		};
		var pos = A2(rundis$elm_bootstrap$Bootstrap$Popover$calculatePos, conf.C, domState);
		var styles = isActive ? _List_fromArray(
			[
				A2(
				elm$html$Html$Attributes$style,
				'left',
				px(pos.N)),
				A2(
				elm$html$Html$Attributes$style,
				'top',
				px(pos.aH)),
				A2(elm$html$Html$Attributes$style, 'display', 'inline-block'),
				A2(
				elm$html$Html$Attributes$style,
				'width',
				px(domState.bs))
			]) : _List_fromArray(
			[
				A2(elm$html$Html$Attributes$style, 'left', '-5000px'),
				A2(elm$html$Html$Attributes$style, 'top', '-5000px')
			]);
		var arrowStyles = A2(
			elm$core$List$filterMap,
			elm$core$Basics$identity,
			_List_fromArray(
				[
					A2(
					elm$core$Maybe$map,
					function (t) {
						return A2(
							elm$html$Html$Attributes$style,
							'top',
							px(t));
					},
					pos.Y),
					A2(
					elm$core$Maybe$map,
					function (l) {
						return A2(
							elm$html$Html$Attributes$style,
							'left',
							px(l));
					},
					pos.X)
				]));
		return A2(
			elm$html$Html$div,
			_Utils_ap(
				_List_fromArray(
					[
						elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('popover', true),
								_Utils_Tuple2('fade', true),
								_Utils_Tuple2('show', isActive),
								rundis$elm_bootstrap$Bootstrap$Popover$positionClass(conf.C)
							])),
						rundis$elm_bootstrap$Bootstrap$Popover$directionAttr(conf.C)
					]),
				styles),
			A2(
				elm$core$List$filterMap,
				elm$core$Basics$identity,
				_List_fromArray(
					[
						elm$core$Maybe$Just(
						A2(
							elm$html$Html$div,
							A2(
								elm$core$List$cons,
								elm$html$Html$Attributes$class('arrow'),
								arrowStyles),
							_List_Nil)),
						A2(
						elm$core$Maybe$map,
						function (_n2) {
							var t = _n2;
							return t;
						},
						conf.ew),
						A2(
						elm$core$Maybe$map,
						function (_n3) {
							var c = _n3;
							return c;
						},
						conf.dT)
					])));
	});
var rundis$elm_bootstrap$Bootstrap$Popover$view = F2(
	function (state, conf) {
		var triggerElement = conf.dM;
		return A2(
			elm$html$Html$div,
			_List_fromArray(
				[
					A2(elm$html$Html$Attributes$style, 'position', 'relative'),
					A2(elm$html$Html$Attributes$style, 'display', 'inline-block')
				]),
			_List_fromArray(
				[
					triggerElement,
					A2(rundis$elm_bootstrap$Bootstrap$Popover$popoverView, state, conf)
				]));
	});
var author$project$PromotionWizard$viewScreenTypeExtra = F3(
	function (viewCtx, popoverStates, screenType) {
		var popoverFor = function (machineType) {
			var currentPopoverState = A2(elm$core$Dict$get, machineType.Q, popoverStates);
			var popoverAndImgFilename = A3(
				elm$core$Maybe$map2,
				F2(
					function (popoverState, filename) {
						return _Utils_Tuple2(popoverState, filename);
					}),
				currentPopoverState,
				machineType.d3);
			if (!popoverAndImgFilename.$) {
				var _n2 = popoverAndImgFilename.a;
				var popoverState = _n2.a;
				var filename = _n2.b;
				return A2(
					rundis$elm_bootstrap$Bootstrap$Popover$view,
					popoverState,
					A3(
						rundis$elm_bootstrap$Bootstrap$Popover$content,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								elm$html$Html$img,
								_List_fromArray(
									[
										elm$html$Html$Attributes$src(viewCtx.ep + ('/images/' + filename))
									]),
								_List_Nil)
							]),
						rundis$elm_bootstrap$Bootstrap$Popover$right(
							rundis$elm_bootstrap$Bootstrap$Popover$config(
								A2(
									elm$html$Html$button,
									_Utils_ap(
										_List_fromArray(
											[
												elm$html$Html$Attributes$type_('button'),
												elm$html$Html$Attributes$class('btn btn-link'),
												A2(elm$html$Html$Attributes$style, 'padding', '0')
											]),
										A2(
											rundis$elm_bootstrap$Bootstrap$Popover$onHover,
											popoverState,
											author$project$PromotionWizard$PopoverMsg(machineType.Q))),
									_List_fromArray(
										[
											elm$html$Html$text(machineType.Q)
										]))))));
			} else {
				return elm$html$Html$text(machineType.Q);
			}
		};
		var machineLinks = A2(
			elm$core$List$intersperse,
			A2(
				elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						elm$html$Html$text(', ')
					])),
			A2(
				elm$core$List$map,
				popoverFor,
				author$project$Machine$getByScreenType(screenType)));
		var extraInfo = viewCtx.cF(
			function () {
				switch (screenType) {
					case 0:
						return 29;
					case 1:
						return 31;
					case 3:
						return 33;
					case 4:
						return 35;
					default:
						return 37;
				}
			}());
		return A2(
			elm$html$Html$h6,
			_List_Nil,
			_Utils_ap(
				_List_fromArray(
					[
						extraInfo,
						A2(
						elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								elm$html$Html$text(' (')
							]))
					]),
				_Utils_ap(
					machineLinks,
					_List_fromArray(
						[
							A2(
							elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									elm$html$Html$text(')')
								]))
						]))));
	});
var elm$html$Html$h4 = _VirtualDom_node('h4');
var author$project$PromotionWizard$screenTypeHeading = F3(
	function (viewCtx, popoverStates, screenType) {
		var tSpan = viewCtx.cF;
		return A2(
			elm$html$Html$h4,
			_List_Nil,
			_List_fromArray(
				[
					function () {
					switch (screenType) {
						case 0:
							return tSpan(28);
						case 1:
							return tSpan(30);
						case 2:
							return tSpan(36);
						case 4:
							return tSpan(34);
						default:
							return tSpan(32);
					}
				}(),
					A3(author$project$PromotionWizard$viewScreenTypeExtra, viewCtx, popoverStates, screenType)
				]));
	});
var author$project$Endpoint$promotionResourceDefault = F3(
	function (_n0, id, resource) {
		var apiBaseUrl = _n0.dP;
		return apiBaseUrl + ('/rest/promotion/' + (author$project$PromotionId$toString(id) + ('/' + (author$project$Screen$screenResourceToString(resource) + '/default'))));
	});
var author$project$PromotionWizard$getResourceUrl = F3(
	function (ctx, id, resource) {
		return author$project$Endpoint$toUrl(
			resource.eA ? A3(author$project$Endpoint$promotionResource, ctx, id, resource.Q) : A3(author$project$Endpoint$promotionResourceDefault, ctx, id, resource.Q)) + ('?id=' + elm$core$String$fromInt(resource.c2));
	});
var author$project$PromotionWizard$viewMultiFeedMachineIllustration = F2(
	function (viewCtx, elementsToDisplayOnItsScreen) {
		return A2(
			elm$html$Html$div,
			_List_fromArray(
				[
					A2(elm$html$Html$Attributes$style, 'background-image', 'url(\'' + (viewCtx.ep + '/images/multifeed_bg.png\')')),
					A2(elm$html$Html$Attributes$style, 'background-repeat', 'no-repeat'),
					A2(elm$html$Html$Attributes$style, 'background-size', 'cover'),
					A2(elm$html$Html$Attributes$style, 'width', '545px'),
					A2(elm$html$Html$Attributes$style, 'height', '402px'),
					A2(elm$html$Html$Attributes$style, 'position', 'relative'),
					A2(elm$html$Html$Attributes$style, 'margin-bottom', '20px')
				]),
			_List_fromArray(
				[
					A2(
					elm$html$Html$div,
					_List_fromArray(
						[
							A2(elm$html$Html$Attributes$style, 'height', '308px'),
							A2(elm$html$Html$Attributes$style, 'width', '173px'),
							A2(elm$html$Html$Attributes$style, 'position', 'absolute'),
							A2(elm$html$Html$Attributes$style, 'overflow', 'hidden'),
							A2(elm$html$Html$Attributes$style, 'top', '25px'),
							A2(elm$html$Html$Attributes$style, 'left', '314px'),
							A2(elm$html$Html$Attributes$style, 'background-color', 'black')
						]),
					elementsToDisplayOnItsScreen)
				]));
	});
var author$project$PromotionWizard$viewAccountPreviewExtraLargeTouch = F3(
	function (viewCtx, promotionId, resource) {
		return A2(
			author$project$PromotionWizard$viewMultiFeedMachineIllustration,
			viewCtx,
			_List_fromArray(
				[
					A2(
					elm$html$Html$img,
					_List_fromArray(
						[
							elm$html$Html$Attributes$src(
							A3(author$project$PromotionWizard$getResourceUrl, viewCtx, promotionId, resource)),
							A2(elm$html$Html$Attributes$style, 'margin-top', '165px')
						]),
					_List_Nil)
				]));
	});
var author$project$PromotionWizard$viewIdlePreviewExtraLargeTouch = F3(
	function (viewCtx, promotionId, resource) {
		return A2(
			author$project$PromotionWizard$viewMultiFeedMachineIllustration,
			viewCtx,
			_List_fromArray(
				[
					A2(
					elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							elm$html$Html$img,
							_List_fromArray(
								[
									elm$html$Html$Attributes$src(
									A3(author$project$PromotionWizard$getResourceUrl, viewCtx, promotionId, resource))
								]),
							_List_Nil),
							A2(
							elm$html$Html$div,
							_List_fromArray(
								[
									A2(elm$html$Html$Attributes$style, 'position', 'absolute'),
									A2(elm$html$Html$Attributes$style, 'top', '0'),
									A2(elm$html$Html$Attributes$style, 'left', '0'),
									A2(elm$html$Html$Attributes$style, 'height', '35px'),
									A2(elm$html$Html$Attributes$style, 'width', '100%'),
									A2(elm$html$Html$Attributes$style, 'background-color', 'black')
								]),
							_List_Nil)
						]))
				]));
	});
var author$project$PromotionWizard$kilobytesOfFile = function (file) {
	var sizeInKb = elm$core$Basics$floor(
		elm$file$File$size(file) / 1024);
	return elm$core$String$fromInt(sizeInKb) + 'kb';
};
var author$project$PromotionWizard$ScreenFileRequested = function (a) {
	return {$: 6, a: a};
};
var author$project$PromotionWizard$ScreenResetRequested = function (a) {
	return {$: 9, a: a};
};
var author$project$PromotionWizard$UploadKey = F3(
	function (promotionId, screenType, screenResource) {
		return {cw: promotionId, bU: screenResource, dx: screenType};
	});
var author$project$Language$BTN_RESET = 7;
var rundis$elm_bootstrap$Bootstrap$Internal$Button$Danger = 5;
var rundis$elm_bootstrap$Bootstrap$Button$danger = rundis$elm_bootstrap$Bootstrap$Internal$Button$Coloring(
	rundis$elm_bootstrap$Bootstrap$Internal$Button$Roled(5));
var rundis$elm_bootstrap$Bootstrap$Button$onClick = function (message) {
	return rundis$elm_bootstrap$Bootstrap$Button$attrs(
		_List_fromArray(
			[
				A2(
				elm$html$Html$Events$preventDefaultOn,
				'click',
				elm$json$Json$Decode$succeed(
					_Utils_Tuple2(message, true)))
			]));
};
var author$project$PromotionWizard$resetButton = F2(
	function (msg, viewCtx) {
		return A2(
			rundis$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					rundis$elm_bootstrap$Bootstrap$Button$danger,
					rundis$elm_bootstrap$Bootstrap$Button$onClick(msg),
					rundis$elm_bootstrap$Bootstrap$Button$attrs(
					_List_fromArray(
						[rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml2]))
				]),
			_List_fromArray(
				[
					viewCtx.cF(7)
				]));
	});
var author$project$Language$BTN_UPLOAD = 6;
var rundis$elm_bootstrap$Bootstrap$Internal$Button$Secondary = 1;
var rundis$elm_bootstrap$Bootstrap$Button$secondary = rundis$elm_bootstrap$Bootstrap$Internal$Button$Coloring(
	rundis$elm_bootstrap$Bootstrap$Internal$Button$Roled(1));
var author$project$PromotionWizard$uploadButton = F2(
	function (msg, viewCtx) {
		return A2(
			rundis$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					rundis$elm_bootstrap$Bootstrap$Button$secondary,
					rundis$elm_bootstrap$Bootstrap$Button$onClick(msg)
				]),
			_List_fromArray(
				[
					viewCtx.cF(6)
				]));
	});
var author$project$PromotionWizard$viewUploadAndResetButtonsFor = F4(
	function (viewCtx, promotion, screenType, resource) {
		return _Utils_ap(
			_List_fromArray(
				[
					A2(
					author$project$PromotionWizard$uploadButton,
					author$project$PromotionWizard$ScreenFileRequested(
						A3(author$project$PromotionWizard$UploadKey, promotion.c2, screenType, resource.Q)),
					viewCtx)
				]),
			resource.eA ? _List_fromArray(
				[
					A2(
					author$project$PromotionWizard$resetButton,
					author$project$PromotionWizard$ScreenResetRequested(
						A3(author$project$PromotionWizard$UploadKey, promotion.c2, screenType, resource.Q)),
					viewCtx)
				]) : _List_Nil);
	});
var author$project$Screen$getScreenResolution = F2(
	function (screenType, resource) {
		var resolution752_423 = elm$core$Maybe$Just('752 x 423');
		var resolution1080_608 = elm$core$Maybe$Just('1080 x 608');
		var resolution1024_1068 = elm$core$Maybe$Just('1024 x 1068');
		var _n0 = _Utils_Tuple2(screenType, resource);
		switch (_n0.a) {
			case 0:
				var _n1 = _n0.a;
				return resolution752_423;
			case 1:
				var _n2 = _n0.a;
				return resolution752_423;
			case 4:
				if (!_n0.b) {
					var _n3 = _n0.a;
					var _n4 = _n0.b;
					return elm$core$Maybe$Just('976 x 549');
				} else {
					var _n5 = _n0.a;
					return resolution752_423;
				}
			case 3:
				switch (_n0.b) {
					case 3:
						var _n6 = _n0.a;
						var _n7 = _n0.b;
						return resolution1024_1068;
					case 4:
						var _n8 = _n0.a;
						var _n9 = _n0.b;
						return resolution752_423;
					case 8:
						var _n10 = _n0.a;
						var _n11 = _n0.b;
						return resolution752_423;
					case 10:
						var _n12 = _n0.a;
						var _n13 = _n0.b;
						return elm$core$Maybe$Just('1080 x 1920');
					default:
						var _n14 = _n0.a;
						return elm$core$Maybe$Nothing;
				}
			default:
				switch (_n0.b) {
					case 11:
						var _n15 = _n0.a;
						var _n16 = _n0.b;
						return elm$core$Maybe$Just('1080 x 1920');
					case 13:
						var _n17 = _n0.a;
						var _n18 = _n0.b;
						return resolution1080_608;
					case 12:
						var _n19 = _n0.a;
						var _n20 = _n0.b;
						return resolution1080_608;
					default:
						var _n21 = _n0.a;
						return elm$core$Maybe$Nothing;
				}
		}
	});
var elm$file$File$name = _File_name;
var rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb4 = elm$html$Html$Attributes$class('mb-4');
var author$project$PromotionWizard$viewUploadRowFor = F5(
	function (viewCtx, screenType, promotion, resource, headingTranslationKey) {
		var uploadedFilenameText = function () {
			var _n0 = resource.eB;
			if (!_n0.$) {
				var file = _n0.a;
				return ' ' + (elm$file$File$name(file) + (' (' + (author$project$PromotionWizard$kilobytesOfFile(file) + ')')));
			} else {
				return '';
			}
		}();
		var maybeResolution = A2(author$project$Screen$getScreenResolution, screenType, resource.Q);
		var resolutionText = A2(
			elm$core$Maybe$withDefault,
			'',
			A2(
				elm$core$Maybe$map,
				function (resolution) {
					return ' (' + (resolution + 'px) ');
				},
				maybeResolution));
		return A2(
			author$project$Widgets$simpleRow,
			_List_fromArray(
				[rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb4]),
			_List_fromArray(
				[
					A2(
					elm$html$Html$h6,
					_List_Nil,
					_List_fromArray(
						[
							viewCtx.cF(headingTranslationKey),
							elm$html$Html$text(resolutionText),
							A2(
							elm$html$Html$i,
							_List_fromArray(
								[rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml2]),
							_List_fromArray(
								[
									elm$html$Html$text(uploadedFilenameText)
								]))
						])),
					A2(
					author$project$Widgets$simpleRow,
					_List_Nil,
					A4(author$project$PromotionWizard$viewUploadAndResetButtonsFor, viewCtx, promotion, screenType, resource))
				]));
	});
var elm$html$Html$p = _VirtualDom_node('p');
var elm$html$Html$Attributes$target = elm$html$Html$Attributes$stringProperty('target');
var author$project$PromotionWizard$viewIdleAndAccountScreenExtraLargeTouch = F3(
	function (viewCtx, popoverStates, promotion) {
		var idle = A3(author$project$PromotionWizard$getResourceBy, 2, 11, promotion);
		var account = A3(author$project$PromotionWizard$getResourceBy, 2, 13, promotion);
		var _n0 = _Utils_Tuple2(idle, account);
		if ((!_n0.a.$) && (!_n0.b.$)) {
			var idleResource = _n0.a.a;
			var accountResource = _n0.b.a;
			return A2(
				elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A3(author$project$PromotionWizard$screenTypeHeading, viewCtx, popoverStates, 2),
						A2(
						rundis$elm_bootstrap$Bootstrap$Grid$row,
						_List_Nil,
						_List_fromArray(
							[
								author$project$PromotionWizard$screenLeftCol(
								_List_fromArray(
									[
										A2(
										elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												elm$html$Html$a,
												_List_fromArray(
													[
														elm$html$Html$Attributes$href('/documents/multifeed-idle-screen-rules-092019.pdf'),
														elm$html$Html$Attributes$target('blank')
													]),
												_List_fromArray(
													[
														A2(
														elm$html$Html$i,
														_List_fromArray(
															[
																elm$html$Html$Attributes$class('fas fa-download')
															]),
														_List_Nil),
														elm$html$Html$text(' '),
														viewCtx.cF(38)
													]))
											])),
										A5(author$project$PromotionWizard$viewUploadRowFor, viewCtx, 2, promotion, idleResource, 42)
									])),
								author$project$PromotionWizard$screenRightCol(
								_List_fromArray(
									[
										A3(author$project$PromotionWizard$viewIdlePreviewExtraLargeTouch, viewCtx, promotion.c2, idleResource)
									]))
							])),
						A2(
						rundis$elm_bootstrap$Bootstrap$Grid$row,
						_List_Nil,
						_List_fromArray(
							[
								author$project$PromotionWizard$screenLeftCol(
								_List_fromArray(
									[
										A5(author$project$PromotionWizard$viewUploadRowFor, viewCtx, 2, promotion, accountResource, 48)
									])),
								author$project$PromotionWizard$screenRightCol(
								_List_fromArray(
									[
										A3(author$project$PromotionWizard$viewAccountPreviewExtraLargeTouch, viewCtx, promotion.c2, accountResource)
									]))
							]))
					]));
		} else {
			return A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('alert alert-error')
					]),
				_List_fromArray(
					[
						elm$html$Html$text('Error in template data, contact support.')
					]));
		}
	});
var author$project$PromotionWizard$viewPreviewColorTouch = F3(
	function (rootUrl, resourceId, url) {
		return A2(
			elm$html$Html$div,
			_List_fromArray(
				[
					A2(elm$html$Html$Attributes$style, 'background-image', 'url(\'' + (rootUrl + '/images/t9_background.jpg\')')),
					A2(elm$html$Html$Attributes$style, 'background-repeat', 'no-repeat'),
					A2(elm$html$Html$Attributes$style, 'background-size', 'cover'),
					A2(elm$html$Html$Attributes$style, 'position', 'relative'),
					A2(elm$html$Html$Attributes$style, 'width', '402px'),
					A2(elm$html$Html$Attributes$style, 'height', '272px'),
					A2(elm$html$Html$Attributes$style, 'border', '1px solid black')
				]),
			_List_fromArray(
				[
					A2(
					elm$html$Html$div,
					_List_fromArray(
						[
							A2(elm$html$Html$Attributes$style, 'position', 'absolute'),
							A2(elm$html$Html$Attributes$style, 'background-image', 'url(\'' + (rootUrl + '/images/take_idle_touch-bg.gif\')')),
							A2(elm$html$Html$Attributes$style, 'background-size', 'cover'),
							A2(elm$html$Html$Attributes$style, 'width', '148px'),
							A2(elm$html$Html$Attributes$style, 'height', '107px'),
							A2(elm$html$Html$Attributes$style, 'top', '76px'),
							A2(elm$html$Html$Attributes$style, 'left', '241px')
						]),
					_List_fromArray(
						[
							A2(
							elm$html$Html$img,
							_List_fromArray(
								[
									elm$html$Html$Attributes$id(
									elm$core$String$fromInt(resourceId)),
									elm$html$Html$Attributes$src(url),
									A2(elm$html$Html$Attributes$style, 'position', 'absolute'),
									A2(elm$html$Html$Attributes$style, 'top', '12px'),
									A2(elm$html$Html$Attributes$style, 'margin', 'auto'),
									A2(elm$html$Html$Attributes$style, 'width', '100%'),
									A2(elm$html$Html$Attributes$style, 'max-width', '128px'),
									A2(elm$html$Html$Attributes$style, 'left', '8px')
								]),
							_List_Nil)
						]))
				]));
	});
var author$project$PromotionWizard$viewIdleScreenColorTouch = F3(
	function (viewCtx, popoverStates, promotion) {
		var idleResource = A3(author$project$PromotionWizard$getResourceBy, 1, 0, promotion);
		if (!idleResource.$) {
			var resource = idleResource.a;
			return A2(
				elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A3(author$project$PromotionWizard$screenTypeHeading, viewCtx, popoverStates, 1),
						A2(
						rundis$elm_bootstrap$Bootstrap$Grid$row,
						_List_Nil,
						_List_fromArray(
							[
								author$project$PromotionWizard$screenLeftCol(
								_List_fromArray(
									[
										A5(author$project$PromotionWizard$viewUploadRowFor, viewCtx, 1, promotion, resource, 42)
									])),
								author$project$PromotionWizard$screenRightCol(
								_List_fromArray(
									[
										A3(
										author$project$PromotionWizard$viewPreviewColorTouch,
										viewCtx.ep,
										resource.c2,
										A3(author$project$PromotionWizard$getResourceUrl, viewCtx, promotion.c2, resource))
									]))
							]))
					]));
		} else {
			return A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('alert alert-error')
					]),
				_List_fromArray(
					[
						elm$html$Html$text('Error in template data, contact support.')
					]));
		}
	});
var author$project$Language$PROMOTION_MEDIA_BACKGROUND = 47;
var author$project$Language$PROMOTION_MEDIA_IDLE_BOTTOM = 44;
var author$project$Language$PROMOTION_MEDIA_IDLE_TOP = 43;
var author$project$PromotionWizard$viewPreviewDoubleTouch = F3(
	function (viewCtx, promotionId, _n0) {
		var top = _n0.aH;
		var bottom = _n0.am;
		var border = _n0.s;
		return A2(
			elm$html$Html$div,
			_List_fromArray(
				[
					A2(elm$html$Html$Attributes$style, 'background-image', 'url(\'' + (viewCtx.ep + '/images/h30-bg.png\')')),
					A2(elm$html$Html$Attributes$style, 'background-repeat', 'no-repeat'),
					A2(elm$html$Html$Attributes$style, 'background-size', 'cover'),
					A2(elm$html$Html$Attributes$style, 'width', '500px'),
					A2(elm$html$Html$Attributes$style, 'height', '332px'),
					A2(elm$html$Html$Attributes$style, 'position', 'relative')
				]),
			_List_fromArray(
				[
					A2(
					elm$html$Html$div,
					_List_fromArray(
						[
							A2(elm$html$Html$Attributes$style, 'height', '272px'),
							A2(elm$html$Html$Attributes$style, 'width', '163px'),
							A2(
							elm$html$Html$Attributes$style,
							'background-image',
							'url(\'' + (A3(author$project$PromotionWizard$getResourceUrl, viewCtx, promotionId, border) + '\')')),
							A2(elm$html$Html$Attributes$style, 'background-repeat', 'repeat'),
							A2(elm$html$Html$Attributes$style, 'position', 'absolute'),
							A2(elm$html$Html$Attributes$style, 'overflow', 'hidden'),
							A2(elm$html$Html$Attributes$style, 'top', '37px'),
							A2(elm$html$Html$Attributes$style, 'left', '66px')
						]),
					_List_fromArray(
						[
							A2(
							elm$html$Html$div,
							_List_fromArray(
								[
									A2(elm$html$Html$Attributes$style, 'margin', 'auto'),
									A2(elm$html$Html$Attributes$style, 'height', '146px'),
									A2(elm$html$Html$Attributes$style, 'width', '156px'),
									A2(elm$html$Html$Attributes$style, 'position', 'relative')
								]),
							_List_fromArray(
								[
									A2(
									elm$html$Html$img,
									_List_fromArray(
										[
											elm$html$Html$Attributes$src(
											A3(author$project$PromotionWizard$getResourceUrl, viewCtx, promotionId, top)),
											A2(elm$html$Html$Attributes$style, 'width', '100%'),
											A2(elm$html$Html$Attributes$style, 'position', 'absolute'),
											A2(elm$html$Html$Attributes$style, 'top', '0'),
											A2(elm$html$Html$Attributes$style, 'bottom', '0'),
											A2(elm$html$Html$Attributes$style, 'margin', 'auto')
										]),
									_List_Nil)
								])),
							A2(
							elm$html$Html$div,
							_List_fromArray(
								[
									A2(elm$html$Html$Attributes$style, 'background-image', 'url(\'' + (viewCtx.ep + '/images/take_idle_touch-bg.gif\')')),
									A2(elm$html$Html$Attributes$style, 'background-repeat', 'no-repeat'),
									A2(elm$html$Html$Attributes$style, 'background-size', 'cover'),
									A2(elm$html$Html$Attributes$style, 'margin-top', '10px'),
									A2(elm$html$Html$Attributes$style, 'height', '120px'),
									A2(elm$html$Html$Attributes$style, 'width', '100%'),
									A2(elm$html$Html$Attributes$style, 'position', 'relative')
								]),
							_List_fromArray(
								[
									A2(
									elm$html$Html$div,
									_List_fromArray(
										[
											A2(elm$html$Html$Attributes$style, 'height', '92px'),
											A2(elm$html$Html$Attributes$style, 'width', '142px'),
											A2(elm$html$Html$Attributes$style, 'left', '10px'),
											A2(elm$html$Html$Attributes$style, 'top', '10px'),
											A2(elm$html$Html$Attributes$style, 'position', 'absolute')
										]),
									_List_fromArray(
										[
											A2(
											elm$html$Html$img,
											_List_fromArray(
												[
													elm$html$Html$Attributes$src(
													A3(author$project$PromotionWizard$getResourceUrl, viewCtx, promotionId, bottom)),
													A2(elm$html$Html$Attributes$style, 'width', '100%')
												]),
											_List_Nil)
										]))
								]))
						]))
				]));
	});
var author$project$PromotionWizard$viewIdleScreenDoubleTouch = F3(
	function (viewCtx, popoverStates, promotion) {
		var idleTop = A3(author$project$PromotionWizard$getResourceBy, 3, 3, promotion);
		var idleBottom = A3(author$project$PromotionWizard$getResourceBy, 3, 4, promotion);
		var idleBorder = A3(author$project$PromotionWizard$getResourceBy, 3, 10, promotion);
		var _n0 = _Utils_Tuple3(idleTop, idleBottom, idleBorder);
		if (((!_n0.a.$) && (!_n0.b.$)) && (!_n0.c.$)) {
			var idleTopResource = _n0.a.a;
			var idleBottomResource = _n0.b.a;
			var idleBorderResource = _n0.c.a;
			return A2(
				elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A3(author$project$PromotionWizard$screenTypeHeading, viewCtx, popoverStates, 3),
						A2(
						rundis$elm_bootstrap$Bootstrap$Grid$row,
						_List_Nil,
						_List_fromArray(
							[
								author$project$PromotionWizard$screenLeftCol(
								_List_fromArray(
									[
										A5(author$project$PromotionWizard$viewUploadRowFor, viewCtx, 3, promotion, idleTopResource, 43),
										A5(author$project$PromotionWizard$viewUploadRowFor, viewCtx, 3, promotion, idleBottomResource, 44),
										A5(author$project$PromotionWizard$viewUploadRowFor, viewCtx, 3, promotion, idleBorderResource, 47)
									])),
								author$project$PromotionWizard$screenRightCol(
								_List_fromArray(
									[
										A3(
										author$project$PromotionWizard$viewPreviewDoubleTouch,
										viewCtx,
										promotion.c2,
										{s: idleBorderResource, am: idleBottomResource, aH: idleTopResource})
									]))
							]))
					]));
		} else {
			return A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('alert alert-error')
					]),
				_List_fromArray(
					[
						elm$html$Html$text('Error in template data, contact support.')
					]));
		}
	});
var author$project$PromotionWizard$viewPreviewSmallTouch = F3(
	function (rootUrl, resourceId, url) {
		return A2(
			elm$html$Html$div,
			_List_fromArray(
				[
					A2(elm$html$Html$Attributes$style, 'background-image', 'url(\'' + (rootUrl + '/images/mk3-machine-bg.jpg\')')),
					A2(elm$html$Html$Attributes$style, 'background-repeat', 'no-repeat'),
					A2(elm$html$Html$Attributes$style, 'width', '402px'),
					A2(elm$html$Html$Attributes$style, 'height', '231px'),
					A2(elm$html$Html$Attributes$style, 'position', 'relative')
				]),
			_List_fromArray(
				[
					A2(
					elm$html$Html$div,
					_List_fromArray(
						[
							A2(elm$html$Html$Attributes$style, 'position', 'absolute'),
							A2(elm$html$Html$Attributes$style, 'background-color', '#ebebeb'),
							A2(elm$html$Html$Attributes$style, 'top', '60px'),
							A2(elm$html$Html$Attributes$style, 'left', '253px'),
							A2(elm$html$Html$Attributes$style, 'width', '90px'),
							A2(elm$html$Html$Attributes$style, 'height', '66px')
						]),
					_List_fromArray(
						[
							A2(
							elm$html$Html$img,
							_List_fromArray(
								[
									elm$html$Html$Attributes$id(
									elm$core$String$fromInt(resourceId)),
									elm$html$Html$Attributes$src(url),
									A2(elm$html$Html$Attributes$style, 'position', 'absolute'),
									A2(elm$html$Html$Attributes$style, 'top', '0'),
									A2(elm$html$Html$Attributes$style, 'bottom', '0'),
									A2(elm$html$Html$Attributes$style, 'margin', 'auto'),
									A2(elm$html$Html$Attributes$style, 'left', '8px'),
									A2(elm$html$Html$Attributes$style, 'width', '100%'),
									A2(elm$html$Html$Attributes$style, 'max-width', '74px'),
									A2(elm$html$Html$Attributes$style, 'max-height', '50px')
								]),
							_List_Nil)
						]))
				]));
	});
var author$project$PromotionWizard$viewIdleScreenSmallColorTouch = F3(
	function (viewCtx, popoverStates, promotion) {
		var idleResource = A3(author$project$PromotionWizard$getResourceBy, 0, 1, promotion);
		if (!idleResource.$) {
			var resource = idleResource.a;
			return A2(
				elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A3(author$project$PromotionWizard$screenTypeHeading, viewCtx, popoverStates, 0),
						A2(
						rundis$elm_bootstrap$Bootstrap$Grid$row,
						_List_Nil,
						_List_fromArray(
							[
								author$project$PromotionWizard$screenLeftCol(
								_List_fromArray(
									[
										A5(author$project$PromotionWizard$viewUploadRowFor, viewCtx, 0, promotion, resource, 42)
									])),
								author$project$PromotionWizard$screenRightCol(
								_List_fromArray(
									[
										A3(
										author$project$PromotionWizard$viewPreviewSmallTouch,
										viewCtx.ep,
										resource.c2,
										A3(author$project$PromotionWizard$getResourceUrl, viewCtx, promotion.c2, resource))
									]))
							]))
					]));
		} else {
			return A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('alert alert-error')
					]),
				_List_fromArray(
					[
						elm$html$Html$text('Error in template data, contact support.')
					]));
		}
	});
var author$project$PromotionWizard$viewPreviewUno = F3(
	function (viewCtx, promotionId, _n0) {
		var mainResource = _n0.ax;
		var border = _n0.s;
		var step = _n0.aD;
		var borderUrl = A3(
			author$project$PromotionWizard$getResourceUrl,
			viewCtx,
			promotionId,
			function () {
				if (!step) {
					return _Utils_update(
						border,
						{eA: false});
				} else {
					return border;
				}
			}());
		return A2(
			elm$html$Html$div,
			_List_fromArray(
				[
					A2(elm$html$Html$Attributes$style, 'padding', '20px'),
					A2(elm$html$Html$Attributes$style, 'background-image', 'url(\'' + (viewCtx.ep + '/images/UnoPromo-bg.jpg\')')),
					A2(elm$html$Html$Attributes$style, 'background-repeat', 'no-repeat'),
					A2(elm$html$Html$Attributes$style, 'width', '480px'),
					A2(elm$html$Html$Attributes$style, 'height', '300px')
				]),
			_List_fromArray(
				[
					A2(
					elm$html$Html$div,
					_List_fromArray(
						[
							A2(elm$html$Html$Attributes$style, 'position', 'relative'),
							A2(elm$html$Html$Attributes$style, 'width', '188px'),
							A2(elm$html$Html$Attributes$style, 'background-repeat', 'repeat'),
							A2(elm$html$Html$Attributes$style, 'height', '140px'),
							A2(elm$html$Html$Attributes$style, 'overflow', 'hidden'),
							A2(elm$html$Html$Attributes$style, 'margin', '50px 0 0 52px'),
							A2(elm$html$Html$Attributes$style, 'background-image', 'url(\'' + (borderUrl + '\')'))
						]),
					_List_fromArray(
						[
							A2(
							elm$html$Html$img,
							_List_fromArray(
								[
									elm$html$Html$Attributes$src(
									A3(author$project$PromotionWizard$getResourceUrl, viewCtx, promotionId, mainResource)),
									A2(elm$html$Html$Attributes$style, 'width', '100%'),
									A2(elm$html$Html$Attributes$style, 'position', 'absolute'),
									A2(elm$html$Html$Attributes$style, 'top', '0'),
									A2(elm$html$Html$Attributes$style, 'bottom', '0'),
									A2(elm$html$Html$Attributes$style, 'margin', 'auto'),
									A2(elm$html$Html$Attributes$style, 'left', '15px'),
									A2(elm$html$Html$Attributes$style, 'max-width', '158px')
								]),
							_List_Nil)
						]))
				]));
	});
var author$project$PromotionWizard$viewIdleScreenUno = F3(
	function (viewCtx, popoverStates, promotion) {
		var tSpan = viewCtx.cF;
		var idleResource = A3(author$project$PromotionWizard$getResourceBy, 4, 2, promotion);
		var borderResource = A3(author$project$PromotionWizard$getResourceBy, 4, 9, promotion);
		var _n0 = _Utils_Tuple2(idleResource, borderResource);
		if ((!_n0.a.$) && (!_n0.b.$)) {
			var idle = _n0.a.a;
			var border = _n0.b.a;
			return A2(
				elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A3(author$project$PromotionWizard$screenTypeHeading, viewCtx, popoverStates, 4),
						A2(
						rundis$elm_bootstrap$Bootstrap$Grid$row,
						_List_Nil,
						_List_fromArray(
							[
								author$project$PromotionWizard$screenLeftCol(
								_List_fromArray(
									[
										A5(author$project$PromotionWizard$viewUploadRowFor, viewCtx, 4, promotion, idle, 42)
									])),
								author$project$PromotionWizard$screenRightCol(
								_List_fromArray(
									[
										A3(
										author$project$PromotionWizard$viewPreviewUno,
										viewCtx,
										promotion.c2,
										{s: border, ax: idle, aD: 0})
									]))
							]))
					]));
		} else {
			return A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('alert alert-error')
					]),
				_List_fromArray(
					[
						elm$html$Html$text('Error in template data, contact support.')
					]));
		}
	});
var author$project$PromotionWizard$viewIdleScreenDetails = F4(
	function (viewCtx, popoverStates, screenType, promotion) {
		switch (screenType) {
			case 0:
				return A3(author$project$PromotionWizard$viewIdleScreenSmallColorTouch, viewCtx, popoverStates, promotion);
			case 1:
				return A3(author$project$PromotionWizard$viewIdleScreenColorTouch, viewCtx, popoverStates, promotion);
			case 2:
				return A3(author$project$PromotionWizard$viewIdleAndAccountScreenExtraLargeTouch, viewCtx, popoverStates, promotion);
			case 3:
				return A3(author$project$PromotionWizard$viewIdleScreenDoubleTouch, viewCtx, popoverStates, promotion);
			default:
				return A3(author$project$PromotionWizard$viewIdleScreenUno, viewCtx, popoverStates, promotion);
		}
	});
var author$project$PromotionWizard$ScreenTypePageSelected = {$: 5};
var author$project$PromotionWizard$WaitScreenSelected = function (a) {
	return {$: 4, a: a};
};
var author$project$Widgets$backBtn = F2(
	function (_n0, options) {
		var tSpan = _n0.cF;
		return A2(
			rundis$elm_bootstrap$Bootstrap$Button$button,
			A2(elm$core$List$cons, rundis$elm_bootstrap$Bootstrap$Button$primary, options),
			_List_fromArray(
				[
					A2(
					elm$html$Html$i,
					_List_fromArray(
						[
							elm$html$Html$Attributes$class('fa fa-angle-left'),
							rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mr2
						]),
					_List_Nil),
					tSpan(1)
				]));
	});
var author$project$PromotionWizard$viewIdleScreenNavButtons = F2(
	function (viewCtx, screenType) {
		return A2(
			author$project$Widgets$simpleRow,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					author$project$Widgets$backBtn,
					viewCtx,
					_List_fromArray(
						[
							rundis$elm_bootstrap$Bootstrap$Button$onClick(author$project$PromotionWizard$ScreenTypePageSelected)
						])),
					A2(
					author$project$Widgets$nextBtn,
					viewCtx,
					_List_fromArray(
						[
							rundis$elm_bootstrap$Bootstrap$Button$onClick(
							author$project$PromotionWizard$WaitScreenSelected(screenType)),
							rundis$elm_bootstrap$Bootstrap$Button$attrs(
							_List_fromArray(
								[rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml2]))
						]))
				]));
	});
var author$project$Language$PROMOTION_IDLE_HEADING = 52;
var author$project$Language$PROMOTION_STEP = 27;
var author$project$Language$PROMOTION_WAIT_HEADING = 55;
var author$project$PromotionWizard$viewWizardHeading = F2(
	function (_n0, step) {
		var tSpan = _n0.cF;
		var trSpan = _n0.dJ;
		var _n1 = function () {
			if (!step) {
				return _Utils_Tuple2(
					_List_Nil,
					_List_fromArray(
						[
							A2(elm$html$Html$Attributes$style, 'opacity', '0.3')
						]));
			} else {
				return _Utils_Tuple2(
					_List_fromArray(
						[
							A2(elm$html$Html$Attributes$style, 'opacity', '0.3')
						]),
					_List_Nil);
			}
		}();
		var step1Styles = _n1.a;
		var step2Styles = _n1.b;
		return A2(
			elm$html$Html$h2,
			_List_fromArray(
				[
					A2(elm$html$Html$Attributes$style, 'display', 'flex'),
					A2(elm$html$Html$Attributes$style, 'align-items', 'center')
				]),
			_List_fromArray(
				[
					A2(
					elm$html$Html$span,
					step1Styles,
					_List_fromArray(
						[
							A2(
							trSpan,
							27,
							_List_fromArray(
								[
									author$project$Language$Number(1)
								])),
							elm$html$Html$text(': '),
							tSpan(52)
						])),
					A2(
					elm$html$Html$span,
					_List_fromArray(
						[
							A2(elm$html$Html$Attributes$style, 'opacity', '0.3'),
							A2(elm$html$Html$Attributes$style, 'padding', '0 10px 0 10px')
						]),
					_List_fromArray(
						[
							A2(
							elm$html$Html$i,
							_List_fromArray(
								[
									elm$html$Html$Attributes$class('fa fa-arrow-right')
								]),
							_List_Nil)
						])),
					A2(
					elm$html$Html$span,
					step2Styles,
					_List_fromArray(
						[
							A2(
							trSpan,
							27,
							_List_fromArray(
								[
									author$project$Language$Number(2)
								])),
							elm$html$Html$text(': '),
							tSpan(55)
						]))
				]));
	});
var rundis$elm_bootstrap$Bootstrap$Grid$Col$xs6 = A2(rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 0, 6);
var author$project$PromotionWizard$viewIdleScreen = F4(
	function (viewCtx, popoverStates, screenType, promotion) {
		var tSpan = viewCtx.cF;
		var tr = viewCtx.ex;
		return A2(
			elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(author$project$PromotionWizard$viewWizardHeading, viewCtx, 0),
					A2(
					elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							tSpan(53)
						])),
					A4(author$project$PromotionWizard$viewIdleScreenDetails, viewCtx, popoverStates, screenType, promotion),
					A2(
					rundis$elm_bootstrap$Bootstrap$Grid$row,
					_List_fromArray(
						[
							rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
							_List_fromArray(
								[
									A2(elm$html$Html$Attributes$style, 'margin-top', '20px')
								]))
						]),
					_List_fromArray(
						[
							A2(
							rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_fromArray(
								[rundis$elm_bootstrap$Bootstrap$Grid$Col$xs6]),
							_List_fromArray(
								[
									A2(author$project$PromotionWizard$viewIdleScreenNavButtons, viewCtx, screenType)
								]))
						]))
				]));
	});
var author$project$Language$PROMOTION_SCREENTYPE_HEADING = 49;
var author$project$Language$PROMOTION_SCREENTYPE_INGRESS = 50;
var author$project$Language$BTN_EDIT = 2;
var author$project$Language$PROMOTION_SCREENTYPE_BTN_PREVIEW = 51;
var author$project$PromotionWizard$IdleScreenSelected = function (a) {
	return {$: 3, a: a};
};
var author$project$Language$PROMOTION_MEDIA_WAIT = 45;
var author$project$Language$PROMOTION_MEDIA_WAIT_BOTTOM = 46;
var author$project$Screen$screenResourceToLanguage = function (resource) {
	switch (resource) {
		case 0:
			return 42;
		case 1:
			return 42;
		case 2:
			return 42;
		case 3:
			return 43;
		case 4:
			return 44;
		case 9:
			return 47;
		case 5:
			return 45;
		case 6:
			return 45;
		case 7:
			return 45;
		case 8:
			return 46;
		case 10:
			return 47;
		case 11:
			return 42;
		case 13:
			return 48;
		default:
			return 45;
	}
};
var author$project$PromotionWizard$viewResourceShortName = F2(
	function (_n0, resource) {
		var tSpan = _n0.cF;
		return tSpan(
			author$project$Screen$screenResourceToLanguage(resource));
	});
var author$project$Screen$resourceSort = function (resource) {
	switch (resource) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 9:
			return 4;
		case 3:
			return 5;
		case 4:
			return 6;
		case 10:
			return 7;
		case 5:
			return 8;
		case 6:
			return 9;
		case 7:
			return 10;
		case 8:
			return 11;
		case 11:
			return 12;
		case 13:
			return 13;
		default:
			return 14;
	}
};
var elm$core$List$sortBy = _List_sortBy;
var rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mr1 = elm$html$Html$Attributes$class('mr-1');
var author$project$PromotionWizard$viewBadges = F3(
	function (viewCtx, promotion, screenType) {
		var badges = A2(
			elm$core$List$sortBy,
			A2(
				elm$core$Basics$composeR,
				function ($) {
					return $.Q;
				},
				author$project$Screen$resourceSort),
			A2(
				elm$core$Maybe$withDefault,
				_List_Nil,
				A2(pzp1997$assoc_list$AssocList$get, screenType, promotion.em)));
		return A2(
			elm$core$List$indexedMap,
			F2(
				function (idx, r) {
					return A2(
						elm$html$Html$span,
						_List_fromArray(
							[
								elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('badge', true),
										_Utils_Tuple2('badge-pill', true),
										_Utils_Tuple2('badge-success', r.eA),
										_Utils_Tuple2('badge-warning', !r.eA),
										_Utils_Tuple2('ml-1', idx > 0)
									]))
							]),
						_List_fromArray(
							[
								A2(
								elm$html$Html$i,
								_List_fromArray(
									[
										elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2('fa', true),
												_Utils_Tuple2('fa-check', r.eA),
												_Utils_Tuple2('fa-ban', !r.eA)
											])),
										rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mr1
									]),
								_List_Nil),
								A2(author$project$PromotionWizard$viewResourceShortName, viewCtx, r.Q)
							]));
				}),
			badges);
	});
var author$project$Language$PROMOTION_ACCOUNT_HEADING = 54;
var author$project$PromotionWizard$WaitStep = 1;
var author$project$PromotionWizard$previewAnimationStyles = F2(
	function (screenType, previewVisibility) {
		var transitionEvent = A2(
			elm$html$Html$Events$on,
			'transitionend',
			elm$json$Json$Decode$succeed(
				author$project$PromotionWizard$PreviewToggled(screenType)));
		var animateStyles = function (height) {
			return _List_fromArray(
				[
					A2(
					elm$html$Html$Attributes$style,
					'height',
					elm$core$String$fromFloat(height) + 'px'),
					A2(elm$html$Html$Attributes$style, '-webkit-transition-timing-function', 'ease'),
					A2(elm$html$Html$Attributes$style, '-o-transition-timing-function', 'ease'),
					A2(elm$html$Html$Attributes$style, 'transition-timing-function', 'ease'),
					A2(elm$html$Html$Attributes$style, '-webkit-transition-duration', '0.35s'),
					A2(elm$html$Html$Attributes$style, '-o-transition-duration', '0.35s'),
					A2(elm$html$Html$Attributes$style, 'transition-duration', '0.35s'),
					A2(elm$html$Html$Attributes$style, '-webkit-transition-property', 'height'),
					A2(elm$html$Html$Attributes$style, '-o-transition-property', 'height'),
					A2(elm$html$Html$Attributes$style, 'transition-property', 'height'),
					transitionEvent
				]);
		};
		switch (previewVisibility.$) {
			case 0:
				return _List_fromArray(
					[
						A2(elm$html$Html$Attributes$style, 'height', '0')
					]);
			case 1:
				return _List_fromArray(
					[
						A2(elm$html$Html$Attributes$style, 'height', '0')
					]);
			case 2:
				var height = previewVisibility.a;
				return animateStyles(height);
			case 5:
				return _List_fromArray(
					[
						A2(elm$html$Html$Attributes$style, 'height', '100%')
					]);
			case 3:
				var height = previewVisibility.a;
				return _List_fromArray(
					[
						A2(
						elm$html$Html$Attributes$style,
						'height',
						elm$core$String$fromFloat(height) + 'px')
					]);
			default:
				return animateStyles(0);
		}
	});
var author$project$PromotionWizard$previewHeading = function (children) {
	return A2(
		elm$html$Html$h6,
		_List_fromArray(
			[
				A2(elm$html$Html$Attributes$style, 'text-align', 'left'),
				A2(elm$html$Html$Attributes$style, 'font-weight', 'bold')
			]),
		children);
};
var author$project$PromotionWizard$viewWaitPreviewExtraLargeTouch = F3(
	function (viewCtx, promotionId, resource) {
		return A2(
			author$project$PromotionWizard$viewMultiFeedMachineIllustration,
			viewCtx,
			_List_fromArray(
				[
					A2(
					elm$html$Html$img,
					_List_fromArray(
						[
							elm$html$Html$Attributes$src(
							A3(author$project$PromotionWizard$getResourceUrl, viewCtx, promotionId, resource)),
							A2(elm$html$Html$Attributes$style, 'margin-top', '95px')
						]),
					_List_Nil)
				]));
	});
var elm$core$Maybe$map3 = F4(
	function (func, ma, mb, mc) {
		if (ma.$ === 1) {
			return elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				if (mc.$ === 1) {
					return elm$core$Maybe$Nothing;
				} else {
					var c = mc.a;
					return elm$core$Maybe$Just(
						A3(func, a, b, c));
				}
			}
		}
	});
var rundis$elm_bootstrap$Bootstrap$General$Internal$Between = 4;
var rundis$elm_bootstrap$Bootstrap$Grid$Row$betweenXs = A2(rundis$elm_bootstrap$Bootstrap$Grid$Internal$rowHAlign, 0, 4);
var author$project$PromotionWizard$viewPreviewScreensFor = F4(
	function (viewCtx, screenType, previewVisibility, promotion) {
		var waitMissing = A2(
			rundis$elm_bootstrap$Bootstrap$Alert$simpleDanger,
			_List_Nil,
			_List_fromArray(
				[
					elm$html$Html$text('Wait screen resource missing')
				]));
		var waitHeading = author$project$PromotionWizard$previewHeading(
			_List_fromArray(
				[
					viewCtx.cF(55)
				]));
		var idleMissing = A2(
			rundis$elm_bootstrap$Bootstrap$Alert$simpleDanger,
			_List_Nil,
			_List_fromArray(
				[
					elm$html$Html$text('Idle screen resource missing')
				]));
		var idleHeading = author$project$PromotionWizard$previewHeading(
			_List_fromArray(
				[
					viewCtx.cF(52)
				]));
		var accountMissing = A2(
			rundis$elm_bootstrap$Bootstrap$Alert$simpleDanger,
			_List_Nil,
			_List_fromArray(
				[
					elm$html$Html$text('Account screen resource missing')
				]));
		var accountHeading = author$project$PromotionWizard$previewHeading(
			_List_fromArray(
				[
					viewCtx.cF(54)
				]));
		var previews = function () {
			switch (screenType) {
				case 0:
					return _List_fromArray(
						[
							_List_fromArray(
							[
								idleHeading,
								A2(
								elm$core$Maybe$withDefault,
								idleMissing,
								A2(
									elm$core$Maybe$map,
									function (r) {
										return A3(
											author$project$PromotionWizard$viewPreviewSmallTouch,
											viewCtx.ep,
											r.c2,
											A3(author$project$PromotionWizard$getResourceUrl, viewCtx, promotion.c2, r));
									},
									A3(author$project$PromotionWizard$getResourceBy, 0, 1, promotion)))
							]),
							_List_fromArray(
							[
								waitHeading,
								A2(
								elm$core$Maybe$withDefault,
								waitMissing,
								A2(
									elm$core$Maybe$map,
									function (r) {
										return A3(
											author$project$PromotionWizard$viewPreviewSmallTouch,
											viewCtx.ep,
											r.c2,
											A3(author$project$PromotionWizard$getResourceUrl, viewCtx, promotion.c2, r));
									},
									A3(author$project$PromotionWizard$getResourceBy, 0, 6, promotion)))
							])
						]);
				case 1:
					return _List_fromArray(
						[
							_List_fromArray(
							[
								idleHeading,
								A2(
								elm$core$Maybe$withDefault,
								idleMissing,
								A2(
									elm$core$Maybe$map,
									function (r) {
										return A3(
											author$project$PromotionWizard$viewPreviewColorTouch,
											viewCtx.ep,
											r.c2,
											A3(author$project$PromotionWizard$getResourceUrl, viewCtx, promotion.c2, r));
									},
									A3(author$project$PromotionWizard$getResourceBy, 1, 0, promotion)))
							]),
							_List_fromArray(
							[
								waitHeading,
								A2(
								elm$core$Maybe$withDefault,
								waitMissing,
								A2(
									elm$core$Maybe$map,
									function (r) {
										return A3(
											author$project$PromotionWizard$viewPreviewColorTouch,
											viewCtx.ep,
											r.c2,
											A3(author$project$PromotionWizard$getResourceUrl, viewCtx, promotion.c2, r));
									},
									A3(author$project$PromotionWizard$getResourceBy, 1, 5, promotion)))
							])
						]);
				case 4:
					return _List_fromArray(
						[
							_List_fromArray(
							[
								idleHeading,
								A2(
								elm$core$Maybe$withDefault,
								idleMissing,
								A3(
									elm$core$Maybe$map2,
									F2(
										function (r, b) {
											return A3(
												author$project$PromotionWizard$viewPreviewUno,
												viewCtx,
												promotion.c2,
												{s: b, ax: r, aD: 0});
										}),
									A3(author$project$PromotionWizard$getResourceBy, 4, 2, promotion),
									A3(author$project$PromotionWizard$getResourceBy, 4, 9, promotion)))
							]),
							_List_fromArray(
							[
								waitHeading,
								A2(
								elm$core$Maybe$withDefault,
								waitMissing,
								A3(
									elm$core$Maybe$map2,
									F2(
										function (r, b) {
											return A3(
												author$project$PromotionWizard$viewPreviewUno,
												viewCtx,
												promotion.c2,
												{s: b, ax: r, aD: 1});
										}),
									A3(author$project$PromotionWizard$getResourceBy, 4, 7, promotion),
									A3(author$project$PromotionWizard$getResourceBy, 4, 9, promotion)))
							])
						]);
				case 3:
					return _List_fromArray(
						[
							_List_fromArray(
							[
								idleHeading,
								A2(
								elm$core$Maybe$withDefault,
								idleMissing,
								A4(
									elm$core$Maybe$map3,
									F3(
										function (top, bottom, border) {
											return A3(
												author$project$PromotionWizard$viewPreviewDoubleTouch,
												viewCtx,
												promotion.c2,
												{s: border, am: bottom, aH: top});
										}),
									A3(author$project$PromotionWizard$getResourceBy, 3, 3, promotion),
									A3(author$project$PromotionWizard$getResourceBy, 3, 4, promotion),
									A3(author$project$PromotionWizard$getResourceBy, 3, 10, promotion)))
							]),
							_List_fromArray(
							[
								waitHeading,
								A2(
								elm$core$Maybe$withDefault,
								waitMissing,
								A4(
									elm$core$Maybe$map3,
									F3(
										function (top, bottom, border) {
											return A3(
												author$project$PromotionWizard$viewPreviewDoubleTouch,
												viewCtx,
												promotion.c2,
												{s: border, am: bottom, aH: top});
										}),
									A3(author$project$PromotionWizard$getResourceBy, 3, 3, promotion),
									A3(author$project$PromotionWizard$getResourceBy, 3, 8, promotion),
									A3(author$project$PromotionWizard$getResourceBy, 3, 10, promotion)))
							])
						]);
				default:
					return _List_fromArray(
						[
							_List_fromArray(
							[
								idleHeading,
								A2(
								elm$core$Maybe$withDefault,
								idleMissing,
								A2(
									elm$core$Maybe$map,
									A2(author$project$PromotionWizard$viewIdlePreviewExtraLargeTouch, viewCtx, promotion.c2),
									A3(author$project$PromotionWizard$getResourceBy, 2, 11, promotion)))
							]),
							_List_fromArray(
							[
								accountHeading,
								A2(
								elm$core$Maybe$withDefault,
								accountMissing,
								A2(
									elm$core$Maybe$map,
									A2(author$project$PromotionWizard$viewAccountPreviewExtraLargeTouch, viewCtx, promotion.c2),
									A3(author$project$PromotionWizard$getResourceBy, 2, 13, promotion)))
							]),
							_List_fromArray(
							[
								waitHeading,
								A2(
								elm$core$Maybe$withDefault,
								waitMissing,
								A2(
									elm$core$Maybe$map,
									A2(author$project$PromotionWizard$viewWaitPreviewExtraLargeTouch, viewCtx, promotion.c2),
									A3(author$project$PromotionWizard$getResourceBy, 2, 12, promotion)))
							])
						]);
			}
		}();
		return A2(
			elm$html$Html$div,
			_Utils_ap(
				_List_fromArray(
					[
						A2(elm$html$Html$Attributes$style, 'position', 'relative'),
						A2(elm$html$Html$Attributes$style, 'overflow', 'hidden')
					]),
				A2(author$project$PromotionWizard$previewAnimationStyles, screenType, previewVisibility)),
			_List_fromArray(
				[
					A2(
					rundis$elm_bootstrap$Bootstrap$Grid$row,
					_List_fromArray(
						[
							rundis$elm_bootstrap$Bootstrap$Grid$Row$betweenXs,
							rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
							_List_fromArray(
								[
									elm$html$Html$Attributes$id(
									author$project$PromotionWizard$previewId(screenType))
								]))
						]),
					A2(
						elm$core$List$map,
						function (p) {
							return A2(
								rundis$elm_bootstrap$Bootstrap$Grid$col,
								_List_fromArray(
									[
										rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs(
										_List_fromArray(
											[rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt2]))
									]),
								p);
						},
						previews))
				]));
	});
var elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var rundis$elm_bootstrap$Bootstrap$Internal$Button$Outlined = function (a) {
	return {$: 1, a: a};
};
var rundis$elm_bootstrap$Bootstrap$Button$outlinePrimary = rundis$elm_bootstrap$Bootstrap$Internal$Button$Coloring(
	rundis$elm_bootstrap$Bootstrap$Internal$Button$Outlined(0));
var author$project$PromotionWizard$viewScreenType = F4(
	function (model, viewCtx, tpl, screenType) {
		var previewType = A2(
			elm$core$Maybe$withDefault,
			author$project$PromotionWizard$Hidden,
			A2(pzp1997$assoc_list$AssocList$get, screenType, model.x));
		var shouldPreview = _Utils_eq(previewType, author$project$PromotionWizard$Shown);
		var hasCustomMedia = A2(
			elm$core$Maybe$withDefault,
			false,
			A2(
				elm$core$Maybe$map,
				function (rs) {
					return A2(
						elm$core$List$any,
						function ($) {
							return $.eA;
						},
						rs);
				},
				A2(pzp1997$assoc_list$AssocList$get, screenType, tpl.em)));
		var previewBtn = hasCustomMedia ? _List_fromArray(
			[
				A2(
				rundis$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						rundis$elm_bootstrap$Bootstrap$Button$outlinePrimary,
						rundis$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mr2])),
						rundis$elm_bootstrap$Bootstrap$Button$onClick(
						author$project$PromotionWizard$PreviewToggled(screenType))
					]),
				_List_fromArray(
					[
						viewCtx.cF(51),
						A2(
						elm$html$Html$i,
						_List_fromArray(
							[
								elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('fa', true),
										_Utils_Tuple2('fa-angle-down', !shouldPreview),
										_Utils_Tuple2('fa-angle-up', shouldPreview)
									])),
								rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml2
							]),
						_List_Nil)
					]))
			]) : _List_Nil;
		return A2(
			author$project$Widgets$simpleRow,
			_Utils_ap(
				_List_fromArray(
					[
						A2(elm$html$Html$Attributes$style, 'border-top', 'solid #E3E3E3 1px'),
						A2(elm$html$Html$Attributes$style, 'padding', '15px 15px'),
						A2(elm$html$Html$Attributes$style, 'margin-top', '30px'),
						A2(elm$html$Html$Attributes$style, 'border-bottom', 'solid #E3E3E3 1px')
					]),
				hasCustomMedia ? _List_fromArray(
					[
						A2(elm$html$Html$Attributes$style, 'background-color', '#B6EDCD')
					]) : _List_Nil),
			_Utils_ap(
				_List_fromArray(
					[
						A2(
						rundis$elm_bootstrap$Bootstrap$Grid$row,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								rundis$elm_bootstrap$Bootstrap$Grid$col,
								_List_fromArray(
									[rundis$elm_bootstrap$Bootstrap$Grid$Col$xs6]),
								_Utils_ap(
									_List_fromArray(
										[
											A3(author$project$PromotionWizard$screenTypeHeading, viewCtx, model.O, screenType)
										]),
									A3(author$project$PromotionWizard$viewBadges, viewCtx, tpl, screenType))),
								A2(
								rundis$elm_bootstrap$Bootstrap$Grid$col,
								_List_fromArray(
									[
										rundis$elm_bootstrap$Bootstrap$Grid$Col$xs6,
										rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs(
										_List_fromArray(
											[
												A2(elm$html$Html$Attributes$style, 'text-align', 'right')
											]))
									]),
								_Utils_ap(
									previewBtn,
									_List_fromArray(
										[
											A2(
											rundis$elm_bootstrap$Bootstrap$Button$button,
											_List_fromArray(
												[
													rundis$elm_bootstrap$Bootstrap$Button$primary,
													rundis$elm_bootstrap$Bootstrap$Button$onClick(
													author$project$PromotionWizard$IdleScreenSelected(screenType))
												]),
											_List_fromArray(
												[
													viewCtx.cF(2),
													A2(
													elm$html$Html$i,
													_List_fromArray(
														[
															elm$html$Html$Attributes$class('fa fa-angle-right'),
															rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml2
														]),
													_List_Nil)
												]))
										])))
							]))
					]),
				_List_fromArray(
					[
						A4(author$project$PromotionWizard$viewPreviewScreensFor, viewCtx, screenType, previewType, tpl)
					])));
	});
var author$project$PromotionWizard$viewScreenTypeListing = F2(
	function (viewCtx, model) {
		return A2(
			elm$core$List$map,
			A3(author$project$PromotionWizard$viewScreenType, model, viewCtx, model.n),
			pzp1997$assoc_list$AssocList$keys(model.n.em));
	});
var author$project$Language$BTN_CREATE = 5;
var author$project$Language$BTN_VIEW_TEMPLATES = 4;
var author$project$PromotionWizard$disableLinkAttrs = _List_fromArray(
	[
		elm$html$Html$Attributes$class('disabled'),
		A2(
		elm$html$Html$Events$preventDefaultOn,
		'click',
		elm$json$Json$Decode$succeed(
			_Utils_Tuple2(author$project$PromotionWizard$NoOp, true))),
		elm$html$Html$Attributes$tabindex(-1)
	]);
var pzp1997$assoc_list$AssocList$values = function (_n0) {
	var alist = _n0;
	return A2(elm$core$List$map, elm$core$Tuple$second, alist);
};
var author$project$PromotionWizard$hasAnyUploaded = function (_n0) {
	var resources = _n0.em;
	return A2(
		elm$core$List$any,
		function ($) {
			return $.eA;
		},
		elm$core$List$concat(
			pzp1997$assoc_list$AssocList$values(resources)));
};
var author$project$PromotionWizard$viewScreenTypeNavButtons = F2(
	function (viewCtx, promotion) {
		var tSpan = viewCtx.cF;
		var backToCreateLink = A2(
			rundis$elm_bootstrap$Bootstrap$Button$linkButton,
			_List_fromArray(
				[
					rundis$elm_bootstrap$Bootstrap$Button$primary,
					rundis$elm_bootstrap$Bootstrap$Button$attrs(
					_List_fromArray(
						[
							elm$html$Html$Attributes$href(
							'#' + author$project$Route$toHash(author$project$Route$CreatePage))
						]))
				]),
			_List_fromArray(
				[
					A2(
					elm$html$Html$i,
					_List_fromArray(
						[
							elm$html$Html$Attributes$class('fa fa-angle-left'),
							rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mr2
						]),
					_List_Nil),
					tSpan(1)
				]));
		var actionsAllowed = author$project$PromotionWizard$hasAnyUploaded(promotion);
		return A2(
			author$project$Widgets$simpleRow,
			_List_fromArray(
				[
					A2(elm$html$Html$Attributes$style, 'margin-top', '20px')
				]),
			_List_fromArray(
				[
					backToCreateLink,
					A2(
					rundis$elm_bootstrap$Bootstrap$Button$linkButton,
					_List_fromArray(
						[
							rundis$elm_bootstrap$Bootstrap$Button$primary,
							rundis$elm_bootstrap$Bootstrap$Button$attrs(
							_Utils_ap(
								_List_fromArray(
									[
										elm$html$Html$Attributes$href(viewCtx.dP + '/page/promotion/media'),
										rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml2
									]),
								actionsAllowed ? _List_Nil : author$project$PromotionWizard$disableLinkAttrs))
						]),
					_List_fromArray(
						[
							tSpan(4)
						])),
					A2(
					rundis$elm_bootstrap$Bootstrap$Button$linkButton,
					_List_fromArray(
						[
							rundis$elm_bootstrap$Bootstrap$Button$primary,
							rundis$elm_bootstrap$Bootstrap$Button$attrs(
							_Utils_ap(
								_List_fromArray(
									[
										elm$html$Html$Attributes$href(
										viewCtx.dP + ('/page/promotion/schedule/time?selectedRgId=' + author$project$PromotionId$toString(promotion.c2))),
										rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml2
									]),
								actionsAllowed ? _List_Nil : author$project$PromotionWizard$disableLinkAttrs))
						]),
					_List_fromArray(
						[
							tSpan(5),
							A2(
							elm$html$Html$i,
							_List_fromArray(
								[
									elm$html$Html$Attributes$class('icon-chevron-right icon-white')
								]),
							_List_Nil)
						]))
				]));
	});
var author$project$PromotionWizard$viewScreenTypeOverview = F2(
	function (viewCtx, model) {
		var tSpan = viewCtx.cF;
		var currentPromotion = model.n;
		return A2(
			author$project$Widgets$simpleRow,
			_List_Nil,
			_Utils_ap(
				_List_fromArray(
					[
						A2(
						elm$html$Html$h2,
						_List_Nil,
						_List_fromArray(
							[
								tSpan(49),
								elm$html$Html$text(': ' + currentPromotion.Q)
							])),
						A2(
						elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								tSpan(50)
							]))
					]),
				_Utils_ap(
					A2(author$project$PromotionWizard$viewScreenTypeListing, viewCtx, model),
					_List_fromArray(
						[
							A2(author$project$PromotionWizard$viewScreenTypeNavButtons, viewCtx, currentPromotion)
						]))));
	});
var author$project$Language$PROMOTION_WAIT_INGRESS = 56;
var author$project$Language$PROMOTION_AREA_BOTTOM = 40;
var author$project$PromotionWizard$viewWaitDoubleTouch = F3(
	function (viewCtx, popoverStates, promotion) {
		var waitBottomResource = A3(author$project$PromotionWizard$getResourceBy, 3, 8, promotion);
		var subHeading = F2(
			function (attrs, txt) {
				return A2(
					elm$html$Html$h6,
					attrs,
					_List_fromArray(
						[txt]));
			});
		var idleTopResource = A3(author$project$PromotionWizard$getResourceBy, 3, 3, promotion);
		var borderResource = A3(author$project$PromotionWizard$getResourceBy, 3, 10, promotion);
		var _n0 = _Utils_Tuple3(idleTopResource, waitBottomResource, borderResource);
		if (((!_n0.a.$) && (!_n0.b.$)) && (!_n0.c.$)) {
			var idleTop = _n0.a.a;
			var waitBottom = _n0.b.a;
			var border = _n0.c.a;
			return A2(
				elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A3(author$project$PromotionWizard$screenTypeHeading, viewCtx, popoverStates, 3),
						A2(
						rundis$elm_bootstrap$Bootstrap$Grid$row,
						_List_Nil,
						_List_fromArray(
							[
								author$project$PromotionWizard$screenLeftCol(
								_List_fromArray(
									[
										A5(author$project$PromotionWizard$viewUploadRowFor, viewCtx, 3, promotion, waitBottom, 40)
									])),
								author$project$PromotionWizard$screenRightCol(
								_List_fromArray(
									[
										A3(
										author$project$PromotionWizard$viewPreviewDoubleTouch,
										viewCtx,
										promotion.c2,
										{s: border, am: waitBottom, aH: idleTop})
									]))
							]))
					]));
		} else {
			return A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('alert alert-error')
					]),
				_List_fromArray(
					[
						elm$html$Html$text('Error in template data, contact support.')
					]));
		}
	});
var author$project$PromotionWizard$viewWaitExtraLargeTouch = F3(
	function (viewCtx, popoverStates, promotion) {
		var waitResource = A3(author$project$PromotionWizard$getResourceBy, 2, 12, promotion);
		if (!waitResource.$) {
			var wait = waitResource.a;
			return A2(
				elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A3(author$project$PromotionWizard$screenTypeHeading, viewCtx, popoverStates, 2),
						A2(
						rundis$elm_bootstrap$Bootstrap$Grid$row,
						_List_Nil,
						_List_fromArray(
							[
								author$project$PromotionWizard$screenLeftCol(
								_List_fromArray(
									[
										A5(author$project$PromotionWizard$viewUploadRowFor, viewCtx, 2, promotion, wait, 45)
									])),
								author$project$PromotionWizard$screenRightCol(
								_List_fromArray(
									[
										A3(author$project$PromotionWizard$viewWaitPreviewExtraLargeTouch, viewCtx, promotion.c2, wait)
									]))
							]))
					]));
		} else {
			return A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('alert alert-error')
					]),
				_List_fromArray(
					[
						elm$html$Html$text('Error in template data, contact support.')
					]));
		}
	});
var author$project$PromotionWizard$viewWaitScreenColorTouch = F3(
	function (viewCtx, popoverStates, promotion) {
		var idleResource = A3(author$project$PromotionWizard$getResourceBy, 1, 5, promotion);
		if (!idleResource.$) {
			var resource = idleResource.a;
			return A2(
				elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A3(author$project$PromotionWizard$screenTypeHeading, viewCtx, popoverStates, 1),
						A2(
						rundis$elm_bootstrap$Bootstrap$Grid$row,
						_List_Nil,
						_List_fromArray(
							[
								author$project$PromotionWizard$screenLeftCol(
								_List_fromArray(
									[
										A5(author$project$PromotionWizard$viewUploadRowFor, viewCtx, 1, promotion, resource, 45)
									])),
								author$project$PromotionWizard$screenRightCol(
								_List_fromArray(
									[
										A3(
										author$project$PromotionWizard$viewPreviewColorTouch,
										viewCtx.ep,
										resource.c2,
										A3(author$project$PromotionWizard$getResourceUrl, viewCtx, promotion.c2, resource))
									]))
							]))
					]));
		} else {
			return A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('alert alert-error')
					]),
				_List_fromArray(
					[
						elm$html$Html$text('Error in template data, contact support.')
					]));
		}
	});
var author$project$PromotionWizard$viewWaitScreenSmallColorTouch = F3(
	function (viewCtx, popoverStates, promotion) {
		var idleResource = A3(author$project$PromotionWizard$getResourceBy, 0, 6, promotion);
		if (!idleResource.$) {
			var resource = idleResource.a;
			return A2(
				elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A3(author$project$PromotionWizard$screenTypeHeading, viewCtx, popoverStates, 0),
						A2(
						rundis$elm_bootstrap$Bootstrap$Grid$row,
						_List_Nil,
						_List_fromArray(
							[
								author$project$PromotionWizard$screenLeftCol(
								_List_fromArray(
									[
										A5(author$project$PromotionWizard$viewUploadRowFor, viewCtx, 0, promotion, resource, 45)
									])),
								author$project$PromotionWizard$screenRightCol(
								_List_fromArray(
									[
										A3(
										author$project$PromotionWizard$viewPreviewSmallTouch,
										viewCtx.ep,
										resource.c2,
										A3(author$project$PromotionWizard$getResourceUrl, viewCtx, promotion.c2, resource))
									]))
							]))
					]));
		} else {
			return A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('alert alert-error')
					]),
				_List_fromArray(
					[
						elm$html$Html$text('Error in template data, contact support.')
					]));
		}
	});
var author$project$Language$PROMOTION_AREA_BACKGROUND = 41;
var author$project$Language$PROMOTION_AREA_MAIN = 39;
var author$project$PromotionWizard$viewWaitScreenUno = F3(
	function (viewCtx, popoverStates, promotion) {
		var tSpan = viewCtx.cF;
		var waitResource = A3(author$project$PromotionWizard$getResourceBy, 4, 7, promotion);
		var borderResource = A3(author$project$PromotionWizard$getResourceBy, 4, 9, promotion);
		var _n0 = _Utils_Tuple2(waitResource, borderResource);
		if ((!_n0.a.$) && (!_n0.b.$)) {
			var wait = _n0.a.a;
			var border = _n0.b.a;
			return A2(
				elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A3(author$project$PromotionWizard$screenTypeHeading, viewCtx, popoverStates, 4),
						A2(
						rundis$elm_bootstrap$Bootstrap$Grid$row,
						_List_Nil,
						_List_fromArray(
							[
								author$project$PromotionWizard$screenLeftCol(
								_List_fromArray(
									[
										A5(author$project$PromotionWizard$viewUploadRowFor, viewCtx, 4, promotion, wait, 39),
										A5(author$project$PromotionWizard$viewUploadRowFor, viewCtx, 4, promotion, border, 41)
									])),
								author$project$PromotionWizard$screenRightCol(
								_List_fromArray(
									[
										A3(
										author$project$PromotionWizard$viewPreviewUno,
										viewCtx,
										promotion.c2,
										{s: border, ax: wait, aD: 1})
									]))
							]))
					]));
		} else {
			return A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('alert alert-error')
					]),
				_List_fromArray(
					[
						elm$html$Html$text('Error in template data, contact support.')
					]));
		}
	});
var author$project$PromotionWizard$viewWaitScreenDetails = F4(
	function (viewCtx, popoverStates, screenType, promotion) {
		switch (screenType) {
			case 0:
				return A3(author$project$PromotionWizard$viewWaitScreenSmallColorTouch, viewCtx, popoverStates, promotion);
			case 1:
				return A3(author$project$PromotionWizard$viewWaitScreenColorTouch, viewCtx, popoverStates, promotion);
			case 2:
				return A3(author$project$PromotionWizard$viewWaitExtraLargeTouch, viewCtx, popoverStates, promotion);
			case 3:
				return A3(author$project$PromotionWizard$viewWaitDoubleTouch, viewCtx, popoverStates, promotion);
			default:
				return A3(author$project$PromotionWizard$viewWaitScreenUno, viewCtx, popoverStates, promotion);
		}
	});
var author$project$Language$BTN_DONE = 3;
var rundis$elm_bootstrap$Bootstrap$Internal$Button$Success = 2;
var rundis$elm_bootstrap$Bootstrap$Button$success = rundis$elm_bootstrap$Bootstrap$Internal$Button$Coloring(
	rundis$elm_bootstrap$Bootstrap$Internal$Button$Roled(2));
var author$project$PromotionWizard$viewWaitScreenNavButtons = F2(
	function (viewCtx, screenType) {
		var tSpan = viewCtx.cF;
		return A2(
			author$project$Widgets$simpleRow,
			_List_fromArray(
				[
					A2(elm$html$Html$Attributes$style, 'margin-top', '20px')
				]),
			_List_fromArray(
				[
					A2(
					author$project$Widgets$backBtn,
					viewCtx,
					_List_fromArray(
						[
							rundis$elm_bootstrap$Bootstrap$Button$onClick(
							author$project$PromotionWizard$IdleScreenSelected(screenType))
						])),
					A2(
					rundis$elm_bootstrap$Bootstrap$Button$button,
					_List_fromArray(
						[
							rundis$elm_bootstrap$Bootstrap$Button$success,
							rundis$elm_bootstrap$Bootstrap$Button$onClick(author$project$PromotionWizard$ScreenTypePageSelected),
							rundis$elm_bootstrap$Bootstrap$Button$attrs(
							_List_fromArray(
								[rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml2]))
						]),
					_List_fromArray(
						[
							tSpan(3)
						]))
				]));
	});
var author$project$PromotionWizard$viewWaitScreen = F4(
	function (viewCtx, popoverStates, screenType, promotion) {
		var tSpan = viewCtx.cF;
		return A2(
			elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(author$project$PromotionWizard$viewWizardHeading, viewCtx, 1),
					A2(
					elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							tSpan(56)
						])),
					A4(author$project$PromotionWizard$viewWaitScreenDetails, viewCtx, popoverStates, screenType, promotion),
					A2(author$project$PromotionWizard$viewWaitScreenNavButtons, viewCtx, screenType)
				]));
	});
var author$project$PromotionWizard$view = F2(
	function (sharedState, model) {
		var viewCtx = author$project$ViewContext$fromSharedState(sharedState);
		var body = function () {
			var _n0 = sharedState.q;
			switch (_n0.$) {
				case 1:
					return A2(author$project$PromotionWizard$viewScreenTypeOverview, viewCtx, model);
				case 2:
					var screenType = _n0.b;
					return A4(author$project$PromotionWizard$viewIdleScreen, viewCtx, model.O, screenType, model.n);
				case 3:
					var screenType = _n0.b;
					return A4(author$project$PromotionWizard$viewWaitScreen, viewCtx, model.O, screenType, model.n);
				default:
					return A2(
						rundis$elm_bootstrap$Bootstrap$Alert$simpleDanger,
						_List_Nil,
						_List_fromArray(
							[
								elm$html$Html$text('This page is not supported by the wizard. Contact support. ')
							]));
			}
		}();
		return A2(
			author$project$Widgets$simpleRow,
			_List_Nil,
			_List_fromArray(
				[
					body,
					A2(author$project$PromotionWizard$showUploadModal, viewCtx, model),
					A2(author$project$PromotionWizard$showResetFailedModal, viewCtx, model)
				]));
	});
var elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var elm$html$Html$map = elm$virtual_dom$VirtualDom$map;
var author$project$Main$view = function (model) {
	return A2(
		elm$html$Html$div,
		_List_fromArray(
			[
				elm$html$Html$Attributes$class('container-fluid'),
				A2(elm$html$Html$Attributes$style, 'font-family', 'arial'),
				A2(elm$html$Html$Attributes$style, 'height', '100%')
			]),
		_List_fromArray(
			[
				A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						A2(elm$html$Html$Attributes$style, 'display', 'flex'),
						A2(elm$html$Html$Attributes$style, 'flex-direction', 'column'),
						A2(elm$html$Html$Attributes$style, 'height', '100%')
					]),
				_List_fromArray(
					[
						author$project$Main$viewNavbar(model),
						function () {
						var _n0 = model.d;
						switch (_n0.$) {
							case 0:
								return A2(author$project$Main$viewLoading, model.ep, 'Starting up application...');
							case 1:
								return A2(author$project$Main$viewLoading, model.ep, 'Loading create page...');
							case 3:
								return A2(author$project$Main$viewLoading, model.ep, 'Loading promotion wizard...');
							case 2:
								var sharedState = _n0.a;
								var createModel = _n0.b;
								return A2(
									elm$html$Html$map,
									author$project$Main$CreateMsg,
									A2(author$project$CreatePromotion$view, sharedState, createModel));
							case 4:
								var sharedState = _n0.a;
								var wizardModel = _n0.b;
								return A2(
									elm$html$Html$map,
									author$project$Main$WizardMsg,
									A2(author$project$PromotionWizard$view, sharedState, wizardModel));
							case 5:
								var err = _n0.a;
								return author$project$Main$viewBigAlertMessage(err);
							default:
								var sharedState = _n0.a;
								return author$project$Main$viewLicenseAccountUnassignedError(sharedState);
						}
					}()
					]))
			]));
};
var elm$browser$Browser$element = _Browser_element;
var author$project$Main$main = elm$browser$Browser$element(
	{d6: author$project$Main$init, eu: author$project$Main$subscriptions, ez: author$project$Main$update, eD: author$project$Main$view});
_Platform_export({'Main':{'init':author$project$Main$main(
	A2(
		elm$json$Json$Decode$andThen,
		function (version) {
			return A2(
				elm$json$Json$Decode$andThen,
				function (initialUrl) {
					return A2(
						elm$json$Json$Decode$andThen,
						function (apiBaseUrl) {
							return elm$json$Json$Decode$succeed(
								{dP: apiBaseUrl, bi: initialUrl, eC: version});
						},
						A2(elm$json$Json$Decode$field, 'apiBaseUrl', elm$json$Json$Decode$string));
				},
				A2(elm$json$Json$Decode$field, 'initialUrl', elm$json$Json$Decode$string));
		},
		A2(elm$json$Json$Decode$field, 'version', elm$json$Json$Decode$string)))(0)}});}(this));